<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Extramural</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-select required v-model="extramuralObject.year" placeholder="Year">
          <option
              v-for="year of $store.getters['dates/years']"
              :key="year + 'year'"
              :value="year"
          >{{ year }}
          </option>
        </b-select>
        <b-field label="Name">
          <b-input
              v-model="extramuralObject.name"
              placeholder="Extramural name"
              required>
          </b-input>
        </b-field>
        <b-field v-if="loaded" style="overflow: hidden" label="Description">
          <ckeditor style="overflow: hidden"
                    :editor="editor"
                    v-model="extramuralObject.description"
                    :config="editorConfig"
          ></ckeditor>
        </b-field>
        <b-field label="Has Attendances">
          <b-switch v-model="extramuralObject.has_attendances" :true-value="1" :false-value="0">
            {{ extramuralObject.has_attendances === 1 ? 'Open for attendance' : 'Closed for attendance' }}
          </b-switch>
        </b-field>
        <b-field :label="$tc('Teacher',2)">
          <b-table
              class="margin-top"
              :data="extramuralObject.teachers"
              :striped="true"
              :hoverable="true"
              :bordered="true"
          >
            <template #empty>
              <div class="has-text-centered">No {{ $tc('Teacher', 2) }}</div>
            </template>
            <b-table-column
                v-slot="props"
                sortable
                field="first_name"
                label="First Name"
            >{{ props.row.first_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                sortable
                field="last_name"
                label="Last Name"
            >{{ props.row.last_name }}
            </b-table-column>
            <b-table-column v-slot="props" sortable field="email" label="Email">{{
                props.row.email
              }}
            </b-table-column>
            <b-table-column v-slot="props" narrowed centered custom-key="actions">
              <b-field grouped>
                <b-field>
                  <b-button @click="viewUser(props.row)" type="is-primary"
                  >View
                  </b-button
                  >
                </b-field>
                <b-field>
                  <b-tooltip label="Remove" size="is-small" type="is-danger">
                    <b-button
                        type="is-danger"
                        :icon-right="$tc('icons.minus')"
                        @click="removeTeacher(props.row)"
                    ></b-button>
                  </b-tooltip>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>

        </b-field>
        <b-field :label="`Add ${$tc('Teacher')}`">
          <UsersFilter :with_permission="'teach extramurals'" :dropdown-direction="'top'"
                       :type="'autocomplete'" @selected="selectUser"
                       :campus_id="extramuralObject.campus_id"></UsersFilter>
        </b-field>
      </section>
      <footer v-if="inModal" class="modal-card-foot">
        <button :loading="loading" class="button" type="is-primary"
                :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
      <b-field grouped class="mt-4" v-else>
        <div class="control">
          <b-button :loading="loading" native-type="submit"
                    tag="input"
                    type="submit" value="Save">
          </b-button>
        </div>
        <div v-if="edit" class="control">
          <b-button :loading="loadingDelete" @click.prevent="startDelete" type="is-danger">
            Delete
          </b-button>
        </div>
      </b-field>
    </div>
    <b-loading :is-full-page="false" :can-cancel="false" v-model="loading"></b-loading>
  </form>
</template>

<script>
import Extramural from "@/models/Extramural";
import UserProfile from "@/components/users/UserProfile";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";


export default {
  name: "ExtramuralForm",
  components: {
    UsersFilter
  },
  data() {
    return {
      loading: false,
      loadingDelete: false,
      loaded:false,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The description to appear on reports."},
      },
      extramuralObject: {
        name: '',
        code: null,
        ordinality: 0,
        campus_id: this.campus_id,
        stage_id: this.stage_id,
        teachers: [],
        teachers_ids: [],
        has_attendances: 0,
        year:this.$store.state.dates.year,
        description: null

      }
    }
  },
  methods: {
    viewUser(user) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          canCreateWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canDeleteWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canEditWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')
          ,
          inModal: true,
          tabs: [
            {title: "User Details", role: "details"},
          ],
          proppedUser: user,
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit users"
          ),
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete users"
          ),
        },
        component: UserProfile,
        fullScreen: true,
        trapFocus: true,
      })
    }
    ,
    removeTeacher(user) {
      this.extramuralObject.teachers = this.extramuralObject.teachers.filter(teacher => teacher.id !== user.id)
      this.extramuralObject.teachers_ids = this.extramuralObject.teachers_ids.filter(teacher_id => teacher_id !== user.id)
    },
    selectUser(user) {
      this.extramuralObject.teachers.push(user)
      this.extramuralObject.teachers_ids.push(user.id)
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting extramural',
        confirmText: 'Delete Extramural',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this extramural?',
        onConfirm: () => this.delete()
      })
    },
    delete() {
      this.$emit('extramural-deleted', this.extramural.id)
    },
    submit() {
      let submitObject = this.extramuralObject
      submitObject.teachers = submitObject.teachers_ids
      delete submitObject.teachers_ids
      delete submitObject.course_scope
      if (this.edit) {
        this.loading = true
        Extramural.Update(submitObject, true).then(() => {
          this.$buefy.snackbar.open(`Extramural updated!`)
          this.$emit('close')
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        this.loading = true
        Extramural.Store(submitObject).then(response => {
          this.$buefy.snackbar.open(`Extramural created!`)
          this.$emit('extramural-created', response.entities.extramurals[0].id)
          this.$emit('close')
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }
    }
  },
  watch: {
    extramural() {
      this.extramuralObject = this.extramural
    }
  },
  mounted() {
    if (this.edit) {
      this.loading = true

      Extramural.FetchById(this.extramural.id, ['teachers']).then(() => {
        this.extramuralObject = Extramural.query().whereId(this.extramural.id).with('teachers').first()
        this.loading = false
        this.loaded = true
      }).catch(err => {
        this.handleError(err)
        this.loading = false
      })
    }
    this.loaded = true
  },
  props: {
    campus_id: {
      type: Number, default() {
        return 0
      }
    },
    stage_id: {
      type: Number, default() {
        return 0
      }
    },
    extramural: {
      type: Object,
      default() {
        return {
          name: '', ordinality: 0
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

