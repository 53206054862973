<template>
  <form @submit.prevent="submit">
    <section class="section">
      <div class="columns">
        <div class="column">
          <b-field label="Observation">
            <ckeditor
                :editor="editor"
                v-model="profile.observation"
                :config="editorConfig"
            ></ckeditor>
          </b-field>
        </div>
      </div>
    </section>
    <hr class="my-0"/>
    <b-field grouped position="is-centered" class="mt-4">
      <div class="control">
        <b-button v-if="canEdit" :loading="loading" native-type="submit"
                  tag="input"
                  type="submit" value="Save">
        </b-button>
      </div>
    </b-field>

    <b-loading v-model="loading" :is-full-page="false"></b-loading>

  </form>

</template>

<script>
import Profile from "@/models/Profile";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";

export default {
  name: "ObservationsPanel",
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The observation"},
      },
      create: false,
      loading: false,
      loaded: false,
      profile: {
        observation: ''
      }
    }
  },
  props: {
    canEdit: {
      type: Boolean, default() {
        return false
      }
    },
    user: {
      type: Object,
      required: true
    }
  },
  methods: {

    submit() {
      if (this.canEdit) {

        this.loading = true

        if (this.create) {
          Profile.Store(this.profile, this.user.id).then(() => {
            this.$buefy.snackbar.open('Observation Saved!')
            this.loading = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false
          })
        } else {
          Profile.Update({observation: this.profile.observation, user_id: this.user.id}).then(() => {
            this.$buefy.snackbar.open('Observation Saved!')
            this.loading = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false
          })
        }

      } else {
        this.$store.dispatch('toast/createToast')
      }
    },
  },

  mounted() {
    this.loading = true
    Promise.all([
      Profile.FetchById(this.user.id)]).then(() => {
      this.profile = Profile.query().whereId(this.user.id).first()
      this.loading = false
    }).catch(err => {

      if (err.response.status === 404) {
        this.create = true
      } else {
        this.handleError(err)
      }
          this.loading = false
        }
    )
  }
}
</script>
