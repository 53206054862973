<template>
  <div>
    <div v-if="user" class="section">
      <div class="container">

        <p class="is-size-4">Annual Leave:</p>
        <div v-if="profile" class="columns is-multiline">
          <div class="column is-2-fullhd is-3-desktop is-4-touch">
            <b-field label="Accrued Annual Leave">
              {{ profile.accrued_annual_leave }}/{{ profile.leave_days_per_year['annual'] }} days
            </b-field>

          </div>
          <div v-if="profile.rolled_over_annual_leave_expiry_date" class="column is-2-fullhd is-3-desktop is-4-touch">
            <b-field label="Rolled Over Annual Leave">
              {{ profile.rolled_over_annual_leave }} days
            </b-field>

          </div>
          <div v-if="profile.rolled_over_annual_leave_expiry_date" class="column is-2-fullhd is-3-desktop is-4-touch">
            <b-field label="Rolled Over Annual Leave Expiry Date">
              <b-datepicker disabled :value="profile.rolled_over_annual_leave_expiry_date"></b-datepicker>
            </b-field>

          </div>
          <div class="column is-2-fullhd is-3-desktop is-4-touch">
            <b-field label="Remaining Annual Leave">
              {{
                profile.available_annual_leave
              }}
              days
            </b-field>

          </div>

        </div>
        <p class="is-size-4">Other Leave Balances:</p>

        <div v-if="profile" class="columns is-multiline">

          <div v-show="type.model_name!=='absenteeism'&&type.model_name!=='annual'"
               v-for="type in $store.state.leave.types" :key="type.id"
               class="column is-2-fullhd is-3-desktop is-4-touch">

            <b-field :label="type.name">
              {{ profile.outstanding_leave_days[type.model_name] }}/{{ profile.leave_days_per_year[type.model_name] }}
              days
            </b-field>

          </div>
        </div>
        <div class="has-text-centered">
          <b-field>
            <b-button type="is-primary" :icon-right="$tc('icons.create')" @click="startCreateLeaveRequest()">
              Create a leave request
            </b-button>
          </b-field>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <p class="is-size-4">Leave Requests:</p>
        <b-table
            class="margin-top"
            :data="leaveRequests"
            :striped="true"
            :hoverable="true"
            :bordered="false"
            :scrollable="true"
        >
          <b-table-column
              v-slot="props"
              label="ID"
              field="id"
              sortable
              width="40"
              numeric
          >{{ props.row.id }}
          </b-table-column>
          <!--          <b-table-column-->
          <!--              v-slot="props"-->
          <!--              label="First Name"-->
          <!--              field="user.first_name"-->
          <!--              sortable-->
          <!--          >-->
          <!--            {{ props.row.user.first_name }}-->
          <!--          </b-table-column>-->
          <!--          <b-table-column-->
          <!--              v-slot="props"-->
          <!--              label="Last Name"-->
          <!--              field="user.last_name"-->
          <!--              sortable-->
          <!--          >-->
          <!--            {{ props.row.user.last_name }}-->
          <!--          </b-table-column>-->

          <b-table-column
              v-slot="props"
              label="Start Date"
          >
            {{ props.row.dates[0].date }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label="End Date"
          >
            {{ props.row.dates[props.row.dates.length - 1].date }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label="Days Requested"
              field="length"
              sortable
          >
            {{ props.row.length }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label="Type"
              field="type_id"
              sortable
          >
            {{ $store.state.leave.types.find(type => type.id === props.row.type_id).name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label="Status"
              field="status_id"
              sortable
          >
            {{ $store.state.leave.statuses.find(status => status.id === props.row.status_id).name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label="Created At"
          >
            {{ props.row.created_at }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              narrrowed
              centered
              width="100"
              label="Actions"
              cell-class="py-1"
              custom-key="actions"
          >
            <b-field grouped>

              <b-field>
                <b-button @click="viewLeaveRequest(props.row)" type="is-primary"
                >View{{ canEdit ? '/Edit' : '' }}
                </b-button
                >
              </b-field>
              <b-field v-if="canManage" style="margin-top:auto;margin-bottom: auto">

                <b-tooltip :type="props.row.is_archived?'is-success':'is-link'"
                           :label="props.row.is_archived?'Unarchive':'Archive'" size="is-small">
                  <a
                      class="card-header-icon px-0 py-0"
                      :class="{'has-text-success':props.row.is_archived,'has-text-link':!props.row.is_archived}"
                      @click.prevent.stop="toggleArchive(props.row)"
                  >
                    <b-icon :icon="$tc('icons.archive')"/>
                  </a></b-tooltip>
              </b-field>
              <b-field
                  v-if="canManage">
                <b-button @click.prevent.stop="approve(props.row)" :disabled="props.row.status_id===1" size="is-large"
                          type="is-text" class="has-text-success"
                          :icon-right="$tc('icons.tick-circle')">

                </b-button>


                <b-button @click.prevent.stop="decline(props.row)" :disabled="props.row.status_id===3" size="is-large"
                          type="is-text" class="has-text-danger"
                          :icon-right="$tc('icons.close-circle')">

                </b-button>

              </b-field>
            </b-field>
          </b-table-column>
        </b-table>
        <b-pagination
            class="margin-top"
            :total="meta.total"
            :current="page"
            :range-before="2"
            :range-after="2"
            :per-page="limit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            v-on:change="setPage"

        ></b-pagination>
      </div>

    </div>
    <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false"/>
  </div>

</template>

<script>

import LeaveRequestForm from "@/components/leave/LeaveRequestForm";
import LeaveRequest from "@/models/LeaveRequest";
import StaffProfile from "@/models/StaffProfile";


export default {
  name: "LeavePanel",
  components: {},
  data() {
    return {
      page: 1,
      limit: 20,
      create: false,
      loading: false,
      loaded: false,
      meta: {},
      leaveRequestsDataHold: null,

    }
  },
  props: {
    canEdit: {
      type: Boolean, default() {
        return false
      }
    }, canManage: {
      type: Boolean, default() {
        return false
      }
    },
    canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    },
    user: {
      type: Object,
      default() {
        return null
      }
    }
  },
  methods: {

    toggleArchive(leaveRequest) {

      this.loading = true
      let copy = JSON.parse(JSON.stringify(leaveRequest))


      copy.is_archived = !copy.is_archived
      LeaveRequest.Update(copy, true).then(() => {
        this.$buefy.snackbar.open(`Leave Request ${copy.is_archived ? 'Archived' : 'Unarchived'}!`);
      }).catch(err => {
        console.log(err)
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })
    },
    approve(leaveRequest) {
      this.loading = true
      let copy = JSON.parse(JSON.stringify(leaveRequest))
      copy.approved_by_user_id = this.$store.state.user.id
      copy.status_id = 2
      LeaveRequest.Update(copy, true).then(() => {
        this.$buefy.snackbar.open(`Leave Request Approved!`);
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })
    }, decline(leaveRequest) {

      this.loading = true
      let copy = JSON.parse(JSON.stringify(leaveRequest))
      copy.approved_by_user_id = this.$store.state.user.id


      copy.status_id = 3
      LeaveRequest.Update(copy, true).then(() => {
        this.$buefy.snackbar.open(`Leave Request Denied!`);
      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
      })
    },
    startDelete(leaveRequest) {
      this.$buefy.dialog.confirm({
        title: `Deleting Leave Request`,
        confirmText: `Delete Leave Request`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this leave request?`,
        onConfirm: () =>
            LeaveRequest.Delete(leaveRequest.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Leave Request deleted!`);
                  this.setPage(this.page)
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreateLeaveRequest() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          default_user: this.user.id,
          canManage: this.canManage,
          canEdit: this.canEdit
          , canCreate: this.canCreate,
          canDelete: this.canDelete,
        },
        component: LeaveRequestForm,
        fullScreen: false,
        trapFocus: true,
      })
    },
    viewLeaveRequest(leaveRequest) {
      this.setQuery({leave_request_id: leaveRequest.id})
      console.log('bob')
      this.$buefy.modal.open({
        parent: this,
        props: {
          edit: true,
          inModal: true,
          canManage: this.canManage,
          canEdit: this.canEdit,
          canCreate: this.canCreate,
          canDelete: this.canDelete,
          leaveRequest: leaveRequest
        },
        component: LeaveRequestForm,
        fullScreen: false,
        trapFocus: true,
        onCancel: () => {

          this.removeQuery(['leave_request_id'])
        },

      })
    },
    setPage(pageNumber) {
      this.loading = true;
      this.leaveRequestsDataHold = this.leaveRequests;
      LeaveRequest.deleteAll();
      this.page = pageNumber;
      LeaveRequest.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          }, {user: this.user.id}, ['user']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loading = false;
            this.leaveRequestsDataHold = null;
          }
      );
    },
  },
  computed: {
    profile() {
      if (this.user) {
        return StaffProfile.query().where('user_id', this.user.id).first()
      }
      return null
    },
    leaveRequests() {
      if (this.leaveRequestsDataHold === null) {
        return LeaveRequest.query().with('user').get()
      }
      return this.leaveRequestsDataHold
    }
  },
  mounted() {
    if (this.user) {
      StaffProfile.FetchById(this.user.id).catch(() => {
        this.$store.dispatch('toast/createToast', {
          message: 'Fill in the Employee Info before logging a leave request',
          type: 'is-danger'
        })
      })
    }
    if (this.$route.query.leave_request_id) {
      LeaveRequest.FetchById(this.$route.query.leave_request_id).then(() => {
        if (LeaveRequest.query().where('id', parseInt(this.$route.query.leave_request_id)).where('user_id', this.user.id).exists()) {
          this.viewLeaveRequest(LeaveRequest.query().where('id', parseInt(this.$route.query.leave_request_id)).first())
        }
      }).catch(err => {
        this.handleError(err)
      })
    }
    this.setPage(1)
  }
}
</script>
