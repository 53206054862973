<template>
  <div>
    <div v-if="user" class="section">
        <div class="level">
          <div class="level-left">
            <div class="level-item">
              <p class="is-size-3">Incidents</p>

            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <b-button v-if="canCreate" :icon-right="$tc('icons.create')" type="is-primary"
                        @click="startCreateIncident()">
                Log an incident
              </b-button>
            </div>
          </div>
        </div>

    </div>
    <div class="section">
        <PerfectScrollbarWrapper>
        <b-table
            class="is-fullwidth"
            :bordered="false"
            :data="incidents"
            :hoverable="true"
            :scrollable="false"
            :striped="true"
        >
          <template #empty>
            <div class="has-text-centered">No Incidents</div>
          </template>
          <b-table-column
              v-slot="props"
              field="id"
              label="ID"
              numeric
              sortable
              width="40"
          >{{ props.row.id }}
          </b-table-column>


          <!--          <b-table-column-->
          <!--              v-slot="props"-->
          <!--              field="type_id"-->
          <!--              label="Type"-->
          <!--              sortable-->
          <!--          >-->
          <!--            {{ $store.state.leave.types.find(type => type.id === props.row.type_id).name }}-->
          <!--          </b-table-column>-->
          <b-table-column
              v-slot="props"
              field="status_id"
              label="Status"
              sortable
          >
            {{ $store.state.incidents.statuses.find(status => status.id === props.row.status_id).name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="category_id"
              label="Category"
              sortable
          >
            {{
              $store.state.incidents.ungroupedCategories.find(category => category.id === props.row.category_id).name
            }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="charge_id"
              label="Charge"
              sortable
          >
            {{ $store.state.incidents.ungroupedCharges.find(charge => charge.id === props.row.charge_id).name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="disciplinary_action_id"
              label="Disciplinary Action"
              sortable
          >
            {{
              props.row.disciplinary_action_id ? $store.state.incidents.actions.find(action => action.id === props.row.disciplinary_action_id).name : 'None recorded'
            }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="incident_date"
              label="Incident Date"
              sortable
          >
            {{ props.row.incident_date }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              field="created_at"
              label="Created At"
              sortable
          >
            {{ props.row.created_at }}
          </b-table-column>
          <b-table-column v-if="canEdit||canCreate||canDelete"
                          v-slot="props"
                          centered
                          label="Actions"

          >
            <b-dropdown append-to-body aria-role="list">
              <template #trigger="{ active }">
                <b-button
                    :icon-right="active ? 'menu-up' : 'menu-down'"
                    label="Actions"
                    type="is-primary"/>
              </template>


              <b-dropdown-item v-if="canCreate||canEdit" aria-role="listitem" @click="viewIncident(props.row)">View{{
                  canEdit ? '/Edit' : ''
                }}
              </b-dropdown-item>

              <b-dropdown-item v-if="canDelete" aria-role="listitem"
                              @click="startDelete(props.row)">Delete
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
        </b-table>
        </PerfectScrollbarWrapper>
        <b-pagination
            :current="page"
            :per-page="limit"
            :range-after="2"
            :range-before="2"
            :total="meta.total"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            class="margin-top"
            v-on:change="setPage"

        ></b-pagination>

      </div>
    <b-loading :active.sync="loading" :can-cancel="false" :is-full-page="false"/>
  </div>

</template>

<script>


import Incident from "@/models/Incident";
import IncidentForm from "@/components/incidents/IncidentForm";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "IncidentsPanel",
  components: { PerfectScrollbarWrapper },
  data() {
    return {
      page: 1,
      limit: 20,
      create: false,
      loading: false,
      loaded: false,
      meta: {},
      incidentsDataHold: null,

    }
  },
  props: {
    canEdit: {
      type: Boolean, default() {
        return false
      }
    }, canManage: {
      type: Boolean, default() {
        return false
      }
    },
    canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    },
    user: {
      type: Object,
      default() {
        return null
      }
    }
  },
  methods: {

    startDelete(incident) {
      this.$buefy.dialog.confirm({
        title: `Deleting Incident`,
        confirmText: `Delete Incident`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this incident?`,
        onConfirm: () =>
            Incident.Delete(incident.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Incident deleted!`);
                  this.setPage(this.page)
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    startCreateIncident() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          canManage: this.canManage,
          canEdit: this.canEdit,
          canCreate: this.canCreate,
          canDelete: this.canDelete,
        },
        component: IncidentForm,
        fullScreen: false,
        trapFocus: true,
      })
    },
    viewIncident(Incident) {
      this.setQuery({incident_id: Incident.id})
      this.$buefy.modal.open({
        parent: this,
        props: {
          edit: true,
          inModal: true,
          canManage: this.canManage,
          canEdit: this.canEdit,
          canCreate: this.canCreate,
          canDelete: this.canDelete,
          incident: Incident
        },
        component: IncidentForm,
        fullScreen: false,
        trapFocus: true,
        onCancel: () => {

          this.removeQuery(['incident_id'])
        },

      })
    },
    setPage(pageNumber) {
      this.loading = true;
      this.incidentsDataHold = this.incidents;
      Incident.deleteAll();
      this.page = pageNumber;
      Incident.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          }, {user:this.user.id}, ['users']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loading = false;
            this.incidentsDataHold = null;
          }
      );
    },
  },
  computed: {

    incidents() {
      if (this.incidentsDataHold === null) {
        return Incident.query().with('users').get()
      }
      return this.incidentsDataHold
    }
  },
  mounted() {

    if (this.$route.query.incident_id && (this.canEdit || this.canCreate)) {
      Incident.FetchById(this.$route.query.incident_id, ['users']).then(() => {
        if (Incident.query().where('id', parseInt(this.$route.query.incident_id)).exists()) {
          this.viewIncident(Incident.query().where('id', parseInt(this.$route.query.incident_id)).first())
        }
      }).catch(err => {
        this.handleError(err)
      })
    }
    this.setPage(1)
  }
}
</script>
