<template>
  <form @submit.prevent="submitActivity()">
    <h3 class="has-text-centered is-capitalized">{{ action }} Activity</h3>
    <b-field class="margin-top">
      <b-field>
        <b-input
            autocomplete="off"
            v-model="form.name"
            :placeholder="namePlaceholder"
            name="name"
            required
        />
      </b-field>
    </b-field>
    <b-field>
      <b-field v-if="loaded">
        <b-radio-button
            v-for="type in activity_types"
            :key="type.id"
            v-model="form.type_id"
            :native-value="type.id"
        >
          <b-tooltip :label="type.name" position="is-top">
            <b-icon :icon="$tc(`icons.${type.name}`)"></b-icon>
          </b-tooltip>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field>
      <b-field>
        <ckeditor :editor="editor" v-model="note" :config="editorConfig"></ckeditor>
      </b-field>
    </b-field>
    <b-field>
      <b-field>
        <b-datetimepicker

            :timepicker="timepicker"
            placeholder="Type or select a date..."
            :icon="$tc('icons.calendar')"
            editable
            v-model="due_date"
        ></b-datetimepicker>
      </b-field>
    </b-field>
    <b-field label="Assign to">
      <b-field>
        <b-select v-model="form.assigned_to_user_id" placeholder="Select the activity assignee">
          <option v-for="user in team" :key="user.id" :value="user.id">
            <p>{{ user.first_name }} {{ user.last_name }} | {{ user.email }}</p>
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field>
      <b-field grouped>
        <div class="control">
          <b-button native-type="submit" type="is-primary">Submit</b-button>
        </div>
        <div class="control">
          <b-button @click.prevent="cancelActivity()" type="is-secondary">Cancel</b-button>
        </div>

        <b-checkbox
            :true-value="1"
            :false-value="0"
            type="is-success"
            v-model="form.is_complete"
        >Completed
        </b-checkbox>
      </b-field>
    </b-field>
    <b-loading :is-full-page="false" :active.sync="loadingActivity" :can-cancel="false"></b-loading>
  </form>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import Activity from "@/models/Task";
import ActivityType from "@/models/TaskType"
import {format} from 'date-fns'


export default {
  name: "ActivityForm",
  props: {
    model_type: {type: String, required: true},
    model_id: {type: Number, required: true},
    team: {
      type: Array,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({
        can_create: false,
        can_edit: false,
      }),
    },
    activity: {
      type: Object,
      default: () => {
      },
    },
    action: {
      type: String,
      default: "create",
    },
  },
  mounted() {
    this.loadingActivity = true

    if (!ActivityType.exists()) {
      ActivityType.FetchAll({page: 1, limit: 999}).then(() => {
        this.loadingActivity = false
        this.loaded = true
        if (this.activity) {
          this.form = this.activity
        }
      }).catch(
          err => {
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      )

    } else {
      this.loadingActivity = false
      this.loaded = true
      if (this.activity) {
        this.form = this.activity
      }
    }
  },
  data() {
    return {
      loaded: false,
      loadingActivity: false,
      timepicker: {
        incrementMinutes: 5,
        hourFormat: "24",
      },
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "An optional description"},
      },
      form: {
        is_complete: 0,
        name: "",
        type_id: 1,
        note: "",
        due_at: null,
        assigned_to_user_id: null,
      },

    };
  },
  computed: {
    activity_types() {
      return ActivityType.query().get()
    },
    note: {
      get() {
        if (this.form.note == null) {
          return "";
        }
        return this.form.note;
      },
      set(newVal) {
        this.form.note = newVal;
      },
    },
    due_date: {
      set(newVal) {
        this.form.due_at = format(newVal, 'yyyy-MM-dd hh:mm:ss')
      },
      get() {
        if (this.form.due_at == null) {
          return null;
        }
        return new Date(this.form.due_at);
      },
    },
    namePlaceholder() {
      if (this.loaded && !this.loadingActivity) {

        return ActivityType.query().whereId(this.form.type_id).first().name;
      } else {
        return ''
      }
    },
  },
  watch: {
    activity(newValue) {
      if (newValue !== null) {
        this.form = this.activity;
      }
    },
  },
  methods: {
    cancelActivity() {
      this.form = {
        is_complete: 0,
        name: "",
        type_id: 1,
        note: "",
        due_at: null,
        assigned_to_user_id: null,
      };
      this.$emit("cancelActivity");
    },
    submitActivity() {
      if (this.action == "create") {
        if (this.permissions.can_create) {
          this.loadingActivity = true;

          Activity.Store(this.form, {type: this.model_type, id: this.model_id})
              .then(() => {
                this.$buefy.snackbar.open({
                  message: "Activity created",
                  queue: false,
                  type: "is-primary",
                });
                this.loadingActivity = false;
                this.cancelActivity();
              })
              .catch(
                  err => {
                    if (err.response.status === 422) {
                      this.$store.dispatch("toast/createToast", {
                        message: JSON.stringify(err.response.data),
                      });
                    } else {
                      this.$store.dispatch("toast/createToast", {
                        message: JSON.stringify(err),
                      });
                    }
                  }
              )
        } else {
          this.$store.dispatch("toast/createToast");
        }
      } else if (this.action == "edit") {
        if (this.permissions.can_edit) {
          this.loadingActivity = true;

          Activity.Update(this.form, {type: this.model_type, id: this.model_id},)
              .then(() => {
                this.$buefy.snackbar.open({
                  message: "Activity edited",
                  queue: false,
                  type: "is-primary",
                });
                this.cancelActivity();
                this.$emit('activity-edited')
                this.loadingActivity = false;
              })
              .catch(
                  err => {
                    if (err.response.status === 422) {
                      this.$store.dispatch("toast/createToast", {
                        message: JSON.stringify(err.response.data),
                      });
                    } else {
                      this.$store.dispatch("toast/createToast", {
                        message: JSON.stringify(err),
                      });
                    }
                  }
              )
        } else {
          this.$store.dispatch("toast/createToast");
        }
      }
    },
  },
};
</script>
