<template>

  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <b-field expanded grouped group-multiline>
          <b-field>
            <b-datepicker

                :years-range="[-100,100]"
                v-model="dateFilterComputed"
                placeholder="Start date"
                :icon="$tc('icons.calendar')"

            ></b-datepicker>
            <b-button type="is-primary" @click="dateRightClick" icon-left="close-circle"></b-button>
          </b-field>
          <b-field  v-if="canCreate">
            <b-button type="is-primary" :icon-right="$tc('icons.create')" @click="startCreateCovidAssessment()">
              Create a Covid assessment
            </b-button>
          </b-field>
        </b-field>
      </div>
    </div>
    <hr>
    <PerfectScrollbarWrapper>
    <b-table
        class="margin-top is-fullwidth"
        :data="covidAssessments"
        :striped="true"
        :loading="loadingData"
        :hoverable="true"
        :bordered="false"
        :scrollable="false"
    >
      <b-table-column
          v-slot="props"
          label="ID"
          field="id"
          sortable
          width="40"
          numeric
      >{{ props.row.id }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="First Name"
          field="user.first_name"
          sortable
      >
        {{ props.row.user.first_name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Last Name"
          field="user.last_name"
          sortable
      >
        {{ props.row.user.last_name }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Taken On"
          field="taken_on"
          sortable
      >
        {{ dateFormat(props.row.taken_on) }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Temperature"
          field="temperature"
          sortable
      >
        {{ props.row.temperature }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          field="hasSymptoms"
          sortable
          label="Has Symptoms"
      >
        {{ props.row.hasSymptoms ? 'Yes' : 'No' }}
      </b-table-column>

      <b-table-column
          field="created_at"
          sortable
          v-slot="props"
          label="Created At"
      >
        {{ dateFormat(props.row.created_at) }}
      </b-table-column>
      <b-table-column
          v-slot="props"
          label="Actions"
          custom-key="actions"
          centered

      >
        <b-dropdown position="is-bottom-left"
                    append-to-body aria-role="list">
          <template #trigger="{ active }">
            <b-button
                label="Actions"
                type="is-primary"
                :icon-right="active ? 'menu-up' : 'menu-down'"/>
          </template>

          <b-dropdown-item @click="viewCovidAssessment(props.row)" aria-role="listitem">
            View{{ canEdit ? '/Edit' : '' }}
          </b-dropdown-item>
          <b-dropdown-item v-if="canDelete" @click="startDelete(props.row)" aria-role="listitem">
            Delete
          </b-dropdown-item>

        </b-dropdown>
      </b-table-column>
    </b-table>
    </PerfectScrollbarWrapper>
    <b-pagination
        class="mt-4"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
    ></b-pagination>
  </div>

</template>

<script>
import {mapState} from "vuex";
import CovidAssessmentForm from "@/components/covidAssessments/CovidAssessmentForm";
import CovidAssessment from "@/models/CovidAssessment";
import Dates from "@/mixins/Dates";
import {isValid, parseISO} from "date-fns";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "CovidAssessmentsIndex",
  components: { PerfectScrollbarWrapper },
  mixins: [Dates],
  data() {
    return {

      loadingFilter: false,
      covidAssessmentsDataHold: null,
      meta: Object,
      page: 1,
      use_modal: false,
      limit: 20,
      loadingData: false,
      userId: this.defaultUserId,
      dateFilter: null,

    };
  },
  props: {

    defaultUserId: {
      type: Number,
      default() {
        return null;
      },
    }, canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    }, canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    }, canCreate: {
      type: Boolean,
      default() {
        return false;
      },
    }, canManage: {
      type: Boolean,
      default() {
        return false;
      },
    },


  },
  methods: {
    dateRightClick(){
      this.dateFilterComputed = null
    },
    startCreateCovidAssessment() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          default_user: this.defaultUserId,
          canEdit: this.canEdit,
          canCreate: this.canCreate,
          canDelete: this.canDelete,
        },
        component: CovidAssessmentForm,
        fullScreen: false,
        trapFocus: true,
      })
    },
    clear() {
      this.dateFilter = null
    },
    onSort(field, order) {
      this.order_by = field;
      this.order_direction = order;
    },
    startDelete(covidAssessment) {
      this.$buefy.dialog.confirm({
        title: `Deleting Covid Assessment`,
        confirmText: `Delete Covid Assessment`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this Covid assessment?`,
        onConfirm: () =>
            CovidAssessment.Delete(covidAssessment.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Covid Assessment deleted!`);
                  this.setPage(this.page)
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    viewCovidAssessment(covidAssessment) {
      this.setQuery({covid_assessment_id: covidAssessment.id})
      this.$buefy.modal.open({
        parent: this,
        props: {
          edit: true,
          inModal: true,
          canEdit: this.canEdit,
          canCreate: this.canCreate,
          canDelete: this.canDelete,
          covidAssessment: covidAssessment
        },
        component: CovidAssessmentForm,
        fullScreen: false,
        trapFocus: true,
        onCancel: () => {
          this.removeQuery(['covid_assessment_id'])
        },

      })
    },

    setPage(pageNumber, initial = false) {
      this.loadingData = true;
      this.covidAssessmentsDataHold = this.covidAssessments;
      CovidAssessment.deleteAll();
      this.page = pageNumber;
      CovidAssessment.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          }, this.filters, ['user']
      ).then(
          ({
             response: {
               data: {meta},
             },
           }) => {
            this.meta = meta;
            this.loadingData = false;
            this.covidAssessmentsDataHold = null;
            if (!initial) {
              this.$cookies.set(
                  `covid_assessment_filters`,
                 {
                    page: this.page,
                    userId: this.userId,
                    date: this.dateFilter,
                  },
                  "1d"
              );
            }
          }
      );
    },
  },
  watch: {
    filters() {
      this.setPage(1);
    },
  },
  computed: {

    ...mapState('campus', ["selected_campus_id", "all_campuses"]),

    dateFilterComputed: {
      get() {
        if (isValid(parseISO(this.dateFilter)) && this.dateFilter !== null) {
          return new Date(this.dateFilter)
        }
        return null
      }, set(newValue) {
        if (newValue ===null){
          this.dateFilter = null
        } else {
          this.dateFilter = this.dateFormat(newValue)
        }      },
    }, filters() {
      return {

        ...(this.userId
            ? {
              user: this.userId,
            }
            : {}), ...(this.dateFilter
            ? {
              date: this.dateFilter,
            }
            : {}),


      };
    },
    covidAssessments() {
      if (this.covidAssessmentsDataHold === null) {
        return CovidAssessment.query().with('user').get();
      } else {
        return this.covidAssessmentsDataHold;
      }
    },

  },
  mounted() {
    this.$store.dispatch("loader/show");
    this.$store.state.documentation.tab_name='covid-assessments'
    this.$store.state.documentation.active_doc_document_path='./Covid Assessments.md'
    if (this.$cookies.isKey(`covid_assessment_filters`)) {
      let filters = this.$cookies.get(`covid_assessment_filters`);
      this.userId = filters.userId
      this.dateFilter = filters.date
      this.$cookies.remove(`covid_assessment_filters`);
    }
    if (this.$route.query.covid_assessment_id) {
      CovidAssessment.FetchById(this.$route.query.covid_assessment_id).then(() => {
        this.viewCovidAssessment(CovidAssessment.query().where('id', parseInt(this.$route.query.covid_assessment_id)).first())
      }).catch(err => {
        this.removeQuery(['covid_assessment_id'])
        this.handleError(err)
      })
    }
    this.$store.dispatch("loader/hide");
    this.setPage(this.page, true);

  },
};
</script>
