<template>
  <form @submit.prevent="submit">

    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">Re-enrol {{ $tc('Learner', 2) }}</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <h2 class="has-text-centered is-size-4">Filters</h2>
            <b-field label="Year">
              <b-select
                  @input="filters.phase_id=null;filters.stage_id=null;newEnrolments.year =null;getFilterPhasesAndStages()"
                  expanded
                  v-model="filters.year"
                  placeholder="Filter by year"
              >
                <option
                    v-for="year of $store.getters['dates/years']"
                    :key="year + 'year'"
                    :value="year"
                >{{ year }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Phase">
              <b-select :disabled="filters.year===null" placeholder="Phase" expanded @input="filters.stage_id = null"
                        v-model="filters.phase_id">
                <option
                    v-for="phase of filterPhases"
                    :key="phase.id"
                    :value="phase.id"
                >{{ phase.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Stage">
              <b-select placeholder="Stage" :disabled="filters.phase_id===null" expanded v-model="filters.stage_id">
                <option
                    v-for="stage of filterStages"
                    :key="stage.id"
                    :value="stage.id"
                >{{ stage.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Status">
              <b-select expanded
                        placeholder="Status"
                        v-model="filters.status_id">
                <option :value="null">All</option>
                <option
                    v-for="status in statuses"
                    :key="status.id + 'status'"
                    :value="status.id"
                >{{ status.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Type">
              <b-select expanded
                        v-model="filters.type_id" placeholder="Enrolment Type">
                <option v-for="type in $store.state.profile.student_types" :key="type.id" :value="type.id">
                  {{ type.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Action">
              <b-button type="is-primary" @click.prevent="filter" :icon-right="'filter'">Filter</b-button>
            </b-field>
        <h2 class="has-text-centered is-size-4">New Enrolment Properties</h2>
            <b-field label="Year">
              <b-select
                  required
                  @input="newEnrolments.phase_id=null;newEnrolments.stage_id=null;getNewEnrolmentsPhasesAndStages()"
                  expanded
                  v-model="newEnrolments.year"
                  placeholder="Year"
              >
                <option
                    v-for="year of years"
                    :key="year + 'year'"
                    :value="year"
                >{{ year }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Phase">
              <b-select required expanded :disabled="newEnrolments.year===null" @input="newEnrolments.stage_id = null"
                        placeholder="Phase"
                        v-model="newEnrolments.phase_id">
                <option
                    v-for="phase of newEnrolmentPhases"
                    :key="phase.id"
                    :value="phase.id"
                >{{ phase.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Stage">
              <b-select :disabled="newEnrolments.phase_id===null" placeholder="Stage" required expanded
                        v-model="newEnrolments.stage_id">
                <option
                    v-for="stage of newEnrolmentStages"
                    :key="stage.id"
                    :value="stage.id"
                >{{ stage.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Status">
              <b-select expanded
                        required
                        placeholder="Status"
                        v-model="newEnrolments.status_id">
                <option
                    v-for="status in statuses"
                    :key="status.id + 'status'"
                    :value="status.id"
                >{{ status.name }}
                </option>
              </b-select>
            </b-field>
            <b-field :type="{'is-danger':warningDate}" label="Date">
              <b-datepicker expanded
                            :position="'is-bottom-left'"
                            required
                            append-to-body
                            :placeholder="datePlaceholder"
                            v-model="date">

              </b-datepicker>
            </b-field>
            <b-field label="Type">
              <b-select expanded
                        required
                        v-model="newEnrolments.type_id" placeholder="Enrolment Type">
                <option v-for="type in $store.state.profile.student_types" :key="type.id" :value="type.id">
                  {{ type.name }}
                </option>
              </b-select>
            </b-field>
        <h2 class="has-text-centered is-size-4">{{ $tc('Learner', 2) }}</h2>
        <b-table
            :paginated="true"
            :per-page="20"
            :current-page.sync="currentPage"
            :data="filteredUsers"
            :striped="true"
            :loading="loadingData"
            :hoverable="true"
            :bordered="true"
            :scrollable="true"
            :checkable="true"
            :checked-rows.sync="checkedUsers"
        >
          <b-table-column
              v-slot="props"
              label="ID"
              field="id"
              sortable
              width="40"
              numeric
          >{{ props.row.id }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label="First Name"
              field="first_name"
              sortable
          >
            {{ props.row.first_name }}
          </b-table-column>
          <b-table-column
              v-slot="props"
              label="Last Name"
              field="last_name"
              sortable
          >
            {{ props.row.last_name }}
          </b-table-column>

        </b-table>
      </section>

      <footer v-if="showButtons" :class="{'modal-card-foot':inModal}">
        <b-field class="mt-3 has-text-centered" grouped expanded position="is-centered">
          <b-field position="is-centered">
            <b-button native-type="submit"
                      :disabled="!canCreate || checkedUsers.length===0"
                      type="is-primary"
                      :icon-right="$tc(`icons.edit`)">
              Submit
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import Enrolment from "@/models/Enrolment";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import Phase from "@/models/Phase";
import Stage from "@/models/Stage";
import {format} from "date-fns";

export default {
  name: "BulkEnrolmentForm",
  data() {
    return {
      loaded: false,
      loading: false,
      loadingData: false,
      loadingNewEnrolmentsPhases: false,
      loadingNewEnrolmentsStages: false,
      loadingFiltersPhases: false,
      loadingFiltersStages: false,
      created: false,
      currentPage: 1,
      phaseFilterClearFlip: false,
      warningDate: false,
      stageFilterClearFlip: false,
      phaseNewClearFlip: false,
      stageNewClearFlip: false,
      filters: {
        phase_id: this.defaultPhaseId,
        stage_id: this.defaultStageId,
        status_id: null,
        year: new Date().getFullYear(),
        type_id: null,
        campus_id: this.campusId
      },
      checkedUsers: [],
      filteredUsers: [],
      newEnrolments: {
        user_ids: [],
        status_id: null,
        phase_id: null,
        stage_id: null,
        year: (new Date().getFullYear()) + 1,
        campus_id: this.campusId,
        type_id: null,
        date: null
      },

    }
  },

  computed: {
    datePlaceholder() {
      if (this.newEnrolments.status_id === null) {
        return 'Date'
      }
      return `${EnrolmentStatus.query().where('id', this.newEnrolments.status_id).first().name} date`
    },
    date: {
      get() {
        if (this.newEnrolments.date) {
          return new Date(this.newEnrolments.date)
        }
        return null
      }, set(newValue) {
        this.newEnrolments.date = format(newValue, 'yyyy-MM-dd')
      },
    },
    newEnrolmentPhases() {
      return Phase.query().where('year', this.newEnrolments.year).where('campus_id', this.newEnrolments.campus_id).get()
    }, filterPhases() {
      return Phase.query().where('year', this.filters.year).where('campus_id', this.filters.campus_id).get()
    }, newEnrolmentStages() {
      return Stage.query().where('phase_id', this.newEnrolments.phase_id).where('year', this.newEnrolments.year).where('campus_id', this.newEnrolments.campus_id).get()
    }, filterStages() {
      return Stage.query().where('phase_id', this.filters.phase_id).where('year', this.filters.year).where('campus_id', this.filters.campus_id).get()
    },
    years() {
      let years = []

      for (let i = 0; i < 6; i++) {
        let year = new Date().getFullYear() - 1 + i
        if (year !== this.filters.year) {
          years.push(year)

        }
      }
      return years
    },
    users() {
      return Enrolment.query().where((enrolment) => {
        for (let key in this.filters) {
          if (this.filters[key] !== null) {
            if (enrolment[key] !== this.filters[key]) {
              return false
            }
          }
        }
        return true;
      }).with('user').get().map(enrolment => {
        return enrolment.user
      })
    },
    statuses() {
      return EnrolmentStatus.query().where('name', value => value !== 'Pending').get()
    },
  },
  watch: {
    'newEnrolments.date'() {
      this.warningDate = false
    },
    submit_flip(val) {
      if (val === true) {
        this.submit()
      }
    }
  },
  methods: {
    getFilterPhasesAndStages() {
      this.loadingFiltersPhases = true
      this.loadingFiltersStages = true
      Phase.FetchAll({page: 1, limit: 999}, {
        campus_id: this.filters.campus_id,
        year: this.filters.year
      }, ['stages']).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingFiltersPhases = false
        this.loadingFiltersStages = false
      })
    }, getNewEnrolmentsPhasesAndStages() {
      this.loadingNewEnrolmentsPhases = true
      this.loadingNewEnrolmentsStages = true
      Phase.FetchAll({page: 1, limit: 999}, {
        campus_id: this.newEnrolments.campus_id,
        year: this.newEnrolments.year
      }, ['stages']).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingNewEnrolmentsPhases = false
        this.loadingNewEnrolmentsStages = false
      })
    },
    filter(autoSelect = false) {
      this.loadingData = true

      return Enrolment.FetchAll({
        page: 1,
        limit: 999
      }, this.filters, ['user']).then((response) => {
        if (response.entities) {
          if (response.entities.users) {
            this.filteredUsers = response.entities.users
            if (autoSelect === true) {
              this.checkedUsers = this.filteredUsers
            }
          } else {
            this.filteredUsers = []
          }
        } else {
          this.filteredUsers = []
        }
        this.loadingData = false
      }).catch(err => {
        this.handleError(err)
      })
    },

    submit() {
      if (this.newEnrolments.date === null) {
        this.warningDate = true
        return
      }
      if (this.canCreate) {
        this.loading = true
        this.newEnrolments.user_ids = this.checkedUsers.map(user => {
          return user.id
        })
        Enrolment.StoreBulk(this.newEnrolments).then(() => {
          this.loading = false
          this.$buefy.snackbar.open(`Enrolments Converted!`)
          if (this.inModal) {
            this.$emit('close')
          }
          this.$emit('submitSuccess')

        }).catch(err => {
          this.loading = false
          this.$emit('submitFail')
          this.handleError(err)
        })
      } else {
        this.$store.dispatch('toast/createToast')
      }

    }
  }
  ,
  mounted() {

    this.getFilterPhasesAndStages()
    this.getNewEnrolmentsPhasesAndStages()
    if (this.defaultPhaseId !== null && this.defaultStageId !== null) {
      this.filter(true)
    }
    setTimeout(() => {
      this.loaded = true
    }, 5000)

  }
  ,
  props: {
    campusId: {
      type: Number,
      default() {
        return null
      }
    },
    showButtons: {
      type: Boolean,
      default() {
        return true
      }
    },
    isNarrow: {
      type: Boolean,
      default() {
        return false
      }
    },
    canCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
    defaultPhaseId: {
      type: Number,
      default() {
        return null
      }
    }, defaultStageId: {
      type: Number,
      default() {
        return null
      }
    },

    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

