<template>
  <div>
    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <span class="is-size-3">Guardians</span>
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <div v-if="tab === 0" class="level-item">
          <b-field grouped>
            <b-button
              type="is-primary"
              @click="tab = 1"
              :icon-right="$tc('icons.create')"
            >
              Add guardian
            </b-button>
          </b-field>
        </div>
        <div class="level-item">
          <b-field v-if="tab === 1" grouped>
            <b-field>
              <b-button @click="tab = 0" type="is-danger" icon-right="cancel"
                >Cancel</b-button
              >
            </b-field>
            <b-field v-if="canCreateNew">
              <b-button
                @click="create()"
                type="is-primary"
                :icon-right="$tc('icons.create')"
                >Create new Guardian
              </b-button>
            </b-field>
          </b-field>
        </div>
      </div>
    </div>
    <b-tabs expanded multiline  v-model="tab" class="hidden-tabs no-padding">
      <b-tab-item>
        <PerfectScrollbarWrapper>
        <b-table
          class="margin-top is-fullwidth"
          :loading="!loaded"
          :data="user.guardians"
          :striped="true"
          :hoverable="true"
          :bordered="false"
          :scrollable="false"
        >
          <template #empty>
            <div class="has-text-centered">No Guardians</div>
          </template>
          <b-table-column
            v-slot="props"
            label="ID"
            field="id"
            sortable
            width="40"
            numeric
            >{{ props.row.id }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            sortable
            field="first_name"
            label="First Name"
            >{{ props.row.first_name }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            sortable
            field="last_name"
            label="Last Name"
            >{{ props.row.last_name }}
          </b-table-column>
          <b-table-column v-slot="props" sortable field="email" label="Email">{{
            props.row.email
          }}</b-table-column>
          <b-table-column v-slot="props" narrowed centered custom-key="actions">
            <b-field grouped>
              <b-field>
                <b-button @click="viewUser(props.row)" type="is-primary"
                  >View</b-button
                >
              </b-field>
              <b-field v-if="canDelete">
                <b-tooltip label="Remove" size="is-small" type="is-danger">
                  <b-button
                    type="is-danger"
                    :icon-right="$tc('icons.minus')"
                    @click="removeGuardian(props.row)"
                  ></b-button>
                </b-tooltip>
              </b-field>
            </b-field>
          </b-table-column>
        </b-table>
        </PerfectScrollbarWrapper>
      </b-tab-item>
      <b-tab-item>
        <UsersFilter
          :role="'guardian'"
          :type="'filter'"
          @cleared="clear"
          @filtered="setList"
        ></UsersFilter>
        <PerfectScrollbarWrapper>
        <b-table
          class="margin-top is-fullwidth"
          :data="addList"
          :striped="true"
          :hoverable="true"
          :bordered="true"
          :scrollable="false"
        >
          <template #empty>
            <div class="has-text-centered">No Guardians Selected</div>
          </template>
          <b-table-column
            v-slot="props"
            label="ID"
            field="id"
            sortable
            width="40"
            numeric
            >{{ props.row.id }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            sortable
            field="first_name"
            label="First Name"
            >{{ props.row.first_name }}
          </b-table-column>
          <b-table-column
            v-slot="props"
            sortable
            field="last_name"
            label="Last Name"
            >{{ props.row.last_name }}
          </b-table-column>
          <b-table-column v-slot="props" sortable field="email" label="Email">{{
            props.row.email
          }}</b-table-column>
          <b-table-column v-slot="props" narrowed centered custom-key="actions">
            <b-field grouped>
              <b-field>
                <b-button @click="viewUser(props.row)" type="is-primary"
                  >View</b-button
                >
              </b-field>
              <b-field v-if="canCreate">
                <b-tooltip label="Add" size="is-small" type="is-success">
                  <b-button
                    type="is-success"
                    :icon-right="$tc('icons.create')"
                    @click="addGuardian(props.row)"
                  ></b-button>
                </b-tooltip>
              </b-field>
            </b-field>
          </b-table-column>
        </b-table>
        </PerfectScrollbarWrapper>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import UserProfile from "@/components/users/UserProfile";
import User from "@/models/User";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import UserCreateForm from "@/components/users/UserCreateForm";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  components: { UsersFilter, PerfectScrollbarWrapper },
  name: "GuardiansPanel",
  data() {
    return {
      tab: 0,
      loaded: false,
      type: "Guardian",
      addList: [],
    };
  },
  mounted() {
    User.FetchById(this.userObject.id, ["guardians"]).then(() => {
      this.loaded = true;
    });
  },
  computed: {
    user() {
      return User.query().whereId(this.userObject.id).with("guardians").first();
    },
  },
  methods: {
    create() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          type: "Guardian",
          defaultRole: "guardian",
          edit: false,
          canDelete: false,
          canCreate: this.canCreateNew,
        },
        component: UserCreateForm,
        fullScreen: false,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "user-created": (user) => {
            this.addGuardian(user);
          },
        },
      });
    },
    addGuardian(guardian) {
      this.loaded = false;
      let user = this.user;
      user.guardians = user.guardian_ids;
      user.guardians.push(guardian.id);
      User.Update(
        {
          id: user.id,
          guardians: user.guardians,
          title: user.title,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        },
          true
      )
          .then(() => {
            User.FetchById(this.userObject.id, ["guardians"]).then(() => {
              this.loaded = true;
              this.$buefy.snackbar.open("Guardian added!");
              this.tab = 0;
            });
          })
          .catch(
              err => {
                if (err.response.status === 422) {
                  this.handleError(err)
                } else {
                  this.handleError(err)
                }
              }
          )
    },
    removeGuardian(remove_guardian) {
      this.loaded = false;
      let user = this.user;
      user.guardians = user.guardian_ids;
      user.guardians = user.guardians.filter((guardian) => {
        return guardian !== remove_guardian.id;
      });
      User.Update(
        {
          id: user.id,
          guardians: user.guardians,
          title: user.title,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        },
        true
      )
        .then(() => {
          User.update({
            where: user.id,
            data: { guardian_ids: user.guardians },
          }).then(() => {
            this.loaded = true;
            this.$buefy.snackbar.open("Guardian removed!");
            this.tab = 0;
          });
        })
        .catch((err) => {
          this.handleError(err)
        });
    },
    clear() {
      this.addList = [];
    },
    setList({ result }) {
      this.addList = result.entities.users;
    },
    viewUser(user) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          tabs: [
            {title: "User Details", role: "details"},
            {title: "Wards", role: "wards"},
          ],
          proppedUser: user,
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit learners"
          ),
          canCreateWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canDeleteWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canEditWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')
          ,
          canCreateGuardian: !!this.$store.getters['entities/user-permissions/find']('edit learners'),
          canDeleteGuardian: !!this.$store.getters['entities/user-permissions/find']('edit learners'),
          canEditGuardian: !!this.$store.getters['entities/user-permissions/find']('edit learners')
          ,
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete learners"
          ),
        },
        component: UserProfile,
        fullScreen: true,
        trapFocus: true,
      });
    },
  },
  props: {
    userObject: {
      type: Object,
      required: true,
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateNew: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style scoped>
</style>
