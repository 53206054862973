<template>
  <b-table :loading="loading" :paginated="true" :data="extramurals" :striped="true" :hoverable="true" :bordered="true">
    <template #empty>
      <div class="has-text-centered">No Extramurals</div>
    </template>
    <b-table-column v-slot="props" label="ID" field="id" sortable width="40" numeric>{{
        props.row.id
      }}
    </b-table-column>

    <b-table-column v-slot="props" sortable field="name" label="Name">{{
        props.row.name
      }}
    </b-table-column>
    <b-table-column v-if="showAttendances" v-slot="props" sortable field="has_attendances" label="Has Attendances">
      {{ props.row.has_attendances === 1 ? 'Yes' : 'No' }}
    </b-table-column>
    <b-table-column centered width="80" v-slot="props" cell-class="py-1" label="Actions">
      <b-checkbox v-if="canSelectExtramurals"
                  :disabled="!canClickExtramurals"
                  v-model="selectedExtramuralIds"
                  :native-value="props.row.id"
      ></b-checkbox>
      <b-tooltip v-if="canEditExtramural"
                 label="Edit"
                 size="is-small"
                 type="is-warning"
      >
        <a

            class="card-header-icon has-text-black  px-0 py-0"
            @click.prevent.stop="startEdit(props.row)"
        >
          <b-icon :icon="$tc('icons.edit')"
          ></b-icon>
        </a>
      </b-tooltip>
      <b-tooltip
          v-if="canDeleteExtramural"
          label="Delete"
          size="is-small"
          type="is-danger"
      >
        <a
            class="card-header-icon has-text-danger px-0 py-0"
            @click.prevent.stop="startDelete(props.row)"
        >
          <b-icon :icon="$tc('icons.delete')"/>
        </a>

      </b-tooltip>

    </b-table-column>

  </b-table>
</template>

<script>
export default {
  name: "ExtramuralsTable",
  methods: {
    startEdit(extramural) {
      this.$emit('start-edit-extramural', extramural)
    }, startDelete(extramural) {
      this.$emit('start-delete-extramural', extramural.id)
    }
  },
  computed: {
    selectedExtramuralIds: {
      get() {
        return this.selectedExtramurals
      },
      set(newValue) {
        this.$emit('selected-extramural-ids', newValue)
      },
    }
  },
  props: {
    selectedExtramurals: {
      type: Array, default() {
        return []
      }
    },
    showAttendances: {
      type: Boolean, default() {
        return false
      }
    },
    canEditExtramural: {
      type: Boolean, default() {
        return false
      }
    },
    canSelectExtramurals: {
      type: Boolean, default() {
        return false
      }
    }, canClickExtramurals: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteExtramural: {
      type: Boolean, default() {
        return false
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false
      }
    },
    extramurals: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
