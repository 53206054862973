import { render, staticRenderFns } from "./SubjectsTable.vue?vue&type=template&id=4bd8a01f&scoped=true&"
import script from "./SubjectsTable.vue?vue&type=script&lang=js&"
export * from "./SubjectsTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd8a01f",
  null
  
)

export default component.exports