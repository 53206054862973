var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"tabs",staticClass:"section"},[_c('activityForm',{attrs:{"permissions":{
                          can_create: !!_vm.$store.getters['entities/user-permissions/find']("create tasks"),
                          can_edit: !!_vm.$store.getters['entities/user-permissions/find']("edit tasks"),
                        },"action":_vm.editingActivity ? 'edit' : 'create',"activity":_vm.editingActivity ? _vm.activityToEdit : null,"model_type":'user',"model_id":parseInt(this.user.id),"team":_vm.activities_team},on:{"cancelActivity":function($event){_vm.editingActivity = false;}}})],1),_c('div',{staticClass:"section"},[_vm._l((_vm.activities),function(activity){return _c('div',{key:activity.id},[_c('activityListItem',{attrs:{"activity":activity,"permissions":{
                      can_delete: !!_vm.$store.getters['entities/user-permissions/find']("delete tasks"),
                      can_edit: !!_vm.$store.getters['entities/user-permissions/find']("edit tasks"),
                      can_complete: !!_vm.$store.getters['entities/user-permissions/find']("edit tasks") || parseInt(activity.assigned_to_user_id) === _vm.$store.state.user.id
                    },"model_type":'user',"model_id":parseInt(_vm.user.id)},on:{"editActivity":function($event){return _vm.editActivity($event)}}})],1)}),_c('b-pagination',{staticClass:"margin-top",attrs:{"total":_vm.activities_count,"current":_vm.page,"range-before":2,"range-after":2,"per-page":_vm.limit,"aria-next-label":"Next page","aria-previous-label":"Previous page","aria-page-label":"Page","aria-current-label":"Current page"},on:{"update:current":function($event){_vm.page=$event}}})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }