<template>
  <div>
    <div v-if="!inProfile" class="level">
      <!-- Left side -->
      <div class="level-left" style="max-width: 70%;word-break: break-all;word-wrap: anywhere">
        <div class="level-item" style="max-width: 100%">
          <nav aria-label="breadcrumbs" class="breadcrumb is-large" style="max-width: 100%">
            <ul style="max-width: 100%">
              <li class="is-size-3"><a class="px-0"
                                       @click="((campus_id)=>{selectCampus(campus_id);mode=null;$store.dispatch('campus/selectEnrolment',null);$store.dispatch('campus/selectUser',null);subjectTab=0;filterEnrolments()})">{{
                  campus.name
                }}</a>
              </li>
              <li v-if="$store.state.campus.selected_phase" class="is-size-3"><a
                  class="px-0"
                  @click="$store.dispatch('campus/clearSelectStage');$store.dispatch('campus/selectUser',null);mode=null;$store.dispatch('campus/selectEnrolment',null);subjectTab=0;filterEnrolments()">{{
                  $store.state.campus.selected_phase.name
                }}</a>
              </li>
              <li v-if="$store.state.campus.selected_stage" class="is-size-3"><a class="px-0"
                                                                                 @click="$store.dispatch('campus/selectUser',null);$store.dispatch('campus/selectEnrolment',null);mode=null;subjectTab=0;filterEnrolments()">{{
                  $store.state.campus.selected_stage.name
                }}</a>
              </li>
              <li v-if="$store.state.campus.selected_user" class="is-size-3"><a class="px-0"
                                                                                @click="$store.dispatch('campus/selectEnrolment',null);mode=null;subjectTab=0">{{
                  $store.state.campus.selected_user.first_name
                }} {{
                  $store.state.campus.selected_user.last_name
                }}</a>
              </li>
              <li v-if="$store.state.campus.selected_enrolment" class="is-size-3">{{
                  $store.state.campus.selected_enrolment.id
                }}
              </li>

              <li class="is-size-3 is-capitalized">{{ currentTab }}</li>

            </ul>
          </nav>

        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <div v-if="(canCreateEnrolment || canCreateSubject)&& $store.state.campus.selected_stage"
             class="level-item mr-4">
          <b-tooltip v-if="subjectTab===0||(subjectTab===1&&canCreateSubject)|| (subjectTab ===2 && canCreateSubject)"
                     :label="`Create a new ${currentTab} in this stage`"
                     multilined
                     size="is-small"
                     type="is-success"
          >
            <a
                class="card-header-icon has-text-primary px-0 py-0"
                @click.prevent.stop="createClick()"
            >
              <b-icon :icon="$tc('icons.create')" size="is-medium"/>
            </a>

          </b-tooltip>


        </div>
        <div v-if="(canCreateEnrolment && subjectTab===0)"
             class="level-item mr-4">

          <b-button
              :icon-right="$tc('icons.create')"
              type="is-primary"
              @click.prevent.stop="startCreateBulkEnrolments()"
          >
            Re-enrol {{ $tc('Learner', 2) }}
          </b-button>


        </div>
      </div>
    </div>
    <div v-if="subjectTab ===1 || subjectTab ===2"
         class="level">
      <div class="level-left"></div>
      <div class="level-right">
        <div class="level-item">
          <b-tooltip
              :label="`Switch to ${subjectTab===1?'extramurals':'subjects'}`"
              multilined
              size="is-small"
              type="is-success"
          >
            <div class="field">
              <transition mode="out-in" name="fade">
                <b-button :key="subjectTab" type="is-primary" @click="toggleSubjectTab">
                  {{ subjectTab === 1 ? 'Extramurals' : 'Subjects' }}
                </b-button>
              </transition>
            </div>
          </b-tooltip>
        </div>
      </div>

    </div>
    <b-tabs expanded multiline  v-model="subjectTab" class="hidden-tabs no-padding">
      <b-tab-item v-if="!inProfile">
        <div class="columns">
          <div class="column">
            <UsersFilter
                :campus_id="activeCampusId"
                :phase_id="$store.state.campus.selected_phase? $store.state.campus.selected_phase.id:null"
                :role="'learner'"
                :stage_id="$store.state.campus.selected_stage? $store.state.campus.selected_stage.id:null"
                :type="'autocomplete'"
                @cleared="$store.dispatch('campus/setFilterEnrolmentTerm',null);$store.dispatch('campus/selectUser',null)"
                @selected="((user)=>{enrolmentMeta.currentPage=1;filterEnrolments(user)})"
            ></UsersFilter>
          </div>
          <div class="column is-narrow">
            <b-field v-if="!selected_enrolment" group-multiline grouped>
              <b-field>
                <b-select v-model="$store.state.dates.year" placeholder="Year"
                          @input="filterEnrolments(null)">
                  <option
                      v-for="year of $store.getters['dates/years']"
                      :key="year + 'year'"
                      :value="year"
                  >{{ year }}
                  </option>
                </b-select>
              </b-field>
              <b-field>
                <b-select v-model="$store.state.campus.enrolment_filter_status_id" placeholder="Status"
                          @input="filterEnrolments(null)">
                  <option :value="null">All</option>
                  <option
                      v-for="status in statuses"
                      :key="status.id + 'status'"
                      :value="status.id"
                  >{{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item>

        <div class="columns is-multiline">
          <div v-if="!!selected_enrolment" class="column is-12">
            <b-field group-multiline grouped>
              <b-field expanded label="Status">

                <ValidationObserver ref="observer">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <b-select
                        v-model="selected_enrolment.status_id"
                        :disabled="!$store.getters['entities/user-permissions/find'](`${mode} enrolment status`)" expanded>
                      <option :value="null" disabled>None</option>
                      <option v-for="status in statuses" :key="status.id" :value="status.id">
                        {{ status.name }}
                      </option>
                    </b-select>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>

                </ValidationObserver>
              </b-field>

              <b-field expanded label="Year">
                <b-select ref="year_edit_select"
                          v-model="this.selected_enrolment.year" :disabled="!$store.getters['entities/user-permissions/find'](`${mode} enrolment year`)" expanded
                >
                  <option
                      v-for="year of $store.getters['dates/years']"
                      :key="year + 'year'"
                      :value="year"
                  >{{ year }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded label="HomeClass">
                <b-select v-model="selected_enrolment.home_class_id"
                          :disabled="!$store.getters['entities/user-permissions/find'](`${mode} enrolment homeclass`)" expanded>
                  <option
                      v-for="homeClass in homeClasses"
                      :key="homeClass.id + 'class'"
                      :value="homeClass.id"
                  >{{ homeClass.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field class="pt-1" group-multiline grouped>
              <b-field expanded label="Enrolment Date">
                <b-datepicker
                    v-model="selected_enrolment_enroled_date" :disabled="!this.$store.getters['entities/user-permissions/find'](
              'edit enrolment dates'
          )" :years-range="[-80,80]" expanded
                    placeholder="Enrolment Date"></b-datepicker>
              </b-field>
              <b-field v-if="selected_enrolment.status_id===3" expanded label="Withdrawal Date">
                <b-datepicker v-model="selected_enrolment_withdrawn_date" :disabled="!this.$store.getters['entities/user-permissions/find'](
              'edit enrolment dates'
          )" :years-range="[-80,80]" expanded
                              placeholder="Withdrawal Date"></b-datepicker>
              </b-field>
              <b-field v-if="selected_enrolment.status_id===4" expanded label="Graduated Date">
                <b-datepicker v-model="selected_enrolment_graduated_date" :disabled="!this.$store.getters['entities/user-permissions/find'](
              'edit enrolment dates'
          )" :years-range="[-80,80]" expanded
                              placeholder="Graduated Date"></b-datepicker>
              </b-field>
            </b-field>
            <b-field v-if="!!$store.getters['entities/user-permissions/find'](`view finance information`)" class="pt-1"
                     group-multiline grouped>
              <b-field expanded label="Enrolment Type">
                <b-select v-model="selected_enrolment.type_id"
                          :disabled="!$store.getters['entities/user-permissions/find'](`edit finance information`)"
                          expanded placeholder="Enrolment Type">
                  <option v-for="type in $store.state.profile.student_types" :key="type.id" :value="type.id">
                    {{ type.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded label="Finance Type">
                <b-select v-model="selected_enrolment.finance_type_id"
                          :disabled="!$store.getters['entities/user-permissions/find'](`edit finance information`)"
                          expanded placeholder="Finance Type">
                  <option v-for="type in $store.state.profile.finance_types" :key="type.id" :value="type.id">
                    {{ type.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded label="Amount">
                <b-input v-model.number="selected_enrolment.amount"
                         :disabled="!$store.getters['entities/user-permissions/find'](`edit finance information`)"
                         expanded
                         placeholder="Amount"
                         type="number">

                </b-input>
              </b-field>

            </b-field>
          </div>
          <div v-if="mode==='create'" class="column is-12">
            <b-field grouped>
              <b-field label="Status">
                <ValidationObserver ref="observer">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <b-select
                        v-model="newEnrolment.status_id" expanded>
                      <option :value="null" disabled>None</option>
                      <option v-for="status in statuses" :key="status.id" :value="status.id">
                        {{ status.name }}
                      </option>
                    </b-select>
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>

                </ValidationObserver>
              </b-field>
              <b-field expanded label="Year">
                <b-select
                    v-model="newEnrolment.year"
                    disabled expanded>
                  <option
                      v-for="year of $store.getters['dates/years']"
                      :key="year + 'year'"
                      :value="year"
                  >{{ year }}
                  </option>
                </b-select>
              </b-field>
              <b-field expanded label="HomeClass">
                <b-select
                    v-model="newEnrolment.home_class_id" expanded>
                  <option
                      v-for="homeClass in homeClasses"
                      :key="homeClass.id + 'class'"
                      :value="homeClass.id"
                  >{{ homeClass.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field class="pt-1" group-multiline grouped>
              <b-field expanded label="Enrolment Date">
                <b-datepicker v-model="new_enrolment_enroled_date" :years-range="[-80,80]" expanded
                              placeholder="Enrolment Date"></b-datepicker>
              </b-field>
              <b-field v-if="newEnrolment.status_id===3" expanded label="Withdrawal Date">
                <b-datepicker v-model="new_enrolment_withdrawn_date" :years-range="[-80,80]" expanded
                              placeholder="Withdrawal Date"></b-datepicker>
              </b-field>
              <b-field v-if="newEnrolment.status_id===4" expanded label="Graduated Date">
                <b-datepicker v-model="new_enrolment_graduated_date" :years-range="[-80,80]" expanded
                              placeholder="Graduated Date"></b-datepicker>
              </b-field>
            </b-field>
          </div>
          <div class="column">
            <SubjectsFilter v-if="$store.state.campus.selected_stage && currentTab ==='subjects' &&!inProfile"
                            :campus_id="activeCampusId"
                            :type="'filter'"
                            @cleared="$store.dispatch('campus/setFilterSubjectTerm',null)"
                            @filtered="({term})=>this.$store.dispatch('campus/setFilterSubjectTerm', term)"
            ></SubjectsFilter>
            <ExtramuralsFilter v-if="$store.state.campus.selected_stage && currentTab ==='extramurals' &&!inProfile"
                               :campus_id="activeCampusId"
                               :mode="'create'"
                               :type="'filter'"
                               @cleared="$store.dispatch('campus/setFilterExtramuralTerm',null)"
                               @filtered="({term})=>this.$store.dispatch('campus/setFilterExtramuralTerm', term)"
            ></ExtramuralsFilter>
          </div>
          <div class="column is-narrow">
            <transition mode="out-in" name="fade">
              <b-field v-if="mode!==null" grouped>
                <b-field>
                  <b-button :icon-right="$tc('icons.content-save')" type="is-primary" @click="saveEnrolmentAction">Save</b-button>
                </b-field>
                <b-field>
                  <b-button icon-right="cancel" type="is-danger" @click="cancelEnrolmentAction">Cancel</b-button>
                </b-field>
              </b-field>
            </transition>
          </div>
        </div>

      </b-tab-item>
    </b-tabs>

    <hr>
    <div ref="subjectsContainer" class="subjectsContainer" @mouseenter="psUpdate">
      <b-tabs expanded multiline  v-model="subjectTab" class="hidden-tabs no-padding">
        <b-tab-item>
          <EnrolmentsTable
              :can-delete-enrolment="canDeleteEnrolment"
              :can-edit-enrolment="canEditEnrolment"
              :currentPage="enrolmentMeta.currentPage"
              :enrolments="enrolments"
              :loading="loadingEnrolments"
              :perPage="enrolmentMeta.perPage"
              :total="enrolmentMeta.total"
              @start-edit-enrolment="selectEnrolment"
              @start-delete-enrolment="deleteEnrolment"
              @enrolment-click="selectEnrolment"
              @change-page="changeEnrolmentPage"
          ></EnrolmentsTable>
        </b-tab-item>
        <b-tab-item>
          <SubjectsTable v-if="$store.state.campus.selected_stage!==null&&currentTab ==='subjects'"
                         :can-click-subjects="(!!selected_enrolment && !!$store.getters['entities/user-permissions/find'](`edit enrolment subjects`)) ||(mode==='create')"
                         :can-select-subjects="!!selected_enrolment  ||mode==='create'"
                         :canDeleteSubject="canDeleteSubject"
                         :canEditSubject="canEditSubject" :loading="loadingSubjects"
                         :selected-subjects="selectedSubjects"
                         :show-class-group-select="true"
                         :show-teacher-select="true"
                         :subjects="selectedStageSubjects" @start-delete-subject="deleteSubject"
                         @start-edit-subject="editSubject"
                         @selected-subject-ids="selectSubject"
          ></SubjectsTable>
        </b-tab-item>
        <b-tab-item>
          <ExtramuralsTable v-if="$store.state.campus.selected_stage!==null &&currentTab==='extramurals'"
                            :can-click-extramurals="(!!selected_enrolment && !!$store.getters['entities/user-permissions/find'](`edit enrolment extramurals`)) ||(mode==='create')"
                            :can-select-extramurals="!!selected_enrolment ||mode==='create'" :canDeleteExtramural="canDeleteExtramural"
                            :canEditExtramural="canEditExtramural"
                            :extramurals="selectedStageExtramurals"
                            :loading="loadingExtramurals"
                            :selected-extramurals="!!selected_enrolment?selected_enrolment.extramural_ids:newEnrolment.extramurals" @start-delete-extramural="deleteExtramural"
                            @start-edit-extramural="editExtramural"
                            @selected-extramural-ids="selectExtramural"
          ></ExtramuralsTable>
        </b-tab-item>

      </b-tabs>
    </div>

  </div>
</template>
<script>
import SubjectsFilter from "@/components/panelled-dash/SubjectsFilter"
import SubjectsTable from "@/components/panelled-dash/SubjectsTable"
import SubjectForm from "@/components/panelled-dash/SubjectForm";
import Subject from "@/models/Subject";
import PerfectScrollbar from "perfect-scrollbar";
import Enrolment from "@/models/Enrolment";
import EnrolmentsTable from "@/components/panelled-dash/EnrolmentsTable";
import {mapState} from 'vuex'
import User from "@/models/User";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import EnrolmentStatus from "@/models/EnrolmentStatus";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import HomeClass from "@/models/HomeClass";
import Extramural from "@/models/Extramural";
import ExtramuralForm from "@/components/panelled-dash/ExtramuralForm";
import ExtramuralsFilter from "@/components/panelled-dash/ExtramuralsFilter";
import ExtramuralsTable from "@/components/panelled-dash/ExtramuralsTable";
import {format, isValid, parseISO} from 'date-fns'
import ClassGroup from "@/models/ClassGroup";
import BulkEnrolmentForm from "@/components/enrolments/BulkEnrolmentForm";

extend("required", {
  ...required,
  message: "This field is required",
});
export default {
  name: 'EnrolmentsPanel',
  components: {
    ExtramuralsFilter,
    ExtramuralsTable,
    SubjectsFilter,
    SubjectsTable,
    EnrolmentsTable,
    UsersFilter,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      enrolmentDataHold: null,
      yearFilter: new Date().getFullYear(),
      subjectTab: 0,
      ps: null,
      loadingEnrolments: false,
      loadingSubjects: false,
      loadingExtramurals: false,
      mode: null,
      newEnrolment: {
        status_id: null,
        subjects: [],
        subject_ids: [],
        extramurals: [],
        phase_id: null,
        stage_id: null,
        user_id: null,
        campus_id: null,
        home_class_id: null,
        year: this.selected_stage?.year ?? null,
        enroled_date: format(new Date(), 'yyyy-MM-dd'),
        graduated_date: null,
        withdrawn_date: null,
        amount: 0,
        finance_type_id: 1,
        type_id: 1
      },
      enrolmentMeta: {
        total: 20,
        perPage: 20,
        currentPage: 1
      }
    }
  },
  watch: {
    subject_select_boolean() {
      this.subjectTab = 0
    },
    activeCampusId(newValue) {
      if (newValue !== null) {
        this.loadingEnrolments = true
        this.filterEnrolments()
      }
    },
    selected_stage(newValue) {
      if (newValue !== null) {
        this.loadingEnrolments = true
        this.enrolments.currentPage = 1
        this.newEnrolment.year = newValue.year
        this.filterEnrolments()
      }
    }, selected_phase(newValue) {
      if (newValue !== null && !this.selected_stage) {
        this.loadingEnrolments = true
        this.enrolments.currentPage = 1
        this.filterEnrolments()
      }
    },
  },
  mounted() {
    this.$store.state.documentation.tab_name = 'enrolments'
    this.$store.state.documentation.active_doc_document_path = './Operations/Enrolments.md'
    this.ps = new PerfectScrollbar(this.$refs.subjectsContainer);
    this.loadingEnrolments = true
    EnrolmentStatus.FetchAll()
    HomeClass.FetchAll({page: 1, limit: 999}, {campus_id: this.activeCampusId, year: this.$store.state.dates.year})

    this.filterEnrolments(this.userObject)

  },
  computed: {
    selectedSubjects() {
      return this.selected_enrolment !== null ? this.selected_enrolment.subject_ids : this.newEnrolment.subject_ids
    },
    new_enrolment_graduated_date: {
      get() {
        if (isValid(parseISO(this.newEnrolment.graduated_date)) && this.newEnrolment.graduated_date !== null) {
          return new Date(this.newEnrolment.graduated_date)
        }
        return null
      }, set(newValue) {
        this.newEnrolment.graduated_date = format(newValue, 'yyyy-MM-dd')
      },
    },
    new_enrolment_enroled_date: {
      get() {
        if (isValid(parseISO(this.newEnrolment.enroled_date)) && this.newEnrolment.enroled_date !== null) {
          return new Date(this.newEnrolment.enroled_date)
        }
        return null
      }, set(newValue) {
        this.newEnrolment.enroled_date = format(newValue, 'yyyy-MM-dd')
      },
    }, new_enrolment_withdrawn_date: {
      get() {
        if (isValid(parseISO(this.newEnrolment.withdrawn_date)) && this.newEnrolment.withdrawn_date !== null) {
          return new Date(this.newEnrolment.withdrawn_date)
        }
        return null
      }, set(newValue) {
        this.newEnrolment.withdrawn_date = format(newValue, 'yyyy-MM-dd')
      },
    }, selected_enrolment_graduated_date: {
      get() {
        if (isValid(parseISO(this.selected_enrolment.graduated_date)) && this.selected_enrolment.graduated_date !== null) {
          return new Date(this.selected_enrolment.graduated_date)
        }
        return null
      }, set(newValue) {
        this.selected_enrolment.graduated_date = format(newValue, 'yyyy-MM-dd')
      },
    }, selected_enrolment_enroled_date: {
      get() {
        if (isValid(parseISO(this.selected_enrolment.enroled_date)) && this.selected_enrolment.enroled_date !== null) {
          return new Date(this.selected_enrolment.enroled_date)
        }
        return null
      }, set(newValue) {
        this.selected_enrolment.enroled_date = format(newValue, 'yyyy-MM-dd')
      },
    }, selected_enrolment_withdrawn_date: {
      get() {
        if (isValid(parseISO(this.selected_enrolment.withdrawn_date)) && this.selected_enrolment.withdrawn_date !== null) {
          return new Date(this.selected_enrolment.withdrawn_date)
        }
        return null
      }, set(newValue) {
        this.selected_enrolment.withdrawn_date = format(newValue, 'yyyy-MM-dd')
      },
    },
    currentTab() {
      let text = ''
      if (this.subjectTab === 0) {
        text = 'enrolments'
      }
      if (this.subjectTab === 1) {
        text = 'subjects'
      }
      if (this.subjectTab === 2) {
        text = 'extramurals'
      }
      return text

    },
    homeClasses() {
      if (this.selected_phase.id) {
        return HomeClass.query().where('phase_id', this.selected_phase.id).orderBy('name').get()
      }
      return []
    },
    select_enrolment_warning: {
      get() {
        return this.selected_enrolment.year
      }, set(year) {
        let old = this.selected_enrolment.year
        this.$buefy.dialog.confirm({
          title: 'Changing enrolment year',
          confirmText: 'Change year',
          hasIcon: true,
          type: 'is-primary',
          message: `You are trying to change the year of an existing enrolment. If you would like to enrol a ${this.$tc('learner', 1)} for another academic year, please create a new enrolment.`,
          onConfirm: () => this.selected_enrolment.year = year,
          onCancel: () => this.$refs.year_edit_select.selected = old
        })
      }

    },
    statuses() {
      return EnrolmentStatus.query().get()
    },
    users() {
      return User.query().with('subjects').with('enrolments').get()
    },
    ...mapState('campus', ['selected_phase', 'selected_stage', 'selected_stage_id', 'filter_enrolment_term', 'selected_user', 'selected_enrolment', 'subject_select_boolean', 'enrolment_filter_status_id']),
    ...mapState('dates', ['year']),
    filters() {
      return {
        ...(this.activeCampusId && !this.inProfile
            ? {
              campus_id: this.activeCampusId,
            }
            : {}), ...(this.selected_user
            ? {
              user_id: this.selected_user.id,
            }
            : {}), ...(this.enrolment_filter_status_id
            ? {
              status_id: this.enrolment_filter_status_id,
            }
            : {}),
        ...(this.selected_phase && !this.inProfile
            ? {
              phase_id: this.selected_phase.id,
            }
            : {}), ...(this.selected_stage && !this.inProfile
            ? {
              stage_id: this.selected_stage.id,
            }
            : {}), ...(this.$store.state.dates.year && !this.inProfile
            ? {
              year: this.$store.state.dates.year,
            }
            : {}),
      }
    },

    enrolments() {
      if (this.enrolmentDataHold === null) {
        return Enrolment.query().where(enrolment => {
          for (let key in this.filters) {
            if (enrolment[key] !== this.filters[key]) return false;
          }
          return true;
        }).with('subjects').with('phase').with('stage').with('extramurals').with('user').with('status').with('home_class').get()
      } else {
        return this.enrolmentDataHold;
      }

    },
    selectedStageSubjects() {
      return Subject.query().where('campus_id', this.activeCampusId).where(subject => {
        return this.$store.state.campus.filter_subject_term ? (subject.name.toLowerCase().includes(this.$store.state.campus.filter_subject_term.toLowerCase()) || (subject.code !== null ? subject.code.toLowerCase().includes(this.$store.state.campus.filter_subject_term.toLowerCase()) : false) || (subject.long_name !== null ? subject.long_name.toLowerCase().includes(this.$store.state.campus.filter_subject_term.toLowerCase()) : false)) : true
      }).with('class_groups').get()
    }, selectedStageExtramurals() {
      return Extramural.query().where('campus_id', this.activeCampusId).get()
    }
  },
  methods: {
    startCreateBulkEnrolments() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          campusId: this.$store.state.campus.selected_campus_id,
          edit: false,
          defaultPhaseId: this.selected_phase?.id ?? null,
          defaultStageId: this.selected_stage_id ?? null,
          canCreate: this.canCreateEnrolment
        },
        component: BulkEnrolmentForm,
        fullScreen: false,
        hasModalCard:true,
        trapFocus: true,
      })
    },
    toggleSubjectTab() {
      this.subjectTab === 1 ? this.subjectTab = 2 : this.subjectTab = 1
      this.$store.dispatch('campus/setFilterSubjectTerm', null)
      this.$store.dispatch('campus/setFilterExtramuralTerm', null)
    },
    changeStatus(event) {
      return event
    },
    cancelEnrolmentAction() {
      if (this.mode === 'edit') {
        this.$store.dispatch('campus/selectEnrolment', null)
        this.subjectTab = 0
        this.$store.dispatch('campus/setFilterSubjectTerm', null)
        this.$store.dispatch('campus/setFilterExtramuralTerm', null)
        this.mode = null
      } else if (this.mode === 'create') {
        this.newEnrolment = {
          status_id: null,
          subjects: [],
          extramurals: [],
          phase_id: null,
          stage_id: null,
          user_id: null,
          campus_id: null, year: this.$store.state.dates.year

        }
        this.subjectTab = 0
        this.$store.dispatch('campus/setFilterSubjectTerm', null)
        this.$store.dispatch('campus/setFilterExtramuralTerm', null)
        this.mode = null
      }
    },
    saveEnrolmentAction() {
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        }
        if (this.mode === 'edit') {
          if (this.canEditEnrolment) {
            this.loadingEnrolments = true
            this.loadingSubjects = true
            this.loadingExtramurals = true
            this.selected_enrolment.subjects = []
            this.selected_enrolment.subject_ids.map(id => {
              this.selected_enrolment.subjects.push({
                subject_id: id,
                class_group_id: Subject.query().whereId(id).first().class_group_id,
                teacher_id: Subject.query().whereId(id).first().teacher_id,
              })
            })

            Enrolment.Update(this.selected_enrolment, true).then(() => {
              this.$buefy.snackbar.open('Enrolment updated!')
              Enrolment.FetchById(this.selected_enrolment.id, ['subjects', 'extramurals', 'home_class', 'subjects.class_groups']).then(() => {
                this.$store.dispatch('campus/selectEnrolment', null)
                if (!this.inProfile) {

                  this.$store.dispatch('campus/selectUser', null)
                }
                this.loadingEnrolments = false
                this.loadingSubjects = false
                this.loadingExtramurals = false
                this.subjectTab = 0
                this.mode = null
              }).then(() => {

                this.filterEnrolments()
                this.$store.dispatch('campus/setFilterSubjectTerm', null)
                this.$store.dispatch('campus/setFilterExtramuralTerm', null)
              })
            }).catch(err => {
              this.loadingEnrolments = false
              this.loadingSubjects = false
              this.loadingExtramurals = false
              this.handleError(err)
            })
          } else {
            this.$store.dispatch('toast/createToast')
          }
        } else if (this.mode === 'create') {
          if (this.canCreateEnrolment) {
            this.loadingEnrolments = true
            this.loadingSubjects = true
            this.newEnrolment.subjects = []
            this.newEnrolment.subject_ids.map(id => {
              this.newEnrolment.subjects.push({
                subject_id: id,
                class_group_id: Subject.query().whereId(id).first().class_group_id,
                teacher_id: Subject.query().whereId(id).first().teacher_id,
              })
            })
            this.newEnrolment.phase_id = this.selected_phase.id
            this.newEnrolment.stage_id = this.selected_stage.id
            this.newEnrolment.campus_id = this.activeCampusId
            this.newEnrolment.user_id = this.selected_user.id
            Enrolment.Store(this.newEnrolment).then(({entities}) => {
              Enrolment.FetchById(entities.enrolments[0].id, ['subjects', 'extramurals', 'home_class', 'subjects.class_groups']).then(() => {
                this.$buefy.snackbar.open('Enrolment created!')
                this.$store.dispatch('campus/selectEnrolment', null)
                if (!this.inProfile) {
                  this.$store.dispatch('campus/selectUser', null)
                }
                this.loadingEnrolments = false
                this.loadingSubjects = false
                this.newEnrolment = {
                  status_id: null,
                  subjects: [],
                  extramurals: [],
                  phase_id: null,
                  stage_id: null,
                  user_id: null,
                  campus_id: null, year: this.$store.state.dates.year

                }
                this.$store.dispatch('campus/setFilterSubjectTerm', null)
                this.$store.dispatch('campus/setFilterExtramuralTerm', null)
                this.subjectTab = 0
                this.mode = null

              })
            }).catch(err => {
              this.loadingEnrolments = false
              this.loadingSubjects = false
              this.handleError(err)
            })
          } else {
            this.$store.dispatch("toast/createToast")
          }
        }
      })
    }
    ,
    selectSubject(ids) {
      if (this.mode === 'edit') {
        this.selected_enrolment.subject_ids = ids
      } else if (this.mode === 'create') {
        this.newEnrolment.subject_ids = ids
      }
    }, selectExtramural(ids) {
      if (this.mode === 'edit') {
        this.selected_enrolment.extramural_ids = ids
      } else if (this.mode === 'create') {
        this.newEnrolment.extramurals = ids
      }
    }
    ,
    selectEnrolment(enrolment) {
      this.loadingEnrolments = true
      Subject.deleteAll()
      Extramural.deleteAll()
      Enrolment.FetchById(enrolment.id, ['subjects', 'extramurals', 'user', 'phase', 'stage', 'home_class', 'subjects.class_groups']).then(({entities}) => {
        this.$store.dispatch('campus/selectCampus', enrolment.campus_id)
        if (Object.prototype.hasOwnProperty.call(entities, 'phases')) {
          this.$store.dispatch('campus/selectPhase', entities.phases[0])
        }
        if (Object.prototype.hasOwnProperty.call(entities, 'stages')) {

          this.$store.dispatch('campus/selectStage', entities.stages[0])
        }
        this.$store.dispatch('campus/selectUser', entities.users[0])
        this.$store.dispatch('campus/selectEnrolment', enrolment)
        if (this.inProfile) {
          // this.loadingEnrolments = false
          // this.subjectTab = 1
          // this.mode = 'edit'
          let promises = []
          if (entities.subjects) {
            entities.subjects.map(subject => {
              if (subject.class_group_id) {
                promises.push(ClassGroup.FetchById(subject.class_group_id, ['teachers']))
              }
            })
          }
          Promise.all(promises).then(() => {
            this.loadingEnrolments = false
            this.subjectTab = 1
            this.mode = 'edit'
          })
        } else {
          let promises = [
            Subject.FetchAll({page: 1, limit: 999}, {
              campus_id: this.$store.state.campus.selected_campus_id, year: this.$store.state.dates.year
            }, ['class_groups']),
            Extramural.FetchAll({page: 1, limit: 999}, {
              campus_id: this.$store.state.campus.selected_campus_id, year: this.$store.state.dates.year
            })
          ]

          if (entities.subjects) {
            entities.subjects.map(subject => {
              if (subject.class_group_id) {
                promises.push(ClassGroup.FetchById(subject.class_group_id, ['teachers']))
              }
            })
          }
          Promise.all(promises).then(() => {
            this.loadingEnrolments = false
            this.subjectTab = 1
            this.mode = 'edit'
          })
        }


      })
    }
    ,
    changeEnrolmentPage(event) {
      this.enrolmentMeta.currentPage = event
      this.filterEnrolments()
    }
    ,
    filterEnrolments(user = null) {
      this.loadingEnrolments = true
      this.enrolmentDataHold = this.enrolments;
      Enrolment.deleteAll()
      if (user) {
        this.$store.dispatch('campus/selectUser', user)
      }
      Enrolment.FetchAll({
        page: this.enrolmentMeta.currentPage,
        limit: this.enrolmentMeta.perPage
      }, this.filters, ['subjects', 'extramurals', 'user', 'year', 'status', 'phase', 'stage', 'home_class']).then(({response: {data: {meta}}}) => {
        this.enrolmentMeta.total = meta.total
        this.enrolmentDataHold = null
        this.loadingEnrolments = false
      })
    }
    ,
    selectCampus() {
      this.$store.dispatch('campus/selectCampus', this.activeCampusId)
    }
    ,
    startCreateEnrolment() {
      this.mode = 'create'
      this.subjectTab = 1
    }
    ,
    deleteEnrolment(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting enrolment',
        confirmText: 'Delete Enrolment',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this enrolment?',
        onConfirm: () => Enrolment.Delete(id).then(() => {
          this.$buefy.snackbar.open(`Enrolment deleted!`)
        }).catch(err => {
          this.handleError(err)
        })
      })
    }
    ,
    createClick() {
      if (this.subjectTab === 1) {
        if (this.canCreateSubject) {
          this.startCreateSubject()
        } else {
          this.$store.dispatch('toast/createToast')
        }
      } else if (this.subjectTab === 2) {
        if (this.canCreateExtramural) {
          this.startCreateExtramural()
        } else {
          this.$store.dispatch('toast/createToast')
        }
      } else {
        if (this.canCreateEnrolment) {
          if (this.selected_user) {
            this.startCreateEnrolment()
          } else {
            this.$store.dispatch('toast/createToast', {message: 'Please select a user to create an enrolment for'})
          }
        } else {
          this.$store.dispatch('toast/createToast')
        }
      }
    }
    ,
    psUpdate() {
      if (this.ps) {
        this.ps.update();
      }
    }
    ,

    editSubject(subject) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          subject: subject
        },
        component: SubjectForm,
        hasModalCard: true,
        trapFocus: true
      })
    }
    ,
    deleteSubject(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting subject',
        confirmText: 'Delete Subject',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this subject?',
        onConfirm: () => Subject.Delete(id).then(() => {
          this.$buefy.snackbar.open(`Subject deleted!`)
        }).catch(err => {
          this.handleError(err)
        })
      })
    }
    ,
    startCreateSubject() {
      if (this.$store.state.campus.selected_stage) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: false,
            stage_id: this.$store.state.campus.selected_stage.id,
            campus_id: this.activeCampusId
          },
          component: SubjectForm,
          hasModalCard: true,
          trapFocus: true
        })
      } else {
        this.$store.dispatch('toast/createToast', {message: 'Select a stage first'})
      }
    },
    editExtramural(extramural) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          edit: true,
          extramural: extramural
        },
        component: ExtramuralForm,
        hasModalCard: true,
        trapFocus: true
      })
    }
    ,
    deleteExtramural(id) {
      this.$buefy.dialog.confirm({
        title: 'Deleting extramural',
        confirmText: 'Delete Extramural',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this extramural?',
        onConfirm: () => Extramural.Delete(id).then(() => {
          this.$buefy.snackbar.open(`Extramural deleted!`)
        }).catch(err => {
          this.handleError(err)
        })
      })
    }
    ,
    startCreateExtramural() {
      if (this.$store.state.campus.selected_stage) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: false,
            stage_id: this.$store.state.campus.selected_stage.id,
            campus_id: this.activeCampusId
          },
          component: ExtramuralForm,
          hasModalCard: true,
          trapFocus: true
        })
      } else {
        this.$store.dispatch('toast/createToast', {message: 'Select a stage first'})
      }
    }
    ,
  },
  props: {

    userObject: {
      type: Object, default() {
        return null
      }
    },
    inProfile: {
      type: Boolean, default() {
        return false
      }
    }, canCreateSubject: {
      type: Boolean, default() {
        return false
      }
    },
    canEditSubject: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteSubject: {
      type: Boolean, default() {
        return false
      }
    }, canCreateExtramural: {
      type: Boolean, default() {
        return false
      }
    },
    canEditExtramural: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteExtramural: {
      type: Boolean, default() {
        return false
      }
    }, canCreateEnrolment: {
      type: Boolean, default() {
        return false
      }
    }, canEditEnrolment: {
      type: Boolean, default() {
        return false
      }
    }, canDeleteEnrolment: {
      type: Boolean, default() {
        return false
      }
    },

    activeCampusId: {},
    campus: {}

  }
}
</script>
