<template>
<PerfectScrollbarWrapper>
  <b-table
      class="is-fullwidth"
      :scrollable="false"
      narrowed :paginated="true" :total="total" :per-page="perPage" :current-page="currentPage"
      @page-change="changePage"
      :backend-pagination="true" :loading="loading" :data="enrolments" :striped="true"
      :hoverable="true"
      :bordered="false"
      @click="enrolmentClick"
      :default-sort="['year']"
      :default-sort-direction="'desc'"
  >
    <template #empty>
      <div class="has-text-centered">No Enrolments</div>
    </template>
    <b-table-column v-slot="props" sortable field="user.first_name" label="First Name">{{
        props.row.user.first_name
      }}
    </b-table-column>
    <b-table-column v-slot="props" sortable field="user.last_name" label="Last Name">{{
        props.row.user.last_name
      }}
    </b-table-column>
    <b-table-column v-slot="props" sortable field="home_class.name" label="HomeClass">{{
        !!props.row.home_class ? props.row.home_class.name : 'None'

      }}
    </b-table-column>
    <b-table-column v-slot="props" sortable field="phase.name" label="Phase">{{
        !!props.row.phase ? props.row.phase.name : 'None'

      }}
    </b-table-column>
    <b-table-column v-slot="props" sortable field="stage.name" label="Stage">{{
        !!props.row.stage ? props.row.stage.name : 'None'

      }}
    </b-table-column>

    <b-table-column v-slot="props" label="Subjects" centered>
      <p class=" icon has-background-grey has-text-white box px-2 py-2">{{
          props.row.subjects.length
        }}</p>
    </b-table-column>
    <b-table-column v-slot="props" label="Status">
      {{ !!props.row.status ? props.row.status.name : "None" }}

    </b-table-column>
    <b-table-column v-slot="props" label="Year">
      {{ !!props.row.year ? props.row.year : "None" }}

    </b-table-column>

    <b-table-column centered width="80" v-slot="props" cell-class="py-1" label="">
      <div v-on:click.stop>
        <b-dropdown position="is-bottom-left" append-to-body aria-role="list" >
          <template #trigger>
            <b-icon
                :icon="$tc('icons.more')"/>
          </template>
          <b-dropdown-item @click="startDelete(props.row)"
                          aria-role="listitem">Delete
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-table-column>

  </b-table>
</PerfectScrollbarWrapper>
</template>

<script>
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper"
export default {
  components: { PerfectScrollbarWrapper },
  name: "EnrolmentsTable",
  methods: {

    enrolmentClick(enrolment) {
        this.$emit('enrolment-click', enrolment)
    },
    startEdit(enrolment) {
      this.$emit('start-edit-enrolment', enrolment)
    }, startDelete(enrolment) {
      this.$emit('start-delete-enrolment', enrolment.id)
    }, changePage(event) {
      this.$emit('change-page', event)
    }
  },

  props: {
    total: {
      type: Number, default() {
        return 20
      }
    }, perPage: {
      type: Number, default() {
        return 20
      }
    }, currentPage: {
      type: Number, default() {
        return 1
      }
    },
    canEditEnrolment: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteEnrolment: {
      type: Boolean, default() {
        return false
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false
      }
    },
    enrolments: {
      type: Array,
      required: true
    },

  }
}
</script>

<style scoped>

</style>
