<template>
  <div class="is-relative">
    <section class="section">
      <div class="columns">
        <div class="column">
          <b-table

              :striped="true"
              :hoverable="true"
              :bordered="false"
              :data="reports">
            <template #empty>
              <div class="has-text-centered">No records</div>
            </template>
           <b-table-column
                v-slot="props"
                label="Report Date"
                field="caption"
                sortable

            >{{ props.row.caption }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Filename"
                field="filename"
                sortable
            >{{ props.row.filename }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Actions"
                custom-key="actions"
                centered

            >
              <b-dropdown position="is-bottom-left"
                          append-to-body aria-role="list">
                <template #trigger="{ active }">
                  <b-button
                      label="Actions"
                      type="is-primary"
                      :icon-right="active ? 'menu-up' : 'menu-down'"/>
                </template>

                <b-dropdown-item has-link aria-role="listitem">
                  <a :href="props.row.temporary_url" target="_blank">Download</a>
                </b-dropdown-item>
                <b-dropdown-item v-if="!!$store.getters['entities/user-permissions/find']('delete report pdfs')" @click="startDelete(props.row)" aria-role="listitem">
                  Delete
                </b-dropdown-item>

              </b-dropdown>
            </b-table-column>


          </b-table>

        </div>
      </div>
    </section>
    <hr class="my-0"/>
    <b-field grouped position="is-centered" class="mt-4">
      <div class="control">
        <b-button v-if="canEdit" :loading="loading" native-type="submit"
                  tag="input"
                  type="submit" value="Save">
        </b-button>
      </div>
    </b-field>

    <b-loading v-model="loading" :is-full-page="false"></b-loading>

  </div>

</template>

<script>


import UserReport from "@/models/UserReport";
import Media from "@/models/Media";

export default {
  name: "ReportsPanel",
  data() {
    return {
      loading: false,
      loaded: false,
      services: [],
      service_ids: []
    }
  },
  props: {
    canEdit: {
      type: Boolean, default() {
        return false
      }
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {
    reports() {
      return Media.query().get()
    },
  },
methods:{
    startDelete(report){
      this.$buefy.dialog.confirm({
        title: "Deleting report",
        message:
        `Are you sure you want to delete this report from the ${this.$tc('learner',1)}'s profile?`,
        confirmText: "Delete Report",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteReport(report),
      });
    },
  deleteReport(report){
      this.loading = true
      Media.Delete(report.id).then(()=>{
        this.loading = false
        this.$buefy.snackbar.open(`Report Deleted`);
      }).catch(err=>{
        this.handleError(err)
      })
  },
},

  mounted() {
    this.loading = true
    Media.deleteAll()
  UserReport.FetchAll({
      page: 1,
      limit: 999
    }, this.user.id)
        .then(() => {

          this.loaded = true
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        }
    )
  }
}
</script>
