<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ title }}</p>
      </header>
      <section class="modal-card-body">
        <b-field v-if="!editMode">
          <p>{{message}}</p>
        </b-field>
        <b-field v-if="showDates" :label="editMode?'Unsubscribe Date':'Preferred Package Change Date'">
          <b-datepicker
              append-to-body
              :years-range="[-1,1]"
              locale="en-ZA"
              :required="requireDates"
              :min-date="editMode?null:yesterday"
              v-model="package_change_date"
              placeholder="Click to select..."
              :icon="$tc('icons.calendar')"
              trap-focus>
          </b-datepicker>
        </b-field>
        <b-field v-if="notice_period>0">
          There is a notice period on the current package, please note this subscription change may only take into effect when the notice period expires.
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button @click="$emit('close')">Cancel
        </b-button>
        <b-button native-type="submit" type="is-primary">Change Package {{editMode?'Unsubscribe Date':''}}
        </b-button>
      </footer>
    </div>
  </form>
</template>

<script>


import {addDays, subDays} from "date-fns";

export default {
  name: "PackageChangeForm",
  data() {
    return {
      package_change_date: null,
    }
  },
  methods: {

    submit() {
      this.$emit('change',this.package_change_date)
      this.$emit('close')
    }
  }
  ,
  mounted() {

  }
  ,computed:{
    yesterday(){
      return subDays(new Date(),1)
    },
    is_within_notice(){
      if (this.package_change_date===null) {
        return false
      }
      return new Date(this.package_change_date) > addDays(new Date(),this.notice_period)
    }
  }
  ,
  props: {
    editMode:{
      props:Boolean,
      default() {
        return false
      }
    }, showDates:{
      props:Boolean,
      default() {
        return false
      }
    }, requireDates:{
      props:Boolean,
      default() {
        return false
      }
    },
    notice_period: {
      type: Number,
      default
          () {
        return 0
      }
    }
    ,
    title: {
      type: String,
      default
          () {
        return "Changing Package (taking place immediately)"
      }
    }
    ,  message: {
      type: String,
      default
          () {
        return "Are you sure you want to change packages?"
      }
    }
    ,
  }
}
</script>

