<template>
  <div v-if="loaded">
    <CampusHeader
        :show-mobile="false"
        :show-head="showHead"
        :show-campus="showCampus"
        :can-create="false"
        :activeTab="activeTab"
        :manage-campuses="!guardianMode"
        :can-select="false"
        :can-select-year="true"
        @close="close"
        @change-tab="changeTab"
        :hide-tabs="true"
        :can-select-wards="canSelectWards"
        :header-text="headerText"
        :close-button="inModal"
        :header_tabs="tabs"
    ></CampusHeader>
    <section class="section pt-4 box">
      <b-tabs expanded multiline  :destroy-on-hide="true"  @input="changeTab" :class="{'hidden-tabs':tabs.length<2}" v-model="activeTab">
        <b-tab-item v-if="useTab('details')" label="User Details">
          <div class=" columns">
            <div class="column is-12">
              <UserCreateForm
                  :show-roles="showRoles"
                  :show-campuses="showCampuses"
                  @deleted="handleDelete"
                  :canCreate="canCreate"
                  :canDelete="canDelete"
                  :canEdit="canEdit"
                  :campuses="campuses"
                  :default-role="defaultRole"
                  :type="type"
                  :user-object="user"
                  :edit="true"
              ></UserCreateForm>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('profile')" label="Profile">
          <div class=" columns">
            <div class="column is-12">
              <ProfilePanel
                  :in-modal="inModal"
                  :can-edit="canEdit||(user.id===$store.state.user.id)"
                  :fields="profileFields"
                  :user="user"
                  :tags="tags"
              ></ProfilePanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('staff-profile')" label="Employee Info">
          <div class=" columns">
            <div class="column is-12">
              <StaffProfilePanel
                  :can-edit="
                  !!$store.getters['entities/user-permissions/find'](
              'edit staff-profiles'
              )
              "
                  :can-create="
                  !!$store.getters['entities/user-permissions/find'](
              'create staff-profiles'
              )
              "
                  :user="user"
              ></StaffProfilePanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('enrolments')" label="Enrolments">
          <div class=" columns">
            <div class="column is-12">
              <EnrolmentsPanel
                  :user-object="user"
                  :in-profile="true"
                  :canCreateSubject="canCreateSubject"
                  :canEditSubject="canEditSubject"
                  :canDeleteSubject="canDeleteSubject"
                  :canCreateExtramural="canCreateExtramural"
                  :canEditExtramural="canEditExtramural"
                  :canDeleteExtramural="canDeleteExtramural"
                  :canCreateEnrolment="canCreateEnrolment"
                  :canDeleteEnrolment="canDeleteEnrolment"
                  :canEditEnrolment="canEditEnrolment"
                  :active-campus-id="$store.state.campus.selected_campus_id"
              ></EnrolmentsPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('guardians')" label="Guardians">
          <div class=" columns">
            <div class="column is-12">
              <GuardiansPanel
                  :canCreate="canCreateGuardian"
                  :canDelete="canDeleteGuardian"
                  :canEdit="canEditGuardian"
                  :can-create-new="
                  !!$store.getters['entities/user-permissions/find'](
                    'create guardians'
                  )
                "
                  :userObject="user"
              ></GuardiansPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('wards')" label="Wards">
          <div class=" columns">
            <div class="column is-12">
              <WardsPanel
                  @view-ward="emitViewWard"
                  :guardian-mode="guardianMode"
                  :canCreate="canCreateWard"
                  :can-create-new="canCreateLearner"
                  :canDelete="canDeleteWard"
                  :userObject="user"
              ></WardsPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('services')" label="Services">
          <div class=" columns">
            <div class="column is-12">
              <ServicesPanel
                  :campus_id="user.campus_ids[0]"
                  :can-edit="canEditServices"
                  :can-force="canForceServices"
                  :user="user"
              ></ServicesPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('notes')" label="Notes">
          <div class=" columns">
            <div class="column is-12">
              <NotesPanel
                  :model="'user'"
                  :model_id="user.id"
              ></NotesPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('media')" label="Media">
          <div class="columns">
            <div class="column is-12">
              <UserMediaPanel
                  :user="user"
                  :tags="tags"
                  :showGuardianVisibility="showGuardianVisibility"
              ></UserMediaPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('observations')" label="Observations">
          <div class=" columns">
            <div class="column is-12">
              <ObservationsPanel
                  :can-edit="canEdit"
                  :user="user"
              ></ObservationsPanel>
            </div>
          </div>
        </b-tab-item>

        <b-tab-item v-if="useTab('activities')" label="Activities">
          <div class=" columns">
            <div class="column is-12">
              <ActivitiesPanel
                  :user="user"
              ></ActivitiesPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('reports')" label="Reports">
          <div class=" columns">
            <div class="column is-12">
              <ReportsPanel
                  :user="user"
              ></ReportsPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('sports-house')" label="Sports House">
          <div class=" columns">
            <div class="column is-12">
              <SportsHousesPanel
                  :user="user"
              ></SportsHousesPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('leave-requests')" label="Leave Requests">
          <div class=" columns">
            <div class="column is-12">
              <LeavePanel
                  :user="user"
                  :can-edit="!!$store.getters['entities/user-permissions/find']('edit leave-requests')"
                  :can-delete="!!$store.getters['entities/user-permissions/find']('delete leave-requests')"
                  :can-create="!!$store.getters['entities/user-permissions/find']('create leave-requests')"
                  :can-manage="!!$store.getters['entities/user-permissions/find']('manage leave requests')"
              ></LeavePanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('incidents')" label="Incidents">
          <div class=" columns">
            <div class="column is-12">
              <IncidentsPanel
                  :user="user"
                  :can-edit="!!$store.getters['entities/user-permissions/find']('edit incidents')"
                  :can-delete="!!$store.getters['entities/user-permissions/find']('delete incidents')"
                  :can-create="!!$store.getters['entities/user-permissions/find']('create incidents')"
                  :can-manage="!!$store.getters['entities/user-permissions/find']('administrate incidents')"
              ></IncidentsPanel>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item v-if="useTab('covid-assessments')" label="Covid Assessments">
          <div class=" columns">
            <div class="column is-12">
              <CovidAssessmentsIndex
                  :default-user-id="user.id"
                  :can-edit="!!$store.getters['entities/user-permissions/find']('edit covid assessments')"
                  :can-delete="!!$store.getters['entities/user-permissions/find']('delete covid assessments')"
                  :can-create="!!$store.getters['entities/user-permissions/find']('create covid assessments')"
              ></CovidAssessmentsIndex>
            </div>
          </div>
        </b-tab-item>


      </b-tabs>
    </section>
  </div>
</template>

<script>
import User from "@/models/User";
import Role from "@/models/Role";
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import UserCreateForm from "@/components/users/UserCreateForm";
import {mapState} from "vuex";
import Campus from "@/models/Campus";
import EnrolmentsPanel from "@/components/panelled-dash/EnrolmentsPanel";
import GuardiansPanel from "@/components/users/GuardiansPanel";
import WardsPanel from "@/components/users/WardsPanel";
import ProfilePanel from "@/components/users/ProfilePanel";
import ObservationsPanel from "@/components/users/ObservationsPanel";
import ServicesPanel from "@/components/users/ServicesPanel";
import ActivitiesPanel from "@/components/users/ActivitiesPanel";
import NotesPanel from "@/components/users/NotesPanel";
import UserMediaPanel from "@/components/users/UserMediaPanel";
import ReportsPanel from "@/components/users/ReportsPanel";
import SportsHousesPanel from "@/components/users/SportsHousesPanel";
import StaffProfilePanel from "@/components/users/StaffProfilePanel";
import LeavePanel from "@/components/users/LeavePanel";
import IncidentsPanel from "@/components/users/IncidentsPanel";
import CovidAssessmentsIndex from "@/components/covidAssessments/CovidAssessmentIndex";
// import Enrolment from "@/models/Enrolment";

export default {
  name: "UserProfile",
  components: {
    CovidAssessmentsIndex,
    IncidentsPanel,
    LeavePanel,
    UserMediaPanel,
    CampusHeader,
    UserCreateForm,
    EnrolmentsPanel,
    GuardiansPanel,
    WardsPanel,
    ProfilePanel,
    ObservationsPanel,
    ServicesPanel,
    ActivitiesPanel,
    NotesPanel,
    ReportsPanel,
    SportsHousesPanel,
    StaffProfilePanel
  },
  data() {
    return {
      loaded: false,
      activeTab: 0,
      search: null,
      loadingFilter: false,
      userDataHold: null,
      meta: Object,
      page: 1,
      suppress_campus: true,
      limit: 20,
      loadingData: false,
      searchHold: null,
      tags: [
        "Academic",
        "Medical",
        "Personal",
        "Identification",
        "Photographic",
        "Financial"
      ]
    };
  },
  props: {
    showGuardianVisibility: {
      type: Boolean,
      default() {
        return true
      }
    },
    canSelectWards: {
      type: Boolean,
      default() {
        return false;
      }
    },  showHead: {
      type: Boolean,
      default() {
        return true;
      }
    }, showRoles: {
      type: Boolean,
      default() {
        return true;
      }
    }, showCampuses: {
      type: Boolean,
      default() {
        return true;
      }
    },
    guardianMode: {
      type: Boolean, default() {
        return false
      }
    },
    showCampus: {
      type: Boolean, default() {
        return false
      }
    },
    canCreateSubject: {
      type: Boolean,
      default() {
        return false;
      },
    }, canCreateExtramural: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateGuardian: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEditGuardian: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDeleteGuardian: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateLearner: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateWard: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEditWard: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDeleteWard: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEditSubject: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDeleteSubject: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEditExtramural: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEditServices: {
      type: Boolean,
      default() {
        return false;
      },
    }, canForceServices: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDeleteExtramural: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateEnrolment: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEditEnrolment: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDeleteEnrolment: {
      type: Boolean,
      default() {
        return false;
      },
    },

    defaultRole: {
      type: String,
      default() {
        return null;
      },
    },
    inModal: {
      type: Boolean,
      default() {
        return false;
      },
    },
    proppedUser: {
      type: Object,
      required: true,
    },
    tabs: {type: Array, required: true},
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
    profileFields: {
      type: Array,
      default() {
        return null;
      },
    },
    tabEntity: {
      type: String,
      default() {
        return "Staff";
      },
    },
    type: {
      type: String,
      default() {
        return "Staff Member";
      },
    },
  },
  methods: {
    emitViewWard(ward) {
      this.$emit('view-ward', ward)
    },
    changeTab(index) {
      this.activeTab = index;
      this.setQuery({tab: this.tabs[index].role})
    },
    goToTabName(tabName) {
      this.activeTab = this.tabs.findIndex(tab => {
        return tab.role === tabName
      })
      this.setQuery({tab: tabName})

    },
    handleDelete() {
      this.$emit("deleted");
    },
    useTab(role) {
      return this.tabs.some((tab) => tab.role === role);
    },
    close() {
      this.$emit("close");
    },
    startDelete(user) {
      this.$buefy.dialog.confirm({
        title: `Deleting ${this.type}`,
        confirmText: `Delete ${this.type}`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this ${this.type}?`,
        onConfirm: () =>
            User.Delete(user.id)
                .then(() => {
                  this.$buefy.snackbar.open(`${this.type} deleted!`);
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
  },

  computed: {
    campuses() {
      return Campus.query().get();
    },
    headerText() {
      return `${this.user.first_name} ${this.user.last_name}`;
    },
    ...mapState("campus", ["selected_user"]),
    roles() {
      return Role.query().get();
    },
    user() {
      return User.query()
          .whereId(parseInt(this.proppedUser.id))
          .with("roles")
          .with("wards")
          .with("guardians")
          .with("campuses")
          .with('services')
          .first();
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    Promise.all([
      Role.FetchAll({page: 1, limit: 999}),
      User.FetchById(this.proppedUser.id, ["roles", "campuses"]),
    ]).then(() => {
      if (this.$route.query.covid_assessment_id) {
        if (this.useTab('covid-assessments')) {
          this.goToTabName('covid-assessments')
        }
      }
      if (this.$route.query.tab) {
        if (this.useTab(this.$route.query.tab)) {
          this.goToTabName(this.$route.query.tab)
        } else {
          this.removeQuery(['tab'])
        }
      }
      this.$store.dispatch("loader/hide");

      this.loaded = true;
    });
    if (this.inModal) {
      this.makeModalLarge()

    }  },
};
</script>
