import {format, toDate} from "date-fns";

export default {
    methods:
        {
            dateParse(date) {
                if (date === null) {
                    return null
                }
                return toDate(date)
            },

            dateFormat(date) {

                return format(new Date(date), 'yyyy-MM-dd')
            }, dateFormatTime(date) {
                return format(date, 'yyyy-MM-dd HH:mm')
            },
        }
}
