<template>
  <form @submit.prevent="submit">
    <section class="section">
      <div class="columns">
        <div class="column">
          <b-field label="Sports House">
            <b-select expanded v-model="sports_house_id">
              <option v-for="house in sportsHouses" :key="house.id" :value="house.id">{{ house.name }}</option>
            </b-select>
          </b-field>
        </div>
      </div>
    </section>
    <hr class="my-0"/>
    <b-field grouped position="is-centered" class="mt-4">
      <div class="control">
        <b-button v-if="canEdit" :loading="loading" native-type="submit"
                  tag="input"
                  type="submit" value="Save">
        </b-button>
      </div>
    </b-field>

    <b-loading v-model="loading" :is-full-page="false"></b-loading>

  </form>

</template>

<script>


import User from "@/models/User";
import SportsHouse from "@/models/SportsHouse";

export default {
  name: "SportsHousesPanel",
  data() {
    return {
      loading: false,
      loaded: false,
      sports_house_id: []
    }
  },
  props: {
    canEdit: {
      type: Boolean, default() {
        return true
      }
    },
    user: {
      type: Object,
      required: true
    }
  },
  computed: {

    sportsHouses() {
      return SportsHouse.query().orderBy('name').get()
    },
  },

  methods: {
    mountActions() {
      this.loading = true
      SportsHouse.deleteAll()
      Promise.all([SportsHouse.FetchAll({
        page: 1,
        limit: 999
      }, {
        campus_id: this.$store.state.campus.selected_campus_id,
      }), User.FetchById(this.user.id, ['sports_house'])])
          .then(() => {
            this.sports_house_id = this.user.sports_house_id
            this.loaded = true
            this.loading = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false
          }
      )
    },
    submit() {
      if (this.canEdit) {
        this.loading = true

        User.Update({
          id: this.user.id,
          sports_house: this.sports_house_id,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email
        }, false).then(() => {
          this.$buefy.snackbar.open('Sports House Updated!')
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        this.$store.dispatch('toast/createToast')
      }
    },
  },

  mounted() {
    this.mountActions()
  }
}
</script>
