<template>
  <div>
    <div class="level">
      <!-- Left side -->
      <div class="level-left">
        <div class="level-item">
          <span class="is-size-3">{{ guardianMode ? 'Learners' : 'Wards' }}</span>
        </div>
      </div>
      <!-- Right side -->
      <div class="level-right">
        <div v-if="tab === 0 && canCreate" class="level-item">
          <b-field v-if="!guardianMode" grouped>
            <b-button
                :icon-right="$tc('icons.create')"
                type="is-primary"
                @click="tab = 1"
            >
              Add ward
            </b-button>
          </b-field>
          <b-field v-if="canCreateNew && guardianMode">
            <b-button
                :icon-right="$tc('icons.create')"
                type="is-primary"
                @click="create()"
            >Create new Ward
            </b-button>
          </b-field>
        </div>
        <div class="level-item">
          <b-field v-if="tab === 1" grouped>
            <b-field>
              <b-button icon-right="cancel" type="is-danger" @click="tab = 0"
              >Cancel
              </b-button
              >
            </b-field>
            <b-field v-if="canCreateNew">
              <b-button
                  :icon-right="$tc('icons.create')"
                  type="is-primary"
                  @click="create()"
              >Create new Ward
              </b-button>
            </b-field>
          </b-field>
        </div>
      </div>
    </div>
    <b-tabs v-model="tab" class="hidden-tabs no-padding" expanded multiline>
      <b-tab-item>
        <PerfectScrollbarWrapper>
          <b-table
              :bordered="false"
              :data="user.wards"
              :hoverable="true"
              :loading="!loaded"
              :mobile-sort-placeholder="'Click here to sort'"
              :scrollable="false"
              :striped="true"
              class="margin-top is-fullwidth"
          >
            <template #empty>
              <div class="has-text-centered">No Wards</div>
            </template>
            <b-table-column
                v-slot="props"
                field="id"
                label="ID"
                numeric
                sortable
                width="40"
            >{{ props.row.id }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="first_name"
                label="First Name"
                sortable
            >{{ props.row.first_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="last_name"
                label="Last Name"
                sortable
            >{{ props.row.last_name }}
            </b-table-column>
            <b-table-column v-slot="props" field="email" label="Email" sortable>{{
                props.row.email
              }}
            </b-table-column>
            <b-table-column v-slot="props" centered custom-key="actions" narrowed>
              <b-field grouped style="width: 100%">
                <b-field>
                  <b-button type="is-primary" @click="viewUser(props.row)"
                  >View
                  </b-button
                  >
                </b-field>
                <b-field v-if="canSelect" position="is-left">
                  <b-button type="is-primary" @click="$emit('selected',props.row)"
                  >Select
                  </b-button
                  >
                </b-field>
                <b-field v-if="canDelete">
                  <b-tooltip label="Remove" size="is-small" type="is-danger">
                    <b-button
                        :icon-right="$tc('icons.minus')"
                        type="is-danger"
                        @click="removeWard(props.row)"
                    ></b-button>
                  </b-tooltip>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>
        </PerfectScrollbarWrapper>
      </b-tab-item>
      <b-tab-item>
        <UsersFilter
            :role="'learner'"
            :type="'filter'"
            @cleared="clear"
            @filtered="setList"
        ></UsersFilter>
        <PerfectScrollbarWrapper>
          <b-table
              :bordered="true"
              :data="addList"
              :hoverable="true"
              :striped="true"
              class="margin-top is-fullwidth"
          >
            <template #empty>
              <div class="has-text-centered">No Wards Selected</div>
            </template>
            <b-table-column
                v-slot="props"
                field="id"
                label="ID"
                numeric
                sortable
                width="40"
            >{{ props.row.id }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="first_name"
                label="First Name"
                sortable
            >{{ props.row.first_name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                field="last_name"
                label="Last Name"
                sortable
            >{{ props.row.last_name }}
            </b-table-column>
            <b-table-column v-slot="props" field="email" label="Email" sortable>{{
                props.row.email
              }}
            </b-table-column>
            <b-table-column v-slot="props" centered custom-key="actions" narrowed>
              <b-field grouped>
                <b-field>
                  <b-button type="is-primary" @click="viewUser(props.row)"
                  >View
                  </b-button
                  >
                </b-field>
                <b-field v-if="canCreate">
                  <b-tooltip label="Add" size="is-small" type="is-success">
                    <a
                        class="card-header-icon has-text-primary px-0 py-0"
                        @click.prevent.stop="addWard(props.row)"
                    >
                      <b-icon :icon="$tc('icons.create')" size="is-medium"/>
                    </a>
                  </b-tooltip>
                </b-field>
              </b-field>
            </b-table-column>
          </b-table>
        </PerfectScrollbarWrapper>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import UserProfile from "@/components/users/UserProfile";
import User from "@/models/User";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import UserCreateForm from "@/components/users/UserCreateForm";
import Campus from "@/models/Campus";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  components: {PerfectScrollbarWrapper, UsersFilter},
  name: "WardsPanel",
  data() {
    return {
      tab: 0,
      loaded: false,
      type: "Ward",
      addList: [],
    };
  },
  mounted() {
    User.FetchById(this.userObject.id, ["wards"]).then(() => {
      this.loaded = true;
    });
  },
  computed: {

    campuses() {
      return Campus.query().get()
    },
    user() {
      return User.query().whereId(this.userObject.id).with("wards").first();
    },
  },
  methods: {
    create() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          inModal: true,
          type: "Ward",
          defaultRole: "learner",
          edit: false,
          canCreate: this.canCreateNew,
          campuses: this.campuses
        },
        component: UserCreateForm,
        fullScreen: false,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "user-created": (user) => {
            this.addWard(user);
          },
        },
      });
    },
    addWard(ward) {
      this.loaded = false;
      let user = this.user;
      user.wards = user.ward_ids;
      user.wards.push(ward.id);
      User.Update(
          {
            id: user.id,
            wards: user.wards,
            title: user.title,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
          },
          true
      )
          .then(() => {
            User.FetchById(this.userObject.id, ["wards"]).then(() => {
              this.loaded = true;
              this.$buefy.snackbar.open("Ward added!");
              this.tab = 0;
            });
          })
          .catch(
              err => {
                if (err.response.status === 422) {
                  this.handleError(err)
                } else {
                  this.handleError(err)
                }
              }
          )
    },
    removeWard(remove_ward) {
      this.loaded = false;
      let user = this.user;
      user.wards = user.ward_ids;
      user.wards = user.wards.filter((ward) => {
        return ward !== remove_ward.id;
      });
      User.Update(
          {
            id: user.id,
            wards: user.wards,
            title: user.title,
            first_name: user.first_name,
            last_name: user.last_name,
            email: user.email,
          },
          true
      )
          .then(() => {
            User.update({where: user.id, data: {ward_ids: user.wards}}).then(
                () => {
                  this.loaded = true;
                  this.$buefy.snackbar.open("Ward removed!");
                  this.tab = 0;
                }
            );
          })
          .catch((err) => {
            this.handleError(err)
          });
    },
    clear() {
      this.addList = [];
    },
    setList({result}) {
      this.addList = result.entities.users;
    },
    viewUser(user) {
      if (this.guardianMode) {
        this.$emit('view-ward', user)
      } else {
        this.$buefy.modal.open({
          parent: this,
          props: {
            canCreateWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
            canDeleteWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
            canEditWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')
            ,
            canCreateGuardian: !!this.$store.getters['entities/user-permissions/find']('edit learners'),
            canDeleteGuardian: !!this.$store.getters['entities/user-permissions/find']('edit learners'),
            canEditGuardian: !!this.$store.getters['entities/user-permissions/find']('edit learners'),
            inModal: true,
            tabs: [
              {title: "User Details", role: "details"},
              {title: "Guardians", role: "guardians"},
            ],
            proppedUser: user,
            canEdit: !!this.$store.getters["entities/user-permissions/find"](
                "edit learners"
            ),
            canDelete: !!this.$store.getters["entities/user-permissions/find"](
                "delete learners"
            ),
          },
          component: UserProfile,
          fullScreen: true,
          trapFocus: true,
        });
      }
    },
  },
  props: {
    guardianMode: {
      type: Boolean, default() {
        return false
      }
    },
    canSelect: {
      type: Boolean, default() {
        return false
      }
    },
    userObject: {
      type: Object,
      required: true,
    },
    canCreate: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canCreateNew: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canDelete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    canEdit: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>

<style scoped>
</style>
