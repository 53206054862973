<template>
  <form @submit.prevent="submit">
    <section>
      <div class="columns is-centered is-multiline">
        <div v-if="useProfileField('date_of_birth')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field expanded label="Date of birth">
            <b-datepicker
                :required="required"
                :years-range="[-100,100]"
                locale="en-ZA"
                v-model="date_of_birth"
                placeholder="Click to select..."
                :icon="$tc('icons.calendar')"
                trap-focus>
            </b-datepicker>
          </b-field>
        </div>
        <div v-if="useProfileField('id')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Type of ID">
            <b-select expanded :required="required" v-model="profile.identification_type_id" placeholder="ID type">
              <option
                  v-for="type in id_types" :key="type.id" :value="type.id">{{ type.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-if="useProfileField('id')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="ID number">

            <b-input
                :required="required"
                autocomplete="off"

                v-model="profile.id_number"
                placeholder="ID number"
            />

          </b-field>
        </div>
        <div v-if="useProfileField('id')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <ValidationObserver ref="observer">
            <b-field expanded label="Country of issue">
              <ValidationProvider :rules="country?'autocomplete:@confirm':''" v-slot="{ errors }">
                <b-autocomplete
                    :data="countries"
                    autocomplete="off"
                    v-model="country"
                    placeholder="Country"
                    name="country"
                    :required="required"
                    field="name"
                    :clear-on-select="false"
                    @select="option=>selectCountry(option)"
                >
                </b-autocomplete>
                <span class="has-text-weight-bold has-text-danger">{{ errors[0] }}</span>

              </ValidationProvider>
            </b-field>

            <b-field class="is-hidden" label="Confirm password" horizontal>
              <ValidationProvider :rules="country?'required':''" name="confirm" v-slot="{ errors }">
                <b-input v-model="profile.country_of_issue_id"/>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </ValidationObserver>
        </div>

        <div v-if="useProfileField('gender')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Gender">
            <b-select :required="required"
                      expanded v-model="profile.gender_id" placeholder="Gender">
              <option v-for="gender in genders" :key="gender.id" :value="gender.id">{{ gender.name }}</option>
            </b-select>
          </b-field>
        </div>
        <div v-if="useProfileField('language')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <ValidationObserver ref="observer">
            <b-field expanded label="Home language">
              <ValidationProvider :rules="language?'autocomplete:@confirm':''" v-slot="{ errors }">
                <b-autocomplete
                    :data="languages"
                    autocomplete="off"
                    v-model="language"
                    placeholder="Language"
                    name="language"
                    :required="required"
                    field="name"
                    :clear-on-select="false"
                    @select="option=>selectLanguage(option)"
                >
                </b-autocomplete>
                <span class="has-text-weight-bold has-text-danger">{{ errors[0] }}</span>

              </ValidationProvider>
            </b-field>

            <b-field class="is-hidden" label="Confirm password" horizontal>
              <ValidationProvider :rules="language?'required':''" name="confirm" v-slot="{ errors }">
                <b-input v-model="profile.language_id"/>
                <span>{{ errors[0] }}</span>
              </ValidationProvider>
            </b-field>
          </ValidationObserver>
        </div>

        <div v-if="useProfileField('ethnicity')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Ethnicity">
            <b-select :required="required" expanded v-model="profile.ethnicity_id" placeholder="Ethnicity">
              <option v-for="ethnicity in ethnicities" :key="ethnicity.id" :value="ethnicity.id">{{
                  ethnicity.name
                }}
              </option>
            </b-select>
          </b-field>
        </div>

        <div v-if="useProfileField('dominant_hand')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Dominant hand">
            <b-select :required="required" expanded v-model="profile.dominant_hand"
                      placeholder="Dominant hand">
              <option value="Right-hand">Right-hand</option>
              <option value="Left-hand">Left-hand</option>
              <option value="Ambidextrous">Ambidextrous</option>
            </b-select>
          </b-field>
        </div>
        <div v-if="useProfileField('number_of_dependants')" class="column "
             :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Number of Dependants">
            <b-numberinput v-model="profile.number_of_dependants" :step="1" :min="0">

            </b-numberinput>
          </b-field>
        </div>
        <div v-if="useProfileField('tax_number')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="SARS Tax Number">
            <b-input placeholder="Tax Number" v-model="profile.tax_number">

            </b-input>
          </b-field>
        </div>
        <div v-if="useProfileField('religion')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Religion">
            <b-select :required="required" expanded v-model="profile.religion_id" placeholder="Religion">
              <option v-for="religion in religions" :key="religion.id" :value="religion.id">{{
                  religion.name
                }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-if="useProfileField('grade')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Grade">
            <b-select :required="required" expanded v-model="profile.grade_id"
                      placeholder="Grade">
              <option v-for="grade in grades" :key="grade.id" :value="grade.id">{{
                  grade.name
                }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-if="useProfileField('school')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Current School">
            <b-input :required="required" expanded v-model="profile.school_attended.name"
                     placeholder="Current School"></b-input>
          </b-field>
        </div>
        <div v-if="useProfileField('cemis_number')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="CEMIS number">
            <b-input v-model="profile.cemis_number" placeholder="CEMIS number"></b-input>
          </b-field>
        </div>

      </div>
      <div v-if="useProfileField('consent')" class="px-2 py-1"
           style="border: red dotted 2px;border-radius: 10px">
        <p class="has-text-weight-bold">For Admin Office Only</p>
        <p>To request changes please send a formal notification to your campus office.</p>
        <div class="columns is-multiline">
          <div v-for="(consent,index) in consents" :key="index" class="column "
               :class="{'is-12':inModal,'is-4':!inModal}">
            <b-field expanded :label="consentTitle(consent.type)">
              <b-switch :disabled="!can_edit_consent"
                        :required="consent.type!=='picture'"
                        v-model="consent.is_given" :true-value="true" :false-value="false">
                {{ consentText(consent.type) }}
              </b-switch>
            </b-field>
          </div>
          <div v-if="useProfileField('day_length')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
            <b-field label="Day length">
              <b-select disabled v-model="profile.day_length">
                <option :value="3">Half Day excl. lunch</option>
                <option :value="1">
                  Half Day incl. lunch
                </option>
                <option :value="2">
                  Full Day
                </option>
              </b-select>
            </b-field>
          </div>
          <div v-if="useProfileField('lunch')" class="column " :class="{'is-12':inModal,'is-4':!inModal}">
            <b-field label="Lunch option">
              <b-select disabled v-model="profile.lunch_option">
                <option :value="null">None</option>
                <option value="Lunch">
                  Lunch Normal
                </option>
                <option value="Lunch vegan">
                  Lunch Vegan
                </option>
                <option value="Lunch vegetarian">
                  Lunch Vegetarian
                </option>
                <option value="Lunch gluten-free">
                  Lunch Gluten-free
                </option>
                <option value="Lunch dairy-free">Lunch Dairy-free</option>
              </b-select>
            </b-field>
          </div>
        </div>

      </div>
      <hr v-if="useProfileField('occupation')">
      <h3 v-if="useProfileField('occupation') " class="is-size-4">Employment Information:</h3>

      <div v-if="useProfileField('occupation')" class="columns is-centered mt-4 is-multiline">
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Occupation">
            <b-input :required="required" v-model="profile.employment_information.type_of_work"
                     placeholder="Occupation"></b-input>
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Employer">
            <b-input :required="required" v-model="profile.employment_information.employer"
                     placeholder="Employer"></b-input>
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field expanded label="Employer's contact number">
            <b-input :required="required" type="tel"
                     validation-message="Please enter a valid phone number"
                     pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                     v-model="profile.employment_information.contact_number"
                     placeholder="Employer's contact number"></b-input>
          </b-field>
        </div>
      </div>
      <hr v-if="useProfileField('siblings')">
      <h3 v-if="useProfileField('siblings') " class="is-size-4">Siblings:</h3>
      <div v-if="useProfileField('siblings') " class="columns is-centered mt-4">
        <b-field>
          <div class="table-container">
            <table class="table is-bordered is-striped">
              <thead>
              <tr class="table-input">
                <th>Name</th>
                <th>Age</th>
                <th>Gender</th>
                <th>
                  Current School
                </th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(sibling,index) in profile.siblings" :key="index">
                <td>
                  <b-field>
                    <b-input
                        autocomplete="off"
                        placeholder="Bob"
                        v-model="sibling.name"
                        name="name"
                    />
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-input
                        type="number"
                        autocomplete="off"
                        placeholder="12"
                        v-model="sibling.age"
                        name="age"
                    />
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-select v-model="sibling.gender" placeholder="Gender">
                      <option v-for="gender in genders" :key="gender.id" :value="gender.name">{{ gender.name }}</option>
                    </b-select>
                  </b-field>
                </td>
                <td>
                  <b-field>
                    <b-input
                        autocomplete="off"
                        placeholder="School name"
                        v-model="sibling.current_school"
                        name="current_school"
                    />
                  </b-field>
                </td>


                <td>
                  <b-button
                      icon-right="delete"
                      type="is-ghost"
                      class="has-text-danger"                      @click.prevent="remove_sibling(index)"
                  />
                </td>

              </tr>
              </tbody>
            </table>
            <b-field>
              <b-button
                  type="is-success"
                  @click.prevent="add_sibling"
              >Add sibling
              </b-button>
            </b-field>
          </div>
        </b-field>

      </div>
      <hr v-if="useProfileField('medical_information')">

      <h3 v-if="useProfileField('medical_information') " class="is-size-4">Medical Information:</h3>

      <div v-if="useProfileField('medical_information') " class="columns is-centered is-multiline mt-4">
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field :label="`Doctor${useProfileField('non_learner_medical')?'':' / Paediatrician'} name`">
            <b-input
                autocomplete="off"
                :required="required"
                v-model="profile.medical_information.doctor_name"
                :placeholder="`Doctor${useProfileField('non_learner_medical')?'':' / Paediatrician'} name`"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Contact number">
            <b-input
                autocomplete="off"
                :required="required"
                type="tel"
                validation-message="Please enter a valid phone number"
                pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                v-model="profile.medical_information.contact_number"
                placeholder="Contact number"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Has medical aid">
            <b-select expanded v-model="profile.medical_information.has_medical_aid" :required="required"
            >
              <option :value="true">Yes</option>
              <option :value="false">No</option>
            </b-select>

          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Medical aid provider">
            <b-input
                autocomplete="off"
                :disabled="!profile.medical_information.has_medical_aid"
                :required="required && profile.medical_information.has_medical_aid"
                v-model="profile.medical_information.medical_aid_provider"
                placeholder="Medical aid provider"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Medical aid number">
            <b-input
                autocomplete="off"
                :disabled="!profile.medical_information.has_medical_aid"
                :required="required && profile.medical_information.has_medical_aid"
                v-model="profile.medical_information.medical_aid_number"
                placeholder="Medical aid number"
            />
          </b-field>
        </div>
        <div v-if="!useProfileField('non_learner_medical')" class="column "
             :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Allergies">
            <b-input type="textarea" autocomplete="off" :required="required"
                     v-model="profile.medical_information.allergies" placeholder="Allergies"/>
          </b-field>
        </div>
        <div v-if="!useProfileField('non_learner_medical')" class="column "
             :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Additional Information">
            <b-input
                type="textarea"
                autocomplete="off"
                :required="required"
                v-model="profile.medical_information.additional_information"
                placeholder="Additional information"
            />
          </b-field>
        </div>

      </div>
      <hr v-if="useProfileField('emergency_contact')">

      <h3 v-if="useProfileField('emergency_contact') " class="is-size-4">Emergency Contact:</h3>

      <div v-if="useProfileField('emergency_contact') " class="columns is-centered is-multiline mt-4">
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="First name">
            <b-input
                autocomplete="off"
                :required="required"
                v-model="profile.emergency_contact.first_name"
                placeholder="First name"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Last name">
            <b-input
                autocomplete="off"

                v-model="profile.emergency_contact.last_name"
                placeholder="Last name"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Contact Number">
            <b-input
                autocomplete="off"
                :required="required"
                type="tel"
                validation-message="Please enter a valid phone number"
                pattern="^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$"
                v-model="profile.emergency_contact.contact_number"
                placeholder="Contact Number"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Relationship">
            <b-input
                autocomplete="off"
                :required="required"
                v-model="profile.emergency_contact.relationship"
                placeholder="Relationship"
            />
          </b-field>
        </div>


      </div>
      <hr v-if="useProfileField('home_address')">

      <h3 v-if="useProfileField('home_address') " class="is-size-4">Home Address:</h3>

      <div v-if="useProfileField('home_address') " class="columns is-centered is-multiline mt-4">
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Building Number">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                v-model="profile.physical_address.line_2"
                placeholder="10 Fancy Mansions"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Street Address">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                :required="required"
                v-model="profile.physical_address.line_1"
                placeholder="15 Main Street"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Suburb">
            <b-input
                autocomplete="off"
                :required="required"
                :icon="$tc(`icons.map-marker`)"
                v-model="profile.physical_address.suburb"
                placeholder="Constantia"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="City">
            <b-input
                autocomplete="off"
                :required="required"
                :icon="$tc(`icons.map-marker`)"
                v-model="profile.physical_address.city"
                placeholder="Cape Town"
            />
          </b-field>
        </div>

        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Province">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                :required="required"
                v-model="profile.physical_address.province"
                placeholder="Western Cape"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Postal Code">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                :required="required"
                v-model="profile.physical_address.postal_code"
                placeholder="5432"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Country">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                :required="required"
                v-model="profile.physical_address.country"
                placeholder="South Africa"
            />
          </b-field>
        </div>
      </div>
      <hr v-if="useProfileField('work_address')">

      <h3 v-if="useProfileField('work_address')" class="is-size-4">Work Address:</h3>

      <div v-if="useProfileField('work_address')" class="columns is-centered is-multiline mt-4">
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">

          <b-field label="Street Address">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"

                v-model="profile.alternative_address.line_1"
                placeholder="15 Main Street"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Building Number">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"
                v-model="profile.alternative_address.line_2"
                placeholder="10 Fancy Mansions"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="City">
            <b-input
                autocomplete="off"

                :icon="$tc(`icons.map-marker`)"
                v-model="profile.alternative_address.city"
                placeholder="Cape Town"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Suburb">
            <b-input
                autocomplete="off"
                :required="required"

                :icon="$tc(`icons.map-marker`)"
                v-model="profile.alternative_address.suburb"
                placeholder="Constantia"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Province">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"

                v-model="profile.alternative_address.province"
                placeholder="Western Cape"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Postal Code">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"

                v-model="profile.alternative_address.postal_code"
                placeholder="5432"
            />
          </b-field>
        </div>
        <div class="column " :class="{'is-12':inModal,'is-4':!inModal}">
          <b-field label="Country">
            <b-input
                autocomplete="off"
                :icon="$tc(`icons.map-marker`)"

                v-model="profile.alternative_address.country"
                placeholder="South Africa"
            />
          </b-field>
        </div>
      </div>

    </section>
    <hr class="my-0"/>
    <b-field grouped position="is-centered" class="mt-4">
      <div class="control">
        <b-button v-if="canEdit" :loading="loading" native-type="submit"
                  tag="input"
                  type="submit" value="Save">
        </b-button>
      </div>
    </b-field>

    <b-loading v-model="loading" :is-full-page="false"></b-loading>

  </form>

</template>

<script>
import Profile from "@/models/Profile";
import Lookup from "@/models/Lookup";
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";

import {format} from 'date-fns'

extend("autocomplete", {
  params: ["target"],
  validate(value, {target}) {
    return target !== null;
  },
  message: "Please select an item from the list",
});
extend("id", (idVal) => {
  const checkDigit = parseInt(idVal.charAt(idVal.length - 1));
  let odd = 0;
  let even = "";
  let evenResult = 0;
  let result;
  for (let c = 1; c <= idVal.length; c++) {
    // noinspection EqualityComparisonWithCoercionJS
    if (c % 2 == 0) {
      even += idVal.charAt(c - 1);
    } else {
      // noinspection EqualityComparisonWithCoercionJS
      if (c == idVal.length) {
        // noinspection UnnecessaryContinueJS
        continue;
      } else {
        odd = parseInt(odd) + parseInt(idVal.charAt(c - 1));
      }
    }
  }
  even = Number(even) * 2;
  even = even.toString();
  for (let r = 1; r <= even.length; r++) {
    evenResult = parseInt(evenResult) + parseInt(even.charAt(r - 1));
  }
  result = parseInt(odd) + parseInt(evenResult);
  result = result.toString();
  result = 10 - parseInt(result.charAt(result.length - 1));
  result = result.toString();
  if (result.length > 1) {
    result = result.charAt(result.length - 1);
  }
  // noinspection EqualityComparisonWithCoercionJS
  if (parseInt(result) != checkDigit) {
    return "Please enter a valid SA ID number";
  } else {
    return true;
  }
});
export default {
  name: "ProfilePanel",
  components: {ValidationObserver, ValidationProvider},
  data() {
    return {

      create: false,
      loading: false,
      loaded: false,
      language: '',
      country: '',
      profile: {
        date_of_birth: null,
        identification_type_id: null,
        id_number: null,
        gender_id: null,
        language_id: null,
        country_of_issue_id: null,
        ethnicity_id: null,
        dominant_hand: null,
        school_attended: {name: null},
        cemis_number: null,
        lunch_option: null,
        number_of_dependants: 0,
        day_length: null,
        tax_number: null,
        religion_id: null,
        consent: [{type: 'picture', is_given: false}, {type: 'terms', is_given: false}, {
          type: 'apply',
          is_given: false
        }, {type: 'consent', is_given: false}],
        siblings: [{
          age: null, gender: null, current_school: null, name: null
        }],
        employment_information: {
          type_of_work: null,
          contact_number: null,
          employer: null
        }, medical_information: {
          doctor_name: null,
          doctor_contact: null,
          medical_aid_number: null,
          medical_aid_provider: null,
          additional_information: null,
          allergies: null,
          has_medical_aid: true
        },
        emergency_contact: {
          first_name: null, last_name: null, contact_number: null, relationship: null
        },
        physical_address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
          suburb: null
        }, alternative_address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
          suburb: null
        },
      }
    }
  },
  props: {
    required: {
      type: Boolean, default() {
        return false
      }
    },
    canEdit: {
      type: Boolean, default() {
        return false
      }
    }, inModal: {
      type: Boolean, default() {
        return false
      }
    },

    fields: {
      type: Array,
      default() {
        return ['date_of_birth', 'id', 'gender', 'language', 'cemis_number', 'grade', 'dominant_hand', 'ethnicity', 'home_address', 'religion', 'school', 'medical_information', 'emergency_contact', 'siblings', 'consent', 'lunch', 'day_length']
      }
    },
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    consentText(type) {
      let text = ''
      switch (type) {
        case 'picture':
          text = "Consent to Generation Schools using visual images of my child for the purposes of the school"
          break;
        case 'terms':
          text = "Accept and agree to Generation Schools Terms & Conditions"
          break;
        case 'apply':
          text = "Provide consent for a reference check to be carried out on parent(s), guardian(s) and/or payment responsibility nominee(s) in my schools’ application form."
          break;
        case 'consent':
          text = "Declare that both Parents/Guardians whose details appear on the associated application form consent to the completion and submission of said application to Generation Schools."
      }
      return text
    }, consentTitle(type) {
      let text = ''
      switch (type) {
        case 'picture':
          text = "Image Use"
          break;
        case 'terms':
          text = "Terms & Conditions"
          break;
        case 'apply':
          text = "Reference Check"
          break;
        case 'consent':
          text = "Application Consent"
      }
      return text
    },
    remove_sibling(index) {
      this.$buefy.dialog.confirm({
        title: "Deleting sibling",
        message:
            "Are you sure you want to <b>delete</b> this? This action cannot be undone.",
        confirmText: "Delete Sibling",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
            (this.profile.siblings = this.profile.siblings.filter(
                (item, place) => place !== index
            )),
      });
    },
    add_sibling() {
      this.profile.siblings.push({
        name: null,
        age: null,
        gender: null,
        current_school: null,
      });
    },
    useProfileField(field_name) {
      return this.fields.some(field =>
          field === field_name
      )
    },
    selectLanguage(language) {
      this.language = language.name
      this.profile.language_id = language.id
    }, selectCountry(country) {
      this.country = country.name
      this.profile.country_of_issue_id = country.id
    },
    submit() {
      if (this.canEdit) {
        this.$refs.observer.validate().then((valid) => {
          if (!valid) {
            return;
          }
          this.loading = true

          if (this.create) {
            let copy = JSON.parse(JSON.stringify(this.profile))
            if (copy.tax_number===null){
              delete copy.tax_number
            }

            Profile.Store(copy, this.user.id).then(() => {
              this.$buefy.snackbar.open('Profile Created!')
              this.loading = false
            }).catch(err => {
              this.handleError(err)
              this.loading = false
            })
          } else {
            let copy = JSON.parse(JSON.stringify(this.profile))
            if (copy.tax_number===null){
              delete copy.tax_number
            }
            Profile.Update(copy).then(() => {
              this.$buefy.snackbar.open('Profile Updated!')
              this.loading = false
            }).catch(err => {
              this.handleError(err)
              this.loading = false
            })
          }
        })
      } else {
        this.$store.dispatch('toast/createToast')
      }
    },
  },
  computed: {
    can_edit_consent() {
      return this.$store.getters['entities/user-permissions/find']('edit indemnities')
    },
    consents() {
      if (this.useProfileField('consent')) {
        return this.profile.consent
      }
      return []
    },
    date_of_birth: {
      get() {
        if (this.profile.date_of_birth===null){
          return null
        }
        return new Date(this.profile.date_of_birth)
      }, set(newValue) {
        this.profile.date_of_birth = format(newValue, 'yyyy-MM-dd')
      },
    },
    grades() {
      return Lookup.query().where('lookup_type_id', 13).orderBy('order').get()
    }
    , religions() {
      return Lookup.query().where('lookup_type_id', 8).orderBy('name').get()
    },
    genders() {
      return Lookup.query().where('lookup_type_id', 1).orderBy('name').get()
    }, ethnicities() {
      return Lookup.query().where('lookup_type_id', 2).orderBy('name').get()
    }, id_types() {
      return Lookup.query().where('lookup_type_id', 3).get()
    },
    languages() {
      return Lookup.query().where('lookup_type_id', 5).where(language => {
        return this.language ? language.name.toLowerCase().includes(this.language.toLowerCase()) : true
      }).orderBy('name').get()
    }, countries() {
      return Lookup.query().where('lookup_type_id', 4).where(country => {
        return this.country ? country.name.toLowerCase().includes(this.country.toLowerCase()) : true
      }).orderBy('name').get()
    },
  },
  mounted() {
    this.loading = true
    Promise.all([
      Profile.FetchById(this.user.id), Lookup.FetchAll({page: 1, limit: 9999})]).then(() => {
      this.profile = Profile.query().whereId(this.user.id).first()
      if (this.profile.language_id) {
        this.language = Lookup.query().where('lookup_type_id', 5).where('id', this.profile.language_id).first().name
      }
      if (this.profile.country_of_issue_id) {
        this.country = Lookup.query().where('lookup_type_id', 4).where('id', this.profile.country_of_issue_id).first().name
      }
      if (typeof this.profile.consent !== 'object') {
        this.profile.consent = []
      }
      if (this.profile.consent === null) {
        this.profile.consent = []
      }
      if (this.profile.consent.length < 4 && this.useProfileField('consent')) {
        if (!this.profile.consent.some(item => item.type === 'picture')) {
          this.profile.consent.push({type: 'picture', is_given: false})
        }
        if (!this.profile.consent.some(item => item.type === 'terms')) {
          this.profile.consent.push({type: 'terms', is_given: false})
        }
        if (!this.profile.consent.some(item => item.type === 'apply')) {
          this.profile.consent.push({type: 'apply', is_given: false})
        }
        if (!this.profile.consent.some(item => item.type === 'consent')) {
          this.profile.consent.push({type: 'consent', is_given: false})
        }
      }

      this.loading = false
    }).catch(err => {
          if (err.response.status === 404) {
            this.create = true
          } else {
            this.handleError(err)
          }
          this.loading = false
        }
    )
  }
}
</script>
