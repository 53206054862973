<template>
  <b-table :default-sort-direction="'desc'" @page-change="$emit('page-change')" @ :default-sort="'is_selected'" :loading="loading" :paginated="true" :data="subjects" :striped="true" :hoverable="true" :bordered="false">
    <template #empty>
      <div class="has-text-centered">No Subjects</div>
    </template>
    <b-table-column v-slot="props" label="ID" field="id" sortable width="40" numeric>{{
        props.row.id
      }}
    </b-table-column>

    <b-table-column v-slot="props" sortable field="long_name" label="Name">{{
        props.row.long_name
      }}
    </b-table-column>
    <b-table-column v-if="showClassGroupSelect" v-slot="props" label="Class">
      <b-field>
        <b-select :value="props.row.class_group_id" @input="selectClass($event,props.row.id)" placeholder="Select Class"
                  expanded>
          <option :key="classGroup.id" v-for="classGroup in props.row.class_groups" :value="classGroup.id">
            {{ classGroup.name }}
          </option>
        </b-select>
      </b-field>
    </b-table-column>
    <b-table-column v-if="showTeacherSelect" v-slot="props" :label="`Primary ${$tc('Teacher',1)}`">
      <b-field>
        <b-select :disabled="props.row.class_group_id===null"
                  :loading="loadingTeachersClass.includes(props.row.class_group_id)" :value="props.row.teacher_id"
                  @input="selectTeacher($event,props.row.id)"
                  :placeholder="`Select educator to be displayed on ${$tc('learner',1)}\'s report`" expanded>
          <option :value="null">None</option>
          <option :key="teacher.id" v-for="teacher in subjectTeachers(props.row.class_group_id)" :value="teacher.id">
            {{ teacher.first_name }} {{ teacher.last_name }}
          </option>
        </b-select>
      </b-field>
    </b-table-column>
    <b-table-column v-if="showAttendances" v-slot="props" sortable field="has_attendances" label="Has Attendances">
      {{ props.row.has_attendances === 1 ? 'Yes' : 'No' }}
    </b-table-column>
    <b-table-column field="is_selected" sortable v-slot="props" cell-class="is-flex is-align-items-center is-justify-content-center" label="Actions">
      <b-checkbox v-if="canSelectSubjects"

                  :disabled="!canClickSubjects || props.row.class_group_id ===null"
                  v-model="selectedSubjectIds"
                  @click.native.prevent="selectSubject(props.row.id)"
                  :native-value="props.row.id"
      ></b-checkbox>
      <b-tooltip v-if="canEditSubject"
                 label="Edit"
                 size="is-small"
                 type="is-warning"
      >
        <a

            class="card-header-icon has-text-black  px-0 py-0"
            @click.prevent.stop="startEdit(props.row)"
        >
          <b-icon :icon="$tc('icons.edit')"
          ></b-icon>
        </a>
      </b-tooltip>
      <b-tooltip
          v-if="canDeleteSubject"
          label="Delete"
          size="is-small"
          type="is-danger"
      >
        <a
            class="card-header-icon has-text-danger px-0 py-0"
            @click.prevent.stop="startDelete(props.row)"
        >
          <b-icon :icon="$tc('icons.delete')"/>
        </a>

      </b-tooltip>

    </b-table-column>

  </b-table>
</template>

<script>
import Subject from "@/models/Subject";
import ClassGroup from "@/models/ClassGroup";

export default {
  name: "SubjectsTable",
  data() {
    return {
      loadingTeachersClass: []
    }
  },
  methods: {
    subjectTeachers(class_group_id) {
      if (class_group_id) {
        return ClassGroup.query().where('id', class_group_id).with('teachers').first().teachers
      }
      return []
    },
    selectSubject(id) {
      let ids = JSON.parse(JSON.stringify(this.selectedSubjectIds))
      if (ids.includes(id)) {
        ids = ids.filter(item => item !== id)
      } else {
        ids.push(id)
      }
      this.$emit('selected-subject-ids', ids)
    },
    selectClass(class_group_id, subject_id) {
      console.log('sdasd')
      this.loadingTeachersClass.push(subject_id)
      ClassGroup.FetchById(class_group_id, 'teachers').catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loadingTeachersClass = this.loadingTeachersClass.filter(item => item !== class_group_id)
        Subject.update({where: subject_id, data: {class_group_id: class_group_id}})

      })
    },
    selectTeacher(teacher_id, subject_id) {
      Subject.update({where: subject_id, data: {teacher_id: teacher_id}})

    },
    startEdit(subject) {
      this.$emit('start-edit-subject', subject)
    }, startDelete(subject) {
      this.$emit('start-delete-subject', subject.id)
    }
  },
  computed: {
    selectedSubjectIds() {
      return this.selectedSubjects

    }
  },
  props: {
    selectedSubjects: {
      type: Array, default() {
        return []
      }
    },
    showAttendances: {
      type: Boolean, default() {
        return false
      }
    }, showClassGroupSelect: {
      type: Boolean, default() {
        return false
      }
    }, showTeacherSelect: {
      type: Boolean, default() {
        return false
      }
    }, canEditSubject: {
      type: Boolean, default() {
        return false
      }
    },
    canSelectSubjects: {
      type: Boolean, default() {
        return false
      }
    }, canClickSubjects: {
      type: Boolean, default() {
        return false
      }
    },
    canDeleteSubject: {
      type: Boolean, default() {
        return false
      }
    },
    loading: {
      type: Boolean,
      default() {
        return false
      }
    },
    subjects: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
