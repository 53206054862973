<template>
  <form @submit.prevent="submit">

    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Log a new' : 'Edit' }} Covid Assessment</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="User">
          <UsersFilter
              :disabled="default_user!==null || edit ||timeLocked"
              :selected_user_id="covidAssessmentObject.user_id"
              :type="'autocomplete'"
              @selected="option=>covidAssessmentObject.user_id = option.id"
          ></UsersFilter>
        </b-field>
        <b-field v-if="loaded" expanded label="Taken On">
          <b-datepicker
              append-to-body
              disabled
              :years-range="[-2,2]" placeholder="Covid assessment date" :required="is_required" expanded
              v-model="taken_on"
          ></b-datepicker>
        </b-field>
        <b-field expanded label="Temperature">
          <b-numberinput
              step="0.01"

              max="42"
              :disabled="timeLocked"

              placeholder="Temperature" :required="is_required" expanded
              v-model="covidAssessmentObject.temperature"

          ></b-numberinput>
        </b-field>
        <b-field label="Has Symptoms">
          <b-switch :disabled="timeLocked"
                    v-model="hasSymptoms">
            {{ hasSymptoms ? 'Yes' : 'No' }}

          </b-switch>
        </b-field>
        <transition name="fade">
          <b-field v-if="hasSymptoms" group-multiline grouped label="Symptoms">
            <b-field class="is-fullwidth" v-for="symptom of $store.state.covid.symptoms" :key="symptom.value">
              <b-checkbox :disabled="timeLocked"
                          v-model="covidAssessmentObject.symptoms[symptom.value]" :true-value="true"
                          :false-value="false">
                {{ symptom.name }}
              </b-checkbox>
            </b-field>
          </b-field>
        </transition>
      </section>

      <footer v-if="showButtons" :class="{'modal-card-foot':inModal}">
        <b-field class="mt-3 has-text-centered" grouped expanded position="is-centered">
          <b-field position="is-centered">
            <b-button native-type="submit"
                      :disabled="(edit?(!canEdit||timeLocked):!canCreate)"
                      type="is-primary"
                      :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">
              {{ (edit || created) ? 'Save' : 'Submit' }}
            </b-button>
          </b-field>
          <b-field v-if="edit&&canDelete" position="is-centered">
            <b-button
                @click.prevent="startDelete()"
                type="is-danger"
                :icon-right="$tc(`icons.delete`)">Delete
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import UsersFilter from "@/components/panelled-dash/UsersFilter";
import Dates from "@/mixins/Dates";
import CovidAssessment from "@/models/CovidAssessment";
import {differenceInDays, isValid, parseISO} from "date-fns";

export default {
  name: "CovidAssessmentForm",
  components: {UsersFilter},
  mixins: [Dates],
  data() {
    return {
      loaded: false,
      loading: false,
      created: false,
      hasSymptoms: false,
      covidAssessmentObject: {
        user_id: this.default_user,
        taken_on: this.defaultTakenOn,
        symptoms: {},
        temperature: null
      }
    }
  },

  computed: {
    taken_on: {
      get() {
        if (isValid(parseISO(this.covidAssessmentObject.taken_on)) && this.covidAssessmentObject.taken_on !== null) {
          return new Date(this.covidAssessmentObject.taken_on)
        }
        return null
      }, set(newValue) {
        this.covidAssessmentObject.taken_on = this.dateFormat(newValue)
      },
    },

    timeLocked() {
      if (this.edit) {
        return differenceInDays(new Date(), new Date(this.covidAssessmentObject.created_at)) > 0
      }
      return false
    }
  },
  watch: {

    submit_flip(val) {
      if (val === true) {
        this.submit()
      }
    }
  },
  methods: {
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting Covid Assessment`,
        confirmText: `Delete Covid Assessment`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this Covid assessment?`,
        onConfirm: () =>
            CovidAssessment.Delete(this.covidAssessmentObject.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Covid Assessment deleted!`);
                  this.removeQuery(['covid_assessment_id'])
                  this.$emit('close')
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },


    submit() {
      if (this.edit || this.created) {
        if (this.canEdit) {
          this.loading = true
          CovidAssessment.Update({
                user_id: this.covidAssessmentObject.user_id,
                taken_on: this.covidAssessmentObject.taken_on,
                symptoms: this.hasSymptoms?this.covidAssessmentObject.symptoms:JSON.parse(JSON.stringify(this.$store.state.covid.empty_object)),
                temperature: this.covidAssessmentObject.temperature,
                id: this.covidAssessmentObject.id,
              }, true
          ).then(() => {
            this.loading = false
            this.$buefy.snackbar.open(`Covid Assessment updated!`)
            if (this.inModal) {
              this.$emit('close')
            }
            this.$emit('submitSuccess')
          }).catch(err => {
            this.loading = false
            this.$emit('submitFail')
            this.handleError(err)
          })
        } else {
          this.$store.dispatch('toast/createToast')
        }
      } else {
        if (this.canCreate) {
          console.log(this.covidAssessmentObject.symptoms)

          this.loading = true
          CovidAssessment.Store({
            user_id: this.covidAssessmentObject.user_id,
            taken_on: this.covidAssessmentObject.taken_on,
            symptoms: this.covidAssessmentObject.symptoms,
            temperature: this.covidAssessmentObject.temperature,
            id: this.covidAssessmentObject.id,

          }).then(({entities: {covidAssessments}}) => {
            this.loading = false
            this.$buefy.snackbar.open(`Covid Assessment created!`)
            this.covidAssessmentObject.id = covidAssessments[0].id
            this.created = true
            if (this.inModal) {
              this.$emit('close')
            }
            this.$emit('submitSuccess')

          }).catch(err => {
            this.loading = false
            this.$emit('submitFail')
            this.handleError(err)
          })
        } else {
          this.$store.dispatch('toast/createToast')
        }
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {
      this.covidAssessmentObject = JSON.parse(JSON.stringify(this.covidAssessment))

      this.$store.state.covid.symptoms.map(symptom => {
        this.covidAssessmentObject.symptoms[symptom.value] = this.covidAssessmentObject.symptoms[symptom.value] ?? false
      })
      this.hasSymptoms = Object.values(this.covidAssessmentObject.symptoms).some(symptom => symptom === true)

    } else {
      this.covidAssessmentObject.symptoms=JSON.parse(JSON.stringify(this.$store.state.covid.empty_object))
      this.taken_on = this.default_taken_on ===null ? new Date():new Date(this.default_taken_on)
      this.$store.state.covid.symptoms.map(symptom => {
        this.covidAssessmentObject.symptoms[symptom.value] = false
      })
    }
    this.loaded = true

  }
  ,
  props: {
    is_required:{
      type:Boolean,
      default() {
        return true
      }
    },
    submit_flip: {
      type: Boolean,
      default() {
        return false
      }
    }, showButtons: {
      type: Boolean,
      default() {
        return true
      }
    },
    canCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default() {
        return false
      }
    }, canDelete: {
      type: Boolean,
      default() {
        return false
      }
    },
    default_user: {
      type: Number,
      default() {
        return null
      }
    },    default_taken_on: {
      type: String,
      default() {
        return null
      }
    },
    covidAssessment:
        {
          type: Object,
          default
              () {
            return {}
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

