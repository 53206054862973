<template>
  <div>
    <div class="section" ref="tabs">
        <activityForm
            :permissions="{
                            can_create: !!$store.getters['entities/user-permissions/find'](`create tasks`),
                            can_edit: !!$store.getters['entities/user-permissions/find'](`edit tasks`),
                          }"
            @cancelActivity="editingActivity = false;"
            :action="editingActivity ? 'edit' : 'create'"
            :activity="editingActivity ? activityToEdit : null"
            :model_type="'user'"
            :model_id="parseInt(this.user.id)"
            :team="activities_team"
        ></activityForm>
    </div>
    <div class="section">
        <div
            :key="activity.id"
            v-for="activity in activities"
        >
          <activityListItem
              @editActivity="editActivity($event)"
              :activity="activity"
              :permissions="{
                        can_delete: !!$store.getters['entities/user-permissions/find'](`delete tasks`),
                        can_edit: !!$store.getters['entities/user-permissions/find'](`edit tasks`),
                        can_complete: !!$store.getters['entities/user-permissions/find'](`edit tasks`) || parseInt(activity.assigned_to_user_id) === $store.state.user.id
                      }"
              :model_type="'user'"
              :model_id="parseInt(user.id)"
          ></activityListItem>
        </div>
        <b-pagination
            class="margin-top"
            :total="activities_count"
            :current.sync="page"
            :range-before="2"
            :range-after="2"
            :per-page="limit"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
        ></b-pagination>

    </div>
  </div>

</template>

<script>
import ActivityForm from "@/components/activities/ActivityForm";
import ActivityListItem from "@/components/activities/ActivityListItem";
import User from "@/models/User";
import Task from "@/models/Task";


export default {
  name: "ActivitiesPanel",
  components: {
    ActivityForm,
    ActivityListItem,
  },
  data() {
    return {
      page: 1,
      limit: 20,
      create: false,
      loading: false,
      loaded: false,
      activityToEdit: null,
      editingActivity: false,
      activities_team: [],
      profile: {
        observation: ''
      }
    }
  },
  props: {
    canEdit: {
      type: Boolean, default() {
        return false
      }
    },
    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    editActivity(event) {
      this.editingActivity = true;
      this.activityToEdit = Task.query()
          .with("creator")
          .whereId(event)
          .first();
      this.$refs["tabs"].scrollIntoView({
        behavior: "smooth",
      });
    }
  },
  computed: {
    activities_count() {
      return Task.query().count()
    },
    activities() {
      return Task.query()
          .with("creator")
          .with("assigned_to_user")
          .with("completed_user")
          .orderBy("created_at", "desc")
          .limit(this.limit)
          .offset((this.page - 1) * this.limit)
          .get();
    },
  },
  mounted() {
    this.loading = true
    this.$store.state.documentation.tab_name = 'activities'
    this.$store.state.documentation.active_doc_document_path = './Operations/Activities.md'
    Promise.all([User.FetchAll({page: 1, limit: 999}, {
      campus_id: this.$store.state.campus.selected_campus_id,
      without_roles: ['learner', 'guardian']
    }), Task.FetchAllByModel({
      page: 1,
      limit: 999
    }, {}, ['creator', 'assignedToUser', 'completedUser'], {type: 'user', id: this.user.id})]).then(result => {
      console.log(result)
      this.activities_team = result[0].entities.users;
      this.loading = false
    }).catch(err => {
      console.log(err)
          this.handleError(err)

          this.loading = false
        }
    )
  }
}
</script>
