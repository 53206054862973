<template>
  <form @submit.prevent="submit" class="guardian-service-form">
    <section class="section">
      <div class="columns">
        <div class="column">
          <b-field grouped>
            <b-field v-if="campuses.length>1" expanded horizontal label="Filter By Campus">
              <b-select v-model="campus_id_filter" expanded placeholder="Phase">
                <option v-for="campus in campuses" :key="campus.id" :value="campus.id">{{ campus.name }}</option>
              </b-select>
            </b-field>
            <b-field expanded horizontal label="Filter By Phase">
              <b-select v-model="phase_id" expanded placeholder="Phase">
                <option value="All">All</option>
                <option v-for="phase in phases" :key="phase.id" :value="phase.id">{{ phase.name }}</option>
              </b-select>
            </b-field>
          </b-field>
        </div>
      </div>

      <div class="columns is-multiline">
        <div v-for="servicePackage in packages" class="column is-12 mt-3"

             :class="{'selected':package_id===servicePackage.id,'new-package':new_package_id===servicePackage.id}"
             :key="servicePackage.id">
          <div v-if="expanded_services.includes(servicePackage.id)" class="pt-3 pb-3 ck-content"
               v-html="servicePackage.description"></div>


          <div @click.prevent.stop="toggleService(servicePackage.id)"
               class="level is-clickable">
            <div class="level-left">
              <div class="level-item">
                <h2 class="is-size-3">{{ servicePackage.name }}
                  {{
                    servicePackage.id === package_id ? '- Current Package' : ''
                  }}{{ servicePackage.id === new_package_id ? '- Pending Package' : '' }}</h2>

              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <a
                    class="card-header-icon has-text-link px-0 py-0"
                    @click.prevent.stop="toggleService(servicePackage.id)"
                >
                  <b-icon :icon="expanded_services.includes(servicePackage.id)?'menu-up':'menu-down'" size="is-medium"/>
                </a>
              </div>
            </div>
          </div>
          <b-field v-if="expanded_services.includes(servicePackage.id)" label="Services">
            <b-table
                scrollable
                class="margin-top"
                :data="servicePackage.services"
                :striped="true"
                :hoverable="true"
                :bordered="false"
            >
              <b-table-column
                  v-slot="props"
                  label="ID"
                  field="id"
                  sortable
                  width="40"
                  numeric
              >{{ props.row.id }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Name"
                  field="name"
                  sortable

              >{{ props.row.name }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Cost"
                  field="cost"
                  sortable
                  numeric
              >{{ props.row.cost }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Billing Period"
                  field="billing_period"
                  sortable
                  numeric
              >{{ props.row.billing_period }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Notice Period Type"
                  field="notice_period_type"
                  sortable
                  numeric
              >{{ getTypeName(props.row.notice_period_type) }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Notice Period (days)"
                  field="notice_period"
                  sortable
                  numeric
              >{{ props.row.notice_period }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Cutoff Day"
                  field="notice_cut_day"
                  sortable
                  numeric
              >{{ props.row.notice_cut_day }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Status"
                  field="status_id"
                  sortable>
                {{ displayStatus(props.row.status_id, servicePackage.id) }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Subscribed At"
                  field="subscribed_at"
                  sortable>
                {{ props.row.subscribed_at }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Will Unsubscribe At"
                  field="unsubscribe_at"
                  sortable>
                {{ props.row.unsubscribe_at }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Preferred Unsubscribe At"
                  field="preferred_unsubscribe_at"
                  sortable>
                {{ props.row.preferred_unsubscribe_at ? props.row.preferred_unsubscribe_at : 'None' }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Unsubscribed At"
                  field="unsubscribed_at"
                  sortable>
                {{ props.row.unsubscribed_at }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  label="Downloads"
              >
                <b-field v-if="props.row.media.length>0">
                  <b-taglist><a v-for="file in props.row.media" download :href="file.temporary_url" target="_blank"
                                :key="file.id" class="tag">{{ file.caption }}</a></b-taglist>

                </b-field>
              </b-table-column>
            </b-table>

          </b-field>
          <b-field position="is-centered" v-if="expanded_services.includes(servicePackage.id)" grouped>
            <b-field>
              <b-button v-if="canEdit" type="is-primary" :disabled="package_id === servicePackage.id || new_package_id"
                        @click.prevent="startChangePackage(servicePackage)">
                Select Package
              </b-button>
            </b-field>
            <b-field
                v-if="canForce && (new_package_id === servicePackage.id) && subscribedServicePackage">
              <b-button type="is-primary"
                        @click.prevent="startChangePackageUnsubscribe(subscribedServicePackage)">
                Change Subscription Date
              </b-button>
            </b-field>
            <b-field v-if="canForce">
              <b-button type="is-danger"
                        @click.prevent="startChangePackage(servicePackage,true)">
                Force Select Package
              </b-button>
            </b-field>

          </b-field>

          <hr class="mb-0">
        </div>
      </div>
      <div class="columns">

        <div class="column is-12">
          <h2 class="is-size-3">Optional Services</h2>

          <b-table
              :scrollable="true"
              :striped="true"
              :hoverable="true"
              :bordered="false"
              :data="availableServices">
            <template #empty>
              <div class="has-text-centered">No Services</div>
            </template>
            <b-table-column
                v-slot="props"
                label="ID"
                field="id"
                sortable
                width="40"
                numeric
            >{{ props.row.id }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Name"
                field="name"
                sortable

            >{{ props.row.name }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Phases"

            >{{ props.row.phases.length > 0 ? displayPhases(props.row.phases) : 'All' }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Cost"
                field="cost"
                sortable
                numeric
            >{{ props.row.cost }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                label="Billing Period"
                field="billing_period"
                sortable
                numeric
            >{{ props.row.billing_period }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Notice Period Type"
                field="notice_period_type"
                sortable
                numeric
            >{{ getTypeName(props.row.notice_period_type) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Notice Period (days)"
                field="notice_period"
                sortable
                numeric
            >{{ props.row.notice_period }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Cutoff Day"
                field="notice_cut_day"
                sortable
                numeric
            >{{ props.row.notice_cut_day }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Is Open"
                field="is_open"
                sortable
                numeric
            >{{ props.row.is_open ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Status"
                field="status_id"
                sortable>
              {{ displayStatus(props.row.status_id) }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Subscribed At"
                field="subscribed_at"
                sortable>
              {{ props.row.subscribed_at }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Will Unsubscribe At"
                field="unsubscribe_at"
                sortable>
              {{ props.row.unsubscribe_at }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Unsubscribed At"
                field="unsubscribed_at"
                sortable>
              {{ props.row.unsubscribed_at }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Downloads"
            >
              <b-field v-if="props.row.media.length>0">
                <b-taglist><a v-for="file in props.row.media" download :href="file.temporary_url" target="_blank"
                              :key="file.id" class="tag">{{ file.caption }}</a></b-taglist>

              </b-field>
            </b-table-column>
            <b-table-column
                v-slot="props"
                label="Actions"
                custom-key="actions"
                centered

            >
              <b-dropdown position="is-bottom-left"
                          append-to-body aria-role="list">
                <template #trigger="{ active }">
                  <b-button
                      label="Actions"
                      type="is-primary"
                      :icon-right="active ? 'menu-up' : 'menu-down'"/>
                </template>

                <b-dropdown-item v-if="props.row.status_id ===1" @click="startUnsubscribe(props.row)"
                                 aria-role="listitem">
                  Unsubscribe
                </b-dropdown-item>
                <b-dropdown-item v-if="(props.row.status_id ===1 || props.row.status_id ===2) && canForce"
                                 @click="startUnsubscribe(props.row,true)"
                                 aria-role="listitem">
                  Force Unsubscribe
                </b-dropdown-item>
                <b-dropdown-item v-if="props.row.status_id ===3||props.row.status_id===null" :disabled="!canEdit"
                                 @click="startSubscribe(props.row)" aria-role="listitem">
                  Subscribe
                </b-dropdown-item>

                <!--                <b-field v-if="props.row.status_id ===2">-->
                <!--                  <b-button :disabled="!canEdit" @click="cancelUnsubscribe(props.row)" type="is-link">-->
                <!--                    Cancel Unsubscribe-->
                <!--                  </b-button>-->
                <!--                </b-field>-->
              </b-dropdown>
            </b-table-column>

          </b-table>

        </div>
      </div>
    </section>


    <b-loading :active.sync="loading" :is-full-page="false"></b-loading>

  </form>

</template>

<script>


import Service from "@/models/Service";
import User from "@/models/User";
import Package from "@/models/Package";
import {add, format, getDate} from 'date-fns'
import {mapState} from "vuex";
import PackageChangeForm from "@/components/users/PackageChangeForm";
import Phase from "@/models/Phase";
import Campus from "@/models/Campus";

export default {
  name: "ServicesPanel",
  data() {
    return {
      loading: false,
      loaded: false,
      services: [],
      service_ids: [],
      package_id: null,
      new_package_id: null,
      phase_id:'All',
      expanded_services: [],
      campus_id_filter:this.user.campus_ids[0]
    }
  },
  props: {
    campus_id: {
      type: Number,
      required: true
    },
    canForce: {
      type: Boolean, default() {
        return false
      }
    },
    canEdit: {
      type: Boolean, default() {
        return false
      }
    },
    user: {
      type: Object,
      required: true
    }
  },
  watch: {
    campus_id_filter() {
      this.mountActions()
    },  phase_id() {
      this.mountActions()
    }, year() {
      this.mountActions()
    }
  },
  computed: {
    campuses(){
      return Campus.query().whereIdIn(this.user.campus_ids).get()
    },
    phases() {
      return Phase.query().where('campus_id', this.campus_id_filter).where('year', this.year).get()
    },
    ...mapState("dates", ['year']),
    packages() {
      return Package.query().with('services').with('services.media').get()
    },
    subscribedServicePackage() {
      return Package.query().whereId(this.package_id).with('services').with('services.media').first()
    },
    availableServices() {
      return Service.query().with('media').where(service => {
        return (service.is_open === 1 || service.status_id !== null) && service.package_id === null
      }).with('phases').get()
    },
  },
  methods: {
    displayPhases(phases) {

      let names = ''
      phases.map((phase, index) => {
        if (index === 0) {
          names = names + phase.name
        } else {
          names = names + ', ' + phase.name
        }
      })
      return names
    },
    startChangePackageUnsubscribe(servicePackage) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          title: "Changing Package Unsubscribe At",
          message: `Are you sure you want to change packages?`,
          notice_period: 0,
          showDates: true,
          requireDates: true
        },
        component: PackageChangeForm,
        hasModalCard: true,
        trapFocus: true,
        events: {
          "change": (date) => {
            this.loading = true
            Service.ChangePackageUnsubscribe(this.user.id, servicePackage.id, date).then(() => {
              this.$buefy.snackbar.open('Package Updated!')

            }).catch(err => {
              this.handleError(err)
            }).finally(() => {
              this.loading = false
            })

          },
        },
      });
    },
    expandService(id) {
      this.expanded_services.push(id)
    },
    collapseService(id) {
      this.expanded_services = this.expanded_services.filter(service_id => service_id !== id)
    },
    toggleService(id) {
      this.expanded_services.includes(id) ? this.collapseService(id) : this.expandService(id)
    },

    getTypeName(id) {
      return this.$store.state.services.notice_period_types.find(type => type.id === id).name
    },
    mountActions() {
      Package.deleteAll()
      Service.deleteAll()
      this.new_package_id = null
      this.loaded = false
      this.loading = true
      Promise.all([Package.FetchAll({
        page: 1,
        limit: 999
      }, {
        campus_id: this.campus_id_filter,
        year: this.$store.state.dates.year,
        without_phases: 1,
        ...(this.phase_id !== 'All'
            ? {
              phase_id: this.phase_id,
            }
            : {}),
      }, ['services', 'services.media', 'phases']), Service.FetchAll({
        page: 1,
        limit: 999
      }, {
        campus_id: this.campus_id_filter, without_phases: 1,
        ...(this.phase_id !== 'All'
            ? {
              phase_id: this.phase_id,
            }
            : {}), year: this.$store.state.dates.year
      }, ['media', 'phases']), Service.FetchAllByUser({
        page: 1,
        limit: 999
      }, this.user.id, {latest: true, year: this.$store.state.dates.year}, ['media', 'phases'])])
          .then(() => {
            if (Service.query().where('campus_id', this.campus_id_filter).where('status_id', (value => value === 1 || value === 2)).where('package_id', (value => value !== null)).exists()) {
              this.package_id = Service.query().where('status_id', (value => value === 1 || value === 2)).where(service => service.package_id !== null).first().package_id
              this.loaded = true
              this.toggleService(this.package_id)

              this.loading = false
            } else {
              this.package_id = null
              this.loading = false
            }

            if (Service.query().where(service => service.new_package_id !== null).exists()) {
              this.new_package_id = Service.query().where(service => service.new_package_id !== null).first().new_package_id

            }

          }).catch(err => {
            this.handleError(err)
            this.loading = false
          }
      )
    },
    startChangePackage(servicePackage, force = false) {
      if (servicePackage.id === this.package_id) {
        return
      }
      if (this.package_id === null || this.subscribedServicePackage === null) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            title: "Changing Package (taking place immediately)",
            message: `Are you sure you want to change packages?`,
            notice_period: 0,
            showDates: !force,

          },
          component: PackageChangeForm,
          hasModalCard: true,
          trapFocus: true,
          events: {
            "change": (date) => {
              this.loading = true
              this.changePackageNow(servicePackage, force, date)

            },
          },
        });

      } else if ((!this.subscribedServicePackage.services.some(service => service.notice_period_type === 0) || (this.subscribedServicePackage.services.some(service => service.notice_period_type === 2) && this.subscribedServicePackage.services.some(service => service.notice_cut_day > getDate(new Date())))) || force || !this.subscribedServicePackage.services.some(service => service.package_id !== null)) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            title: "Changing Package",
            message: `Are you sure you want to change packages?`,
            showDates: !force,
            notice_period: this.subscribedServicePackage.services.some(service => service.notice_period !== null) ? 1 : 0
          },
          component: PackageChangeForm,
          hasModalCard: true,
          trapFocus: true,
          events: {
            "change": (date) => {
              this.loading = true
              this.changePackageNow(servicePackage, force, date)

            },
          },
        });
      } else {
        this.$buefy.modal.open({
          parent: this,
          props: {
            title: "Changing Package (After contacting administration)",
            message: `Are you sure you want to change packages?`,
            notice_period: 1,
            showDates: !force,


          },
          component: PackageChangeForm,
          hasModalCard: true,
          trapFocus: true,
          events: {
            "change": (date) => {
              this.loading = true
              this.changePackageLater(servicePackage, force, date)

            },
          },
        });

      }
    },
    changePackageNow(servicePackage, force = false, date = null) {

      Service.ChangePackage(this.user.id, this.package_id, servicePackage.id, force, date).then(() => {
        this.package_id = servicePackage.ids
        this.loading = false
        this.$buefy.dialog.confirm({
          title: force ? "Package Updated" : "Request Sent",
          message:
              force ? `The package has now been updated. Please update the guardian of the change to their billing information.` : `Thank you for submitting your request to change your billing options. One of our team will be in contact with you shortly to confirm your package update has been successful.`,
          confirmText: "Ok",
          type: "is-primary",
          canCancel: false,
          hasIcon: true,
          onConfirm: () => {
            this.loading = true

            this.mountActions()
          },
        });
      }).catch(err => {
        this.handleError(err)
        this.loading = false
      })

    }, changePackageLater(servicePackage, force, date = null) {
      Service.ChangePackage(this.user.id, this.package_id, servicePackage.id, force, date).then(response => {
        if (force) {
          this.package_id = servicePackage.package_id
        } else {
          this.new_package_id = response.entities.services[0].new_package_id

        }

      }).finally(() => {
        this.loading = false
        this.$buefy.dialog.confirm({
          title: force ? "Package Updated" : "Request Sent",
          message:
              force ? `The package has now been updated. Please update the guardian of the change to their billing information.` : `Thank you for submitting your request to change your billing options. One of our team will be in contact with you shortly to confirm your package update has been successful.`,
          confirmText: "Ok",
          type: "is-primary",
          canCancel: false,
          hasIcon: true,
          onConfirm: () => {
            this.loading = true

            this.mountActions()
          },
        });
      }).catch(err => {
        this.handleError(err)
        this.loading = false
      })
    },
    formatDate(notice_period) {
      if (notice_period === 0) {
        return 'immediately.'
      }
      let date = add(new Date(), {days: notice_period})
      let display_date = format(date, "'on' iiii do 'of' MMMM yyyy'.'")
      return `${display_date}`
    },
    startUnsubscribe(service, force = false) {
      let message = ''
      if (service.notice_period_type === 0) {
        message = 'immediately'
      }
      if (service.notice_period_type === 2) {
        if (service.notice_cut_day > getDate(new Date())) {
          message = 'next month'
        } else {
          message = 'immediately'
        }

      }
      if (service.notice_period_type === 1) {
        let date = add(new Date(), {days: service.notice_period})

        message = format(date, "'on' iiii do 'of' MMMM yyyy'.'")
      }
      this.$buefy.dialog.confirm({
        title: "Unsubscribing from service",
        message:
            `Are you sure you want to unsubscribe from this service? Please note, this will take effect ${message}`,
        confirmText: "Unsubscribe",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          this.loading = true
          Service.Unsubscribe(this.user.id, service.id, force).catch(err => {
            this.handleError(err)
          }).finally(() => {
            this.loading = false
          })
        },
      });
    },
    cancelUnsubscribe(service) {
      this.$buefy.dialog.confirm({
        title: "Cancel Service Unsubscription",
        message:
            `Are you sure you want to keep your subscription to this service?`,
        confirmText: "Cancel Unsubscription",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          this.loading = true
          Service.Subscribe(this.user.id, [{service_id: service.id}]).catch(err => {
            this.handleError(err)
          }).finally(() => {
            this.loading = false
          })
        },
      });
    },
    startSubscribe(service) {
      this.$buefy.dialog.confirm({
        title: "Subscribe to service",
        message:
            `Are you sure you want to to this service?`,
        confirmText: "Subscribe",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          this.loading = true
          Service.Subscribe(this.user.id, service.id).then(() => {
            Service.update({where: service.id, data: {status_id: 1}})
          }).catch(err => {
            this.handleError(err)
          }).finally(() => {
            this.loading = false
          })
        },
      });
    },
    displayStatus(status_id, package_id = null) {
      let value = 'None'
      if (status_id === 1) {
        value = "Subscribed"
        if (package_id) {
          if (package_id!==this.package_id) {
            value = 'Subscribed in another package'
          }
        }
      }
      if (status_id === 2) {
        value = "Unsubscribe Pending"
      }
      if (status_id === 3) {
        value = "Unsubscribed"
      }
      return value
    },
    submit() {
      if (this.canEdit) {
        this.loading = true

        User.Update({
          id: this.user.id,
          services: this.service_ids,
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          email: this.user.email
        }, false).then(() => {
          this.$buefy.snackbar.open('Services Updated!')
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        this.$store.dispatch('toast/createToast')
      }
    },
  },

  mounted() {
    this.loading = true

    this.mountActions()
  }
}
</script>
