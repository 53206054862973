
<template>
  <div class="listitem">
    <article class="media">
      <figure class="media-left">
        <p class="has-text-centered">
          <b-tooltip :label="type.name" position="is-top">
            <b-icon class="icon rounded is-large box is-64x64" :icon="$tc(`icons.${type.name}`)"></b-icon>
          </b-tooltip>
        </p>
        <p class="has-text-centered mt-2">
          <b-field>
            <b-tooltip
                :label="activity.is_complete!=1?'Mark as complete':'Mark as incomplete'"
                position="is-top"
            >
              <b-button
                  :loading="loadingActivity"
                  :type="{'is-success':activity.is_complete==1}"
                  class="is-rounded"
                  @click.prevent="completeActivity()"
              >
                <b-icon :icon="$tc('icons.tick')"></b-icon>
              </b-button>
            </b-tooltip>
          </b-field>
        </p>
      </figure>
      <div class="box media-content is-relative">
        <div class="content">
          <h3 class="is-capitalized" v-html="activity.name"></h3>
          <b-dropdown class="listitem-dropdown" :position="'is-top-left'" aria-role="list">
            <button class="button is-primary" slot="trigger">
              <b-icon :icon="$tc('icons.more-vertical')"></b-icon>
            </button>

            <b-dropdown-item aria-role="listitem" @click="editActivity(activity)">Edit</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="startDelete(activity)">Delete</b-dropdown-item>
          </b-dropdown>
          <p class="subtitle is-7" :class="{'mb-0':activity.due_at!=null}">
            Created by {{ activity.creator.first_name }} {{ activity.creator.last_name }} @
            {{ (new Date(activity.created_at).toLocaleString()) }}
            <span
                v-if="activity.assigned_to_user_id"
            >-> To:{{ activity.assigned_to_user.first_name }} {{ activity.assigned_to_user.last_name }}</span>
            <span
                v-if="activity.is_complete ==1"
            >&nbsp;| Completed by {{ activity.completed_user.first_name }} {{
                activity.completed_user.last_name
              }} @ {{ (new Date(activity.completed_at).toLocaleString()) }}</span>
          </p>
          <p
              v-if="activity.due_at"
              class="subtitle is-7"
          >Due @ {{ (new Date(activity.due_at).toLocaleString()) }}</p>

          <div class="ck-content" v-html="activity.note"></div>
        </div>
      </div>
    </article>

    <hr/>
  </div>
</template>

<script>
import Activity from "@/models/Task";

export default {
  name: "ActivityListItem",
  props: {
    permissions: {
      type: Object,
      default: () => ({
        can_delete: false,
        can_edit: false,
        can_complete: false,
      }),
    },
    model_type: {type: String, required: true},
    model_id: {type: Number, required: true},
    activity: {
      type: Object,
      default: () => Object,
    },
  },
  data() {
    return {
      loadingActivity: false,
      activity_types: [
        {id: 1, name: "Call", icon: "phone"},
        {id: 2, name: "Send Email", icon: "email-send"},
        {id: 3, name: "Task", icon: "clock"},
      ],
    };
  },

  computed: {
    type() {
      return this.activity_types.find(
          (type) => type.id == this.activity.type_id
      );
    },
  },
  methods: {
    editActivity(activity) {
      if (this.permissions.can_edit) {
        this.$emit("editActivity", activity.id);
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
    deleteActivity(activity) {
      this.$store.dispatch("loader/show");
      Activity.Delete(activity.id, {type: this.model_type, id: this.model_id,}).then(
          () => {
            this.$buefy.snackbar.open('Activity deleted!')
            this.$store.dispatch("loader/hide");
          }
      ).catch(
          err => {
            if (err.response.status === 422) {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            } else {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err),
              });
            }
          }
      )

    },
    startDelete(activity) {
      if (this.permissions.can_delete) {
        this.$buefy.dialog.confirm({
          title: "Deleting activity",
          message:
              "Are you sure you want to <b>delete</b> this activity? This action cannot be undone.",
          confirmText: "Delete Activity",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteActivity(activity),
        });
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
    completeActivity() {
      if (this.permissions.can_complete) {
        this.loadingActivity = true;
        Activity.Toggle(
            this.activity.id, {
              type: this.model_type, id:
              this.model_id,
            }
        )
            .then(() => {
              this.loadingActivity = false;
            })
            .catch(
                err => {
                  if (err.response.status === 422) {
                    this.$store.dispatch("toast/createToast", {
                      message: JSON.stringify(err.response.data),
                    });
                  } else {
                    this.$store.dispatch("toast/createToast", {
                      message: JSON.stringify(err),
                    });
                  }
                }
            )
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
  },
};
</script>
