<template>
  <div>
    <form @submit.prevent="submit">
      <section>
        <h2 class="is-size-4">Employment Information</h2>
        <div class="columns is-centered is-multiline pt-3">
          <!--        <div v-if="useProfileField('date_of_birth')" class="column is-4">-->

          <!--          <b-field expanded label="Date of birth">-->
          <!--            <b-datepicker-->
          <!--                :required="required"-->
          <!--                :years-range="[-100,100]"-->
          <!--                locale="en-ZA"-->
          <!--                v-model="date_of_birth"-->
          <!--                placeholder="Click to select..."-->
          <!--                :icon="$tc('icons.calendar')"-->
          <!--                trap-focus>-->
          <!--            </b-datepicker>-->
          <!--          </b-field>-->
          <!--        </div>-->
          <!--     -->
          <!--        <div v-if="useProfileField('id')" class="column is-4">-->
          <!--          <b-field expanded label="ID number">-->
          <!--            <ValidationObserver ref="observer">-->
          <!--              <ValidationProvider-->
          <!--                  :rules="`${profile.identification_type_id === 9 ? 'id' : ''}`"-->
          <!--                  v-slot="{ errors }"-->
          <!--              >-->
          <!--                <b-input-->
          <!--                    :required="required"-->

          <!--                    autocomplete="off"-->
          <!--                    -->
          <!--                    v-model="profile.id_number"-->
          <!--                    placeholder="ID number"-->
          <!--                />-->
          <!--                <span class="has-text-danger">{{ errors[0] }}</span>-->
          <!--              </ValidationProvider>-->
          <!--            </ValidationObserver>-->
          <!--          </b-field>-->
          <!--        </div>-->
          <div class="column is-4">
            <b-field expanded label="Employment Type">
              <b-select :disabled="!canEdit"
                        expanded v-model="profile.employment_type" placeholder="Employment type">
                <option v-for="type in $store.state.staffProfile.employment_types" :key="type.id" :value="type.name">
                  {{ type.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Department">
              <b-select :disabled="!canEdit"
                        expanded v-model="profile.department_id" placeholder="Department">
                <option v-for="department in departments" :key="department.id" :value="department.id">{{
                    department.name
                  }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Designation/Role">
              <b-input :disabled="!canEdit" placeholder="Designation/Role" v-model="profile.designation">

              </b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Hours (per week)">
              <b-input :disabled="!canEdit" placeholder="Hours (per week)" v-model="profile.hours_per_week">

              </b-input>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Extension Code">
              <b-input :disabled="!canEdit || !$store.getters['entities/user-permissions/find']('edit extension codes')" placeholder="Extension Code" v-model="profile.extension_code">

              </b-input>
            </b-field>
          </div>


        </div>
        <h2 class="is-size-4">Leave</h2>
        <h2 class="is-size-5">Annual Leave Accrual</h2>
        <div class="columns is-centered is-multiline pt-3">
          <div class="column is-6">
            <b-field expanded label="Accrual Rate (Days Per Month)">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="0.05" :min="0"
                             placeholder="Annual Accrual Rate"
                             v-model="profile.annual_accrual_rate">

              </b-numberinput>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field expanded label="Carry Over (Months)">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0"
                             placeholder="Annual Leave Carry Over"
                             v-model="profile.annual_carryover_months">

              </b-numberinput>
            </b-field>
          </div>
        </div>

        <h2 class="is-size-5">Maximum Leave Amounts</h2>
        <div class="columns is-centered is-multiline pt-3">


          <div class="column is-4">
            <b-field expanded label="Annual">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="Annual leave"
                             v-model="profile.leave_days_per_year.annual">

              </b-numberinput>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Sick">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="Sick leave"
                             v-model="profile.leave_days_per_year.sick">

              </b-numberinput>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Unpaid">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="Unpaid leave"
                             v-model="profile.leave_days_per_year.unpaid">

              </b-numberinput>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Family Responsibility">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0"
                             placeholder="Family responsibility leave"
                             v-model="profile.leave_days_per_year.family_responsibility">

              </b-numberinput>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Maternity">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="Maternity leave"
                             v-model="profile.leave_days_per_year.maternity">

              </b-numberinput>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="COVID">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="COVID leave"
                             v-model="profile.leave_days_per_year.covid">

              </b-numberinput>
            </b-field>
          </div>
          <div class="column is-4">
            <b-field expanded label="Study">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="Study leave"
                             v-model="profile.leave_days_per_year.study">

              </b-numberinput>
            </b-field>
          </div>
<!--          <div class="column is-4">-->
<!--            <b-field expanded label="Chronic">-->
<!--              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="Chronic leave"-->
<!--                             v-model="profile.leave_days_per_year.chronic">-->

<!--              </b-numberinput>-->
<!--            </b-field>-->
<!--          </div>-->
          <div class="column is-4">
            <b-field expanded label="Discretional">
              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="Discretional leave"
                             v-model="profile.leave_days_per_year.discretional">

              </b-numberinput>
            </b-field>
          </div>
<!--          <div class="column is-4">-->
<!--            <b-field expanded label="Absenteeism">-->
<!--              <b-numberinput :controls="false" :disabled="!canEdit" :step="1" :min="0" placeholder="Absenteeism leave"-->
<!--                             v-model="profile.leave_days_per_year.absenteeism">-->

<!--              </b-numberinput>-->
<!--            </b-field>-->
<!--          </div>-->


        </div>
        <h2 class="is-size-4">Probation</h2>
        <div class="columns is-centered is-multiline pt-3">


          <div class="column is-6">
            <b-field expanded label="Probation Commencement Date">
              <b-datepicker
                  :disabled="!canEdit"
                  :years-range="[-100,100]"
                  locale="en-ZA"
                  v-model="probation_start"
                  placeholder="Click to select..."
                  :icon="$tc('icons.calendar')"
                  trap-focus>
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field expanded label="Probation End Date">
              <b-datepicker
                  :disabled="!canEdit"
                  :years-range="[-100,100]"
                  locale="en-ZA"
                  v-model="probation_end"
                  placeholder="Click to select..."
                  :icon="$tc('icons.calendar')"
                  trap-focus>
              </b-datepicker>
            </b-field>
          </div>


        </div>

      </section>
      <section v-if="canCreate">
        <h2 class="is-size-4">Employment Dates</h2>
        <div class="columns is-centered is-multiline pt-3">


          <div class="column is-6">
            <b-field expanded label="Employment Start Date">
              <b-datepicker
                  :disabled="!canEdit"
                  :years-range="[-100,100]"
                  locale="en-ZA"
                  v-model="employment_start"
                  placeholder="Click to select..."
                  :icon="$tc('icons.calendar')"
                  trap-focus>
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-6">
            <b-field expanded label="Employment End Date">
              <b-datepicker
                  :disabled="!canEdit"
                  :years-range="[-100,100]"
                  locale="en-ZA"
                  v-model="employment_end"
                  placeholder="Click to select..."
                  :icon="$tc('icons.calendar')"
                  trap-focus>
              </b-datepicker>
            </b-field>
          </div>


        </div>
        <h2 class="is-size-4">Operational Information</h2>

        <div class="columns is-centered is-multiline pt-3">

          <div class="column is-4">
            <b-field expanded label="Manager">
              <UsersFilter :dropdown-direction="'top'" :selected_user_id="profile.manager_id"
                           :with_permission="'manage all staff in campus'"
                           :type="'autocomplete'" @selected="option=>profile.manager_id = option.id"
                           :campus_id="$store.state.campus.selected_campus_id"></UsersFilter>
            </b-field>
          </div>


        </div>

      </section>
      <hr class="my-0"/>
      <b-field grouped position="is-centered" class="mt-4">
        <div class="control">
          <b-button v-if="canEdit" :loading="loading" native-type="submit"
                    tag="input"
                    type="is-primary" value="Save">
          </b-button>
        </div>
      </b-field>

      <b-loading v-model="loading" :is-full-page="false"></b-loading>

    </form>
    <b-tabs expanded multiline  v-model="activeTab" class="non-hidden-tabs" :destroy-on-hide="true">
      <b-tab-item v-if="canCreate || user.id===$store.state.user.id" label="Media" :icon="$tc('icons.media')">
        <MediaPanel
            :submit_flip="submitMedia"
            @submitted="submitMedia=false"
            :submit_button="false"
            :tags="tags"
            :title="'Media'"
            :model="'staff_profiles'" :model_id="parseInt(profile.id)"
            :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
            :canCreate="!!$store.getters['entities/user-permissions/find']('create media') && !create"
        ></MediaPanel>
      </b-tab-item>
      <b-tab-item v-if="canCreate" label="Notes" :icon="$tc('icons.notes')">
        <NoteForm
            :disabled="create"
            :model_type="'staff-profiles'"
            :model_id="parseInt(profile.id)"
            :permissions="{
                      can_create:!!$store.getters['entities/user-permissions/find']('create notes')&&!create
                            }
                  "
        ></NoteForm>
      </b-tab-item>

      <!--                <b-tab-item v-if="can_create_emails" label="Email" icon="email">-->
      <!--                  <emailForm-->
      <!--                      :username_missing="$store.state.userEmail == null"-->
      <!--                      :permissions="{ can_create: can_edit_application }"-->
      <!--                      :reply_email="reply_email"-->
      <!--                      :default_recipient="learner"-->
      <!--                  ></emailForm>-->
      <!--                </b-tab-item>-->
    </b-tabs>
    <b-tabs expanded multiline
        v-if="!create &&canCreate"

        v-model="list"
        position="is-centered"
        class="non-hidden-tabs"
    >
      <b-tab-item label="Notes">
        <div :key="note.id + 'note'" v-for="note in paginated_notes">
          <noteListItem
              :model_type="'staff-profiles'"
              :model_id="parseInt(profile.id)"
              :permissions="{
                                        can_delete: !!$store.getters['entities/user-permissions/find']('delete notes'),
                                        can_edit: !!$store.getters['entities/user-permissions/find']('edit notes'),
                                      }"
              :note="note"
          ></noteListItem>
        </div>
        <b-pagination
            class="margin-top"
            :total="notes.length"
            :current.sync="pages.notes"
            :range-before="2"
            :range-after="2"
            :per-page="15"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
        ></b-pagination>
      </b-tab-item>

      <!--                <b-tab-item v-if="can_view_emails" label="Emails">-->
      <!--                  <div-->
      <!--                      :key="email.id + 'email'"-->
      <!--                      v-for="email in paginated_emails"-->
      <!--                  >-->
      <!--                    <emailListItem-->
      <!--                        @reply="reply"-->
      <!--                        :email_direction="-->
      <!--                        email.from_user_id == learner.id ? 'Received' : 'Sent'-->
      <!--                      "-->
      <!--                        :email="email"-->
      <!--                    ></emailListItem>-->
      <!--                  </div>-->
      <!--                  <b-pagination-->
      <!--                      class="margin-top"-->
      <!--                      :total="emails.length"-->
      <!--                      :current.sync="pages.emails"-->
      <!--                      :range-before="2"-->
      <!--                      :range-after="2"-->
      <!--                      :per-page="15"-->
      <!--                      aria-next-label="Next page"-->
      <!--                      aria-previous-label="Previous page"-->
      <!--                      aria-page-label="Page"-->
      <!--                      aria-current-label="Current page"-->
      <!--                  ></b-pagination>-->
      <!--                </b-tab-item>-->
    </b-tabs>


  </div>

</template>

<script>
import Profile from "@/models/StaffProfile";
import Department from "@/models/Department";
import {format} from "date-fns";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import MediaPanel from "@/components/media/MediaPanel";
import NoteForm from "@/components/notes/NoteForm";
import NoteListItem from "@/components/notes/NoteListItem";
import Note from "@/models/Note";
// import {ValidationProvider, ValidationObserver, extend} from "vee-validate";


// extend("autocomplete", {
//   params: ["target"],
//   validate(value, {target}) {
//     return target !== null;
//   },
//   message: "Please select an item from the list",
// });

export default {
  name: "StaffProfilePanel",
  components: {UsersFilter, NoteForm, MediaPanel, NoteListItem},
  data() {
    return {
      list: 0,
      activeTab: 0,
      create: true,
      loading: false,
      loaded: false,
      language: '',
      submitMedia: false,
      pages: {notes: 1, activities: 1, emails: 1},
      tags: ['Contract', 'CV', 'Qualification','ID or Passport','Proof of banking details','Police clearance certificate' ,'First aid certificate','Beneficiary form', 'SOR clearance','CPR clearance','Doctor\'s certificate'],

      profile: {
        annual_accrual_rate: 0,
        annual_carryover_months: 0,
        employment_type: null,
        department_id: null,
        hours_per_week: 0,
        extension_code: null,
        designation: null,
        leave_days_per_year: {
          annual: null,
          sick: null,
          unpaid: null,
          family_responsibility: null,
          maternity: null,
          covid: null,
          study: null,
          chronic: null,
          discretional: null,
          absenteeism: 365,
        },
        probation_start_date: null,
        probation_end_date: null,
        employment_start_date: null,
        employment_end_date: null,
        manager_id: null
      }
    }
  },
  props: {

    canEdit: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    },


    user: {
      type: Object,
      required: true
    }
  },
  methods: {
    submit() {
      if (this.canEdit) {

        this.loading = true

        if (this.create) {
          Profile.Store(this.profile, this.user.id).then(() => {
            this.$buefy.snackbar.open('Profile Created!')
            this.profile = Profile.query().whereId(this.user.id).first()

            this.submitMedia = true
            this.loading = false
            this.create = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false
          })
        } else {
          Profile.Update(this.profile).then(() => {
            this.$buefy.snackbar.open('Profile Updated!')
            this.submitMedia = true
            this.loading = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false
          })
        }

      } else {
        this.$store.dispatch('toast/createToast')
      }
    },
  },
  computed: {
    notes() {
      return Note.query()
          .with("user")
          .orderBy("created_at", "desc")
          .get();
    },

    paginated_notes() {
      return Note.query()
          .with("user")
          .orderBy("created_at", "desc")
          .offset((this.pages.notes - 1) * 15)
          .limit(15)
          .get();
    },
    probation_start: {
      get() {
        if (this.profile.probation_start_date === null) {
          return null
        }
        return new Date(this.profile.probation_start_date)
      }, set(newValue) {
        this.profile.probation_start_date = format(newValue, 'yyyy-MM-dd')
      },
    }, probation_end: {
      get() {
        if (this.profile.probation_end_date === null) {
          return null
        }
        return new Date(this.profile.probation_end_date)
      }, set(newValue) {
        this.profile.probation_end_date = format(newValue, 'yyyy-MM-dd')
      },
    }, employment_start: {
      get() {
        if (this.profile.employment_start_date === null) {
          return null
        }
        return new Date(this.profile.employment_start_date)
      }, set(newValue) {
        this.profile.employment_start_date = format(newValue, 'yyyy-MM-dd')
      },
    }, employment_end: {
      get() {
        if (this.profile.employment_end_date === null) {
          return null
        }
        return new Date(this.profile.employment_end_date)
      }, set(newValue) {
        this.profile.employment_end_date = format(newValue, 'yyyy-MM-dd')
      },
    },
    departments() {
      return Department.query().get()
    }
  },
  mounted() {

    this.loading = true
    Note.deleteAll()
    Promise.all([
      Profile.FetchById(this.user.id), Department.FetchAll()]).then(() => {
      this.profile = Profile.query().whereId(this.user.id).first()
      this.create = false
      Note.FetchAllByModel({page: 1, limit: 999}, {}, ['user'], {
        id: this.profile.id,
        type: 'staff-profiles'
      }).then(
          () => {
            this.loading = false
            this.$store.dispatch("loader/hide");
          }
      );


    }).catch(err => {
          if (err.response.status === 404) {

            this.create = true
          } else {
            this.handleError(err)
          }
          this.loading = false
        }
    )
  }
}
</script>
