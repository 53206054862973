<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto;">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Log a new' : 'Edit' }} Leave Request</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field label="User">
          <UsersFilter
              :disabled="default_user!==null || !canManage || edit"
              :selected_user_id="leaveObject.user_id"
              :with_permission="'be staff'"
              :type="'autocomplete'"
              @selected="option=>leaveObject.user_id = option.id"
          ></UsersFilter>
        </b-field>
        <b-field v-if="leaveObject.approved_by_user_id!==null" label="Approved By">
          <UsersFilter
              :disabled="true"
              :selected_user_id="leaveObject.approved_by_user_id"
              @selected="option=>leaveObject.approved_by_user_id = option.id"

              :with_permission="'be staff'"
              :type="'autocomplete'"
          ></UsersFilter>
        </b-field>
        <b-field v-if="canManage" label="Is Absent">
          <b-checkbox :true-value="true" :false-value="false" v-model="leaveObject.is_absent">

          </b-checkbox>
        </b-field>
        <b-field expanded label="Leave Type">
          <b-select v-if="!leaveObject.is_absent" placeholder="Leave Type" expanded required
                    v-model="leaveObject.type_id">
            <option v-for="type in $store.state.leave.types_without_absenteeism" :value="type.id" :key="type.id">
              {{ type.name }}
            </option>
          </b-select>
          <b-select v-if="leaveObject.is_absent" placeholder="Leave Type" disabled expanded required
                    v-model="leaveObject.type_id">
            <option :value="10" :key="10">Absenteeism</option>
          </b-select>
        </b-field>
        <b-field expanded label="Full Day or Half Day">
          <b-select placeholder="Leave Type" expanded required v-model="dayType">
            <option v-for="type in $store.state.leave.day_type" :value="type.value" :key="type.value">{{ type.type }}
            </option>
          </b-select>
        </b-field>
        <b-field :type="{ 'is-danger': remaining_days_of_leave!==null?remaining_days_of_leave<0:false }"
                 v-if="leaveObject.type_id" expanded label="Leave Remaining"
                 :message="{ 'Insufficient leave available':  remaining_days_of_leave!==null?remaining_days_of_leave<0:false  }">
          <p><span
              v-if="leaveObject.dates.length>0">{{ remaining_days_of_leave }}/</span><span>{{ current_days_of_leave }} day</span><span
              v-if="current_days_of_leave!==1">s</span></p>
        </b-field>
        <b-field v-if="loaded" expanded label="Leave Dates">
          <b-datepicker
              :years-range="[-5,5]" placeholder="Leave Dates" multiple required expanded
              v-model="leave_dates"></b-datepicker>
        </b-field>
        <b-field v-if="canManage" expanded label="Leave Request Status">
          <b-select @input="handleStatusChange" placeholder="Status" expanded required v-model="leaveObject.status_id">
            <option v-for="status in $store.state.leave.statuses" :value="status.id" :key="status.id">{{
                status.name
              }}
            </option>
          </b-select>
        </b-field>
        <b-field v-if="loaded" label="Notes" style="overflow: hidden">
          <ckeditor :editor="editor" v-model="leaveObject.note" :config="editorConfig"></ckeditor>
        </b-field>
        <MediaPanel
            :submit_flip="submitMedia"
            @submitted="submitMedia=false"
            :submit_button="false"
            v-if="(edit||created) && loaded"
            :has-tag="false"
            :title="'Media'"
            :model="'leave'"
            :retrieve_model="'leaves'"
            :model_id="parseInt(leaveObject.id)"
            :canDelete="!!$store.getters['entities/user-permissions/find']('delete media')"
            :canCreate="!!$store.getters['entities/user-permissions/find']('create media')"
        ></MediaPanel>
      </section>

      <footer :class="{'modal-card-foot':inModal}">
        <b-field class="mt-3 has-text-centered" grouped expanded position="is-centered">
          <b-field position="is-centered">
            <b-button native-type="submit"
                      :disabled="leaveObject.dates.length<1||leaveObject.user_id===null||(remaining_days_of_leave!==null?remaining_days_of_leave<0:false)"
                      type="is-primary"
                      :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">
              {{ (edit || created) ? 'Save' : 'Submit' }}
            </b-button>
          </b-field>
          <b-field v-if="edit&&canDelete&&(canManage||leaveObject.status_id===1)" position="is-centered">
            <b-button
                @click.prevent="startDelete()"
                type="is-danger"
                :icon-right="$tc(`icons.delete`)">Delete
            </b-button>
          </b-field>
        </b-field>
      </footer>
    </div>
    <b-loading v-model="loading" :is-full-page="false" :can-cancel="false"></b-loading>
  </form>
</template>

<script>

import {format} from "date-fns";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import LeaveRequest from "@/models/LeaveRequest";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import Profile from "@/models/StaffProfile";
import MediaPanel from "@/components/media/MediaPanel";

export default {
  name: "LeaveRequestForm",
  components: {UsersFilter, MediaPanel},
  data() {
    return {
      loaded: false,
      loading: false,
      created: false,
      submitMedia: false,
      loadingClassGroups: false,
      year: new Date().getFullYear(),
      dayType: 'full-day',
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "An optional note"},
      },
      leaveObject: {
        user_id: this.default_user,
        type_id: null,
        dates: [],
        is_absent: false,
        status_id: 1,
        approved_by_user_id: null,
        note: null,
        is_archived:false
      }
    }
  },

  computed: {
    current_days_of_leave() {
      if (this.leaveObject.type_id && this.profile) {
        if (this.leaveObject.type_id === 1) {
          return this.profile.available_annual_leave
        }
        return this.profile.outstanding_leave_days[this.$store.state.leave.types.find(type => type.id === this.leaveObject.type_id).model_name]
      }
      return null
    },
    remaining_days_of_leave() {
      if (this.leaveObject.type_id && this.profile) {
        if (this.leaveObject.type_id === 1) {
          return (this.profile.available_annual_leave) - (this.dayType === 'full-day' ? this.leaveObject.dates.length : this.leaveObject.dates.length * 0.5)
        }
        return this.profile.outstanding_leave_days[this.$store.state.leave.types.find(type => type.id === this.leaveObject.type_id).model_name] - (this.dayType === 'full-day' ? this.leaveObject.dates.length : this.leaveObject.dates.length * 0.5)
      }
      return null
    },
    profile() {
      return Profile.query().where('user_id', this.leaveObject.user_id).first()
    },
    leave_dates: {
      get() {
        return this.leaveObject.dates.map(date => {
          return new Date(date.date)
        })
      }, set(newValue) {
        this.leaveObject.dates = newValue.map(date => {
          return {date: format(date, 'yyyy-MM-dd'), type: this.dayType}
        })

      },
    },
  },

  watch: {
    'leaveObject.is_absent'(newValue) {
      if (newValue === true) {
        this.leaveObject.type_id = 10
      }
    },
    'leaveObject.user_id'() {
      console.log('ss')
      Profile.FetchById(this.leaveObject.user_id)
    }
  },
  methods: {
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting Leave Request`,
        confirmText: `Delete Leave Request`,
        hasIcon: true,
        type: "is-danger",
        message: `Are you sure you want to delete this leave request?`,
        onConfirm: () =>
            LeaveRequest.Delete(this.leaveObject.id)
                .then(() => {
                  this.$buefy.snackbar.open(`Leave Request deleted!`);
                  this.removeQuery(['leave_request_id'])

                  this.$emit('close')
                })
                .catch((err) => {
                  this.handleError(err)
                }),
      });
    },
    handleStatusChange(value) {

      value === 1 ? this.leaveObject.approved_by_user_id = null : this.leaveObject.approved_by_user_id = this.$store.state.user.id

    },

    submit() {
      if (this.edit || this.created) {
        if (this.canEdit && (this.canManage || this.leaveRequest.status_id === 1)) {
          this.loading = true
          LeaveRequest.Update({
                user_id: this.leaveObject.user_id,
                type_id: this.leaveObject.type_id,
                dates: this.leaveObject.dates,
                is_absent: this.leaveObject.is_absent,
                approved_by_user_id: this.leaveObject.approved_by_user_id,
                id: this.leaveObject.id,
                status_id: this.leaveObject.status_id
              }, true
          ).then(() => {
            this.submitMedia = true
            this.loading = false
            this.$buefy.snackbar.open(`Leave Request updated!`)
            this.$emit('close')
          }).catch(err => {
            this.loading = false
            this.handleError(err)
          })
        } else {
          this.$store.dispatch('toast/createToast')
        }
      } else {
        if (this.canCreate) {
          this.loading = true
          LeaveRequest.Store({
            user_id: this.leaveObject.user_id,
            type_id: this.leaveObject.type_id,
            dates: this.leaveObject.dates,
            is_absent: this.leaveObject.is_absent,
            approved_by_user_id: this.leaveObject.approved_by_user_id,
            status_id: this.leaveObject.status_id


          }).then(({entities: {leaveRequests}}) => {
            this.loading = false
            this.$buefy.snackbar.open(`Leave Request submitted!`)
            this.leaveObject.id = leaveRequests[0].id
            this.created = true
            this.submitMedia = true
          }).catch(err => {
            this.loading = false
            this.handleError(err)
          })
        } else {
          this.$store.dispatch('toast/createToast')
        }
      }
    }
  }
  ,
  mounted() {
    if (this.edit) {

      this.leaveObject = this.leaveRequest

      if (this.leaveObject.dates.length > 0) {
        this.dayType = this.leaveObject.dates[0].type
        this.loaded = true
      }
    }

    if (!this.edit) {
      Profile.FetchById(this.leaveObject.user_id)
      this.loaded = true
    }
  }
  ,
  props: {
    canManage: {
      type: Boolean,
      default() {
        return false
      }
    }, canCreate: {
      type: Boolean,
      default() {
        return false
      }
    },
    canEdit: {
      type: Boolean,
      default() {
        return false
      }
    }, canDelete: {
      type: Boolean,
      default() {
        return false
      }
    },
    default_user: {
      type: Number,
      default() {
        return null
      }
    },
    leaveRequest:
        {
          type: Object,
          default
              () {
            return {}
          }
          ,
        }
    ,
    inModal: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
    edit: {
      type: Boolean,
      default
          () {
        return false
      }
    }
    ,
  }
}
</script>

