<template>
  <b-field>
    <b-input
        v-if="type==='filter'"
        v-debounce:300ms="getFiltered"
        placeholder="Extramural"
        :loading="loading"
        :icon="$tc('icons.extramurals')"
        :icon-right="$tc('icons.close-circle')"
        :icon-right-clickable="true"
        v-model="searchTerm"
        v-on:icon-right-click="clear();getFiltered('')"
    ></b-input>
    <b-autocomplete
        append-to-body
        v-if="type==='autocomplete'"
        v-debounce:300ms="getFiltered"
        :data="searchResponse"
        autocomplete="off"
        :loading="loading"
        v-model="searchTerm"
        placeholder="Extramural"
        :clear-on-select="false"
        :custom-formatter="thing=> name(thing)"
        @select="option => select(option)"
    >
      <template slot-scope="props">
        <p>{{ props.option.name }} </p>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import Extramural from "@/models/Extramural";

export default {
  name: "ExtramuralsFilter",
  data: function () {
    return {
      loading: false,
      searchTerm: '',
      searchResponse: null
    };
  },
  props: {
    year: {
      type: Number,
      default() {
        return this.$store.state.dates.year
      }
    },
    mode: {
      type: String, default() {
        return 'insertOrUpdate'
      }
    }, type: {
      type: String, default() {
        return 'filter'
      }
    },
    campus_id: {
      type: Number, default() {
        return null
      }
    },
    stage_id: {
      type: Number, default() {
        return null;
      }
    }
  },
  methods: {
    name(thing) {
      return `${thing.name}`
    },
    select(option) {
      if (option === null) {
        this.clear()
      } else {
        this.$emit('selected', option)
      }
    },

    clear() {
      this.searchTerm = ''
      this.searchResponse = [];
      this.$emit('cleared')
    },
    getFiltered(text) {
      this.loading = true;

      Extramural.FetchAll({page: 1, limit: 15}, {
        ...{search: text}, ...(this.campus_id ? {
          campus_id: this.campus_id
        } : {}), ...(this.stage_id ? {
          stage_id: this.stage_id
        } : {}), ...(this.year ? {
          year: this.year
        } : {})
      }, [], this.mode).then((result) => {
        this.loading = false;

        if (result.entities) {
          this.searchResponse = result.entities.extramurals;
          if (this.type === 'filter') {
            this.$emit('filtered', {result: result, term: this.searchTerm})
          }
        } else {
          this.$emit('filtered', {term: this.searchTerm})

          this.searchResponse = [];
        }
      });

    }
  }
}
</script>

<style scoped>

</style>
