<template>
  <form @submit.prevent="submit">
    <div :class="{'modal-card':inModal}" style="width: auto">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }}{{isProgrammeSubject?' Programme':''}} Subject</p>
      </header>
      <section :class="{'modal-card-body':inModal}">
        <b-field expanded label="Year">
          <b-select expanded :disabled="!edit|| !canEditFields" required v-model="subjectObject.year"
                    placeholder="Year">
            <option
                v-for="year of $store.getters['dates/years']"
                :key="year + 'year'"
                :value="year"
            >{{ year }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Name">
          <b-input :disabled="!canEditFields"
                   v-model="subjectObject.name"
                   placeholder="Subject name"
                   required>
          </b-input>
        </b-field>
        <b-field v-if="isProgrammeSubject" label="Provider Code">
          <b-input :disabled="true"
                   v-model="subjectObject.provider_code"
                   >
          </b-input>
        </b-field>
        <b-field v-if="!isProgrammeSubject" label="Admin Name">
          <b-input :disabled="!canEditFields"
                   v-model="subjectObject.long_name"
                   placeholder="Subject Admin Name"
                   required>
          </b-input>
        </b-field>
        <b-field v-if="loaded" style="overflow: hidden" label="Description">
          <ckeditor style="overflow: hidden"
                    :disabled="!canEditFields"
                    :editor="editor"
                    v-model="subjectObject.report_description"
                    :config="editorConfig"
          ></ckeditor>
        </b-field>
        <b-field expanded v-if="!isProgrammeSubject" label="Course Code">
          <b-select expanded :disabled="!canEditFields" v-model="subjectObject.course_scope_id">
            <option v-for="course in courses" :key="course.id" :value="course.id">{{ course.learning_stream.name }} |
              {{ course.code }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Has Attendances">
          <b-switch :disabled="!canEditFields" v-model="subjectObject.has_attendances" :true-value="1" :false-value="0">
            {{ subjectObject.has_attendances === 1 ? 'Open for attendance' : 'Closed for attendance' }}
          </b-switch>
        </b-field>
        <h2 class="is-size-4 has-text-weight-bold">Classes:</h2>
        <div v-for="(classGroup,index) in classGroups" :key="index">
          <b-field label="Class Name">
            <b-input
                :disabled="classGroup.subject_id!==null?!$store.getters['entities/user-permissions/find']('edit class-groups'):!$store.getters['entities/user-permissions/find']('create class-groups')"
                v-model="classGroup.name" placeholder="Class Name"></b-input>
          </b-field>
          <b-field  :label="$tc('Teacher',2)">
            <PerfectScrollbarWrapper>

            <b-table
                class="margin-top is-fullwidth"
                :data="classGroup.teachers"
                :striped="true"
                :hoverable="true"
                :bordered="true"
                :scrollable="false"
            >
              <template #empty>
                <div class="has-text-centered">No {{ $tc('Teacher', 2) }}</div>
              </template>
              <b-table-column
                  v-slot="props"
                  sortable
                  field="first_name"
                  label="First Name"
              >{{ props.row.first_name }}
              </b-table-column>
              <b-table-column
                  v-slot="props"
                  sortable
                  field="last_name"
                  label="Last Name"
              >{{ props.row.last_name }}
              </b-table-column>
              <b-table-column v-slot="props" sortable field="email" label="Email">{{
                  props.row.email
                }}
              </b-table-column>
              <b-table-column v-slot="props" narrowed centered custom-key="actions">
                <b-field grouped>
                  <b-field>
                    <b-button @click="viewUser(props.row)" type="is-primary"
                    >View
                    </b-button
                    >
                  </b-field>
                  <b-field>
                    <b-tooltip label="Remove" size="is-small" type="is-danger">
                      <b-button
                          :disabled="classGroup.subject_id!==null?!$store.getters['entities/user-permissions/find']('edit class-groups'):!$store.getters['entities/user-permissions/find']('create class-groups')"
                          type="is-danger"
                          :icon-right="$tc('icons.minus')"
                          @click="removeTeacher(props.row,classGroup)"
                      ></b-button>
                    </b-tooltip>
                  </b-field>
                </b-field>
              </b-table-column>
            </b-table>
              </PerfectScrollbarWrapper>

          </b-field>
          <b-field :label="`Add ${$tc('Teacher')}`">
            <UsersFilter :with_permission="'teach subjects'" :dropdown-direction="'top'"
                         :append="false"
                         :type="'autocomplete'" @selected="selectUser($event,classGroup)"
                         :campus_id="classGroup.campus_id"></UsersFilter>
          </b-field>
          <b-field position="is-centered" grouped>
            <b-field>
              <b-button :disabled="!$store.getters['entities/user-permissions/find']('delete class-groups')"
                        @click.prevent.stop="removeClassGroup(classGroup)" type="is-danger">Remove Class
              </b-button>
            </b-field>

          </b-field>
          <b-loading :is-full-page="false" :active.sync="loadingClassGroup===classGroup.id"
                     :can-cancel="false"></b-loading>
          <hr>
          <hr>
        </div>
        <b-field position="is-centered" grouped class="pb-4" :label="`Add a New Class`">
          <b-field position="is-centered">
            <b-button
                :disabled="!$store.getters['entities/user-permissions/find']('create class-groups')"
                type="is-primary" @click="addClassGroup">Add New Class
            </b-button>
          </b-field>
        </b-field>


      </section>
      <footer v-if="inModal" class="modal-card-foot">
        <button :loading="loading" class="button" type="is-primary"
                :icon-right="$tc(`icons.${edit===false?'create':'edit'}`)">Save
        </button>
      </footer>
      <b-field grouped class="mt-4" v-else>
        <div class="control">
          <b-button :loading="loading" native-type="submit"
                    tag="input"
                    type="submit" value="Save">
          </b-button>
        </div>
        <div v-if="edit" class="control">
          <b-button :loading="loadingDelete" @click.prevent="startDelete" type="is-danger">
            Delete
          </b-button>
        </div>
      </b-field>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>
import Subject from "@/models/Subject";
import UsersFilter from "@/components/panelled-dash/UsersFilter";
import CourseScope from "@/models/CourseScope";
import UserProfile from "@/components/users/UserProfile";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/editorConfig";
import ClassGroup from "@/models/ClassGroup";
import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

export default {
  name: "SubjectForm",
  components: {UsersFilter,     PerfectScrollbarWrapper
  },
  data() {
    return {
      loading: false,
      loadingDelete: false,
      loadingClassGroup: null,
      loaded: false,
      classGroups: [],
      deleteClassGroups: [],
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "The description to appear on reports."},
      },
      subjectObject: {
        name: '',
        long_name: '',
        code: null,
        ordinality: 0,
        campus_id: this.campus_id,
        stage_id: this.stage_id,
        has_attendances: 0,
        course_scope_id: null,
        year: this.$store.state.dates.year,
        report_description: null
      }
    }
  },
  methods: {
    viewUser(user) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          canCreateWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canDeleteWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians'),
          canEditWard: !!this.$store.getters['entities/user-permissions/find']('edit guardians')
          ,
          inModal: true,
          tabs: [
            {title: "User Details", role: "details"},
          ],
          proppedUser: user,
          canEdit: !!this.$store.getters["entities/user-permissions/find"](
              "edit users"
          ),
          canDelete: !!this.$store.getters["entities/user-permissions/find"](
              "delete users"
          ),
        },
        component: UserProfile,
        fullScreen: true,
        trapFocus: true,
      })
    }
    ,
    updateClassGroup(classGroup) {
      this.loadingClassGroup = classGroup.id
      let updateObject = JSON.parse(JSON.stringify(classGroup))
      updateObject.teachers = updateObject.teachers_ids
      ClassGroup.Update(updateObject).then(() => {
        this.loadingClassGroup = null
      })
    },
    addClassGroup() {
      this.classGroups.push({
        name: '',
        teachers: [],
        teachers_ids: [],
        campus_id: this.subjectObject.campus_id,
        subject_id: null
      })
    },
    removeClassGroup(classGroup) {
      if (this.edit && classGroup.subject_id !== null) {
        this.$buefy.dialog.confirm({
          title: "Deleting Class",
          message:
              "Are you sure you want to <b>delete</b> this class? This action cannot be undone.",
          confirmText: "Delete Class",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => {
            ClassGroup.Delete(classGroup.id).then(() => {
              this.classGroups = this.classGroups.filter(group => group.id !== classGroup.id)

            }).catch(err => {
              this.handleError(err)
            })
          },
        });

      } else {
        this.classGroups = this.classGroups.filter(group => group.id !== classGroup.id)
      }
    },
    removeTeacher(user, classGroup) {
      classGroup.teachers = classGroup.teachers.filter(teacher => teacher.id !== user.id)
      classGroup.teachers_ids = classGroup.teachers_ids.filter(teacher_id => teacher_id !== user.id)
    },
    selectUser(user, classGroup) {
      classGroup.teachers.push(user)
      classGroup.teachers_ids.push(user.id)
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: 'Deleting subject',
        confirmText: 'Delete Subject',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this subject?',
        onConfirm: () => this.delete()
      })
    },
    delete() {
      this.$emit('subject-deleted', this.subject.id)
    },
    submit() {
      let submitObject = this.subjectObject

      if (this.edit) {
        this.loading = true
        Subject.Update(submitObject, true).then(() => {
          let groupPromises = []
          // this.deleteClassGroups.map(id => groupPromises.push(ClassGroup.Delete(id)))
          this.classGroups.map(group => {
            if (group.subject_id === null) {
              group.subject_id = submitObject.id
              group.teachers = group.teachers_ids
              groupPromises.push(ClassGroup.Store(group))
            } else {
              group.teachers = group.teachers_ids
              groupPromises.push(ClassGroup.Update(group))
            }
          })
          Promise.all(groupPromises).then(() => {
            this.$buefy.snackbar.open(`Subject updated!`)
            this.$emit('close')
            this.loading = false
          })

        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        this.loading = true
        Subject.Store(submitObject).then(response => {
          this.$buefy.snackbar.open(`Subject created!`)
          this.$emit('subject-created', response.entities.subjects[0].id)
          let promises = []

          this.classGroups.map(group => {
            group.subject_id = response.entities.subjects[0].id
            group.teachers = group.teachers_ids

            promises.push(
                ClassGroup.Store(group))
          })
          Promise.all(promises).then(() => {
            this.$emit('close')
            this.loading = false
          })

        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }
    }
  },
  watch: {
    // subject() {
    //   this.subjectObject = this.subject
    // }
  },
  computed: {
    isProgrammeSubject() {
      if (this.edit) {
        return this.subjectObject.is_for_programmes === 1
      }
      return false
    },
    courses() {
      if (!this.isProgrammeSubject) {
        return CourseScope.query().with('learning_stream').get().sort((a, b) => a.learning_stream.name.localeCompare(b.learning_stream.name))

      }
      return []
    }
  },
  mounted() {
    this.loading = true
    ClassGroup.deleteAll()
    let promises = [CourseScope.FetchAll({page: 1, limit: 500}, {}, ['learning_stream'])
    ]
    if (this.edit) {
      promises.push(Subject.FetchById(this.subject.id, ['course_scope', 'course_scope.learning_stream']))
      promises.push(ClassGroup.FetchAll({page: 1, limit: 999}, {subject_id: this.subject.id}, ['teachers']))
    }
    Promise.all(promises).then(() => {
      if (this.edit) {
        this.subjectObject = Subject.query().whereId(this.subject.id).with('class_groups').with('class_groups.teachers').first()
        if (Object.prototype.hasOwnProperty.call(this.subjectObject, 'class_groups')) {
          this.classGroups = this.subjectObject.class_groups
        }
      }
      this.loading = false
      this.loaded = true
    }).catch(err => {
      console.log(err)
      this.handleError(err)
      this.loading = false
    })
  },
  props: {
    campus_id: {
      type: Number, default() {
        return 0
      }
    },
    stage_id: {
      type: Number, default() {
        return 0
      }
    },
    subject: {
      type: Object,
      default() {
        return {
          name: '', ordinality: 0
        }
      },
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    canEditFields: {
      type: Boolean,
      default() {
        return true
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

